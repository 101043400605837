

export const siteURLbase = "127.0.0.1"
export const siteURL = "https://" + siteURLbase + ":3000"
// export const siteURL = "https://divein-ad72d.web.app"
let base = "/api"
if (window.location.hostname === "localhost") {
  console.log("is localhost")
    base = "http://localhost:8080"
}
export const baseURL = base
export const LoginUrl = baseURL + "/auth/vipps"
export const CreateSubscriptionURL = baseURL + "/subscription/create"
export const UpgradeSubscriptionURL = baseURL + "/subscription/upgrade"
export const UpdateLicenseURL = baseURL + "/subscription/update"
export const WashGateURL = baseURL + "/washgate"
export const SelfServiceURL = baseURL + "/selfservice"
export const ValuecardURL = baseURL + "/valuecard"
