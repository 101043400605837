import React, { useEffect, useState } from "react";
import DropletLoopJSON from "../../assets/lottie/droplet-loop.json";
import Lottie from "lottie-react";
import Header from "../../components/Header/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { divein } from "../../utils/api";
import GreenCheckmarkJSON from "../../assets/lottie/green_checkmark.json";

const VippsLanding = () => {
  const [status, setStatus] = useState<string>();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const pid = searchParams.get("pid");
  const type = searchParams.get("type");
  const tokenID = searchParams.get("tokid");

  let timeoutId;

  const success = () => {
    switch (type) {
      case "vacuum":
        timeoutId = setTimeout(() => {
        navigate("/stovsugerhall", { replace: true });
      },3000)
        break;
        case "blowdry":
        timeoutId = setTimeout(() => {
        navigate("/qrkode/"+tokenID, { replace: true });
      },3000)
      break;
      case "washgate":
        timeoutId = setTimeout(() => {
        navigate("/qrkode/"+tokenID, { replace: true });
      },3000)
        break;
      case "valuecard":
        timeoutId = setTimeout(() => {
        navigate("/selvvask/", { replace: true });
      },3000)
        break;
      case "upgradesub":
        timeoutId = setTimeout(() => {
        navigate("/profil/", { replace: true });
      },3000)
        break;
      case "selfservice":
        timeoutId = setTimeout(() => {
        navigate("/selvvask/", { replace: true });
      },3000)
        break;
    }
  };


  useEffect(() => {
  return () => clearTimeout(timeoutId)
  },[])

  useEffect(() => {
    console.log(type);
    let intStatus: string;
    const getStatus = async () => {
      try {
      const { responseData } = await divein.pollPayment(pid);
      setStatus(responseData["status"]);
      intStatus = responseData["status"];

      }catch(err) {
        console.error()
        navigate("/")
      }
    };

    const interval = setInterval(() => {
      if (!pid) {
        clearInterval(interval);
        return;
      }
      if (intStatus === "no") {
        clearInterval(interval);
        return;
      }
      if (intStatus === "yes") {
        clearInterval(interval);
        success();
        return;
      }

      getStatus();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let timeout;
    if (status === "no") {
      timeout = setTimeout(() => {
        navigate("/", { replace: true });
      }, 2000);
    }
    if (status === "yes" && type === "matwash") {
      timeout = setTimeout(() => {
        navigate("/mattevask", { replace: true });
      }, 5000);
    }
    if (status === "yes" && type === "valuecard") {
      timeout = setTimeout(() => {
        navigate("/selvvask", { replace: true });
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [status]);
  return (
    <>
      <Header backLink="/" />
      <div className="container mt-20">
        {/* {!pid && (
          <>
            <p className="center-text">
              Noe gikk galt på turen tilbake fra Vipps.
            </p>
            <p className="center-text">
              Om transaksjonen var vellykket, skal du uansett ha ønsket produkt
              i din profil.
            </p>
          </>
        )}
*/}
        {status === "maybe" && (
          <Lottie animationData={DropletLoopJSON} style={{ height: "150px" }} />
        )}
        {status === "no" && (
          <>
            <p className="text-xl">Betalingen var ikke vellykket.</p>
          </>
        )}
        {status === "yes" && (
          <>
            <Lottie
              animationData={GreenCheckmarkJSON}
              style={{ height: "150px" }}
              loop={false}
            />
            <p className="text-2xl">Betaling vellykket!</p>
          </>
        )}
      </div>
    </>
  );
};

export default VippsLanding;
