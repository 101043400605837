import React from 'react'

function ForwardArrowWhite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
    >
      <defs>
        <style>
          {
            '.prefix__a-w{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}'
          }
        </style>
      </defs>
      <path
        className="prefix__a-w"
        d="M31 16A15 15 0 1116 1a15 15 0 0115 15z"
      />
      <path className="prefix__a-w" d="M16 22l6-6-6-6M10 16h12" />
    </svg>
  )
}

export default ForwardArrowWhite
