import { createState, useState } from '@hookstate/core'
import { ProgramType } from '../components/ProgramComponent/ProgramComponent'

export type ProgramOption = {
  option: string
  subText: string
  price: number
  productId: string
  selected: boolean
}

export type BlowdryOption = {
  price: number
  productId: string
  name: string
  id: string
}
interface ProgramStoreProps {
  programData: ProgramType[] | null
  programOptions: ProgramOption[] | null
  programBlowdry: BlowdryOption | null
  selectedProgram: string | undefined
  selectedOptions: boolean[]
  selectedSubscription: string | null
  total: number
}

const ProgramStore = createState<ProgramStoreProps>({
  programData: null,
  programOptions: null,
  programBlowdry: null,
  selectedProgram: undefined,
  selectedOptions: [],
  selectedSubscription: null,
  total: 0,
})

export const useProgramStore = () => {
  return useState(ProgramStore)
}

export function getBlowdryProgram() {
  const bdp = ProgramStore.programBlowdry.get()
  return bdp
}

export default ProgramStore
