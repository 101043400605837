
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect } from 'react'
import { db } from '../utils/Firebase'
import { useState as useSpecialState } from '@hookstate/core'
import SubscriptionStore, { Subscription } from '../stores/subscriptionStore'
import { User } from 'firebase/auth'
import { subscriptionIsDone } from '../utils/helper'

const useSubscriptionListener = (user: User) => {
    const subscriptionStore = useSpecialState(SubscriptionStore)
    useEffect(() => {
        let unsubscribe
        if (user?.uid) {
            const q = query(
                collection(db, "customers/" + user.uid + "/subscriptions"),
                where("status", "!=", 0)
            );
            unsubscribe = onSnapshot(q, (querySnapshot) => {
                const tmp = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data() as Subscription;
                    if (!subscriptionIsDone(data)) {
                        tmp.push(data)
                    }
                });

                subscriptionStore.subscriptions.set(tmp);
            })
        } else {
            unsubscribe = () => null
        }
        return () => unsubscribe()
    }, [user])
}

// eslint-disable-next-line react/display-name        
export default useSubscriptionListener
