import React, { useState } from "react";
import Header from "../../components/Header/Header";
import ExpandArrow from "../../utils/Icons/ExpandArrow";
import Styles from "./Info.module.css";

const InfoSubscription = () => {
  const [isOpen, setIsOpen] = useState(0);
  return (
    <>
      <Header text="Informasjon" backLink="back"/>
      <div className="container">
        <div className="inner">
          <h3 style={{ textAlign: "center", letterSpacing: 0.8 }}>
            Abonnement
          </h3>
          <div
            className={Styles.infobox}
            onClick={() => (isOpen === 1 ? setIsOpen(0) : setIsOpen(1))}
          >
            <p className={Styles.header}>
              Når begynner min månedsperiode å løpe?
            </p>
            <div
              className={isOpen === 1 ? Styles.iconCollapse : Styles.iconExpand}
            >
              <ExpandArrow />
            </div>
          </div>
          <p className={isOpen === 1 ? Styles.open : Styles.closed}>
            Månedsperioden begynner å løpe fra den dagen du benytter din første
            vask, eller senest 15 dager etter bestilling av abonnementet.
          </p>
          <div
            className={Styles.infobox}
            onClick={() => (isOpen === 2 ? setIsOpen(0) : setIsOpen(2))}
          >
            <p className={Styles.header}>
              Kan jeg endre mitt bilnummer på et eksisterende abonnement?
            </p>
            <div
              className={isOpen === 2 ? Styles.iconCollapse : Styles.iconExpand}
            >
              <ExpandArrow />
            </div>
          </div>
          <p className={isOpen === 2 ? Styles.open : Styles.closed}>
            Ja, du kan når som helst endre dette på «Min side». Merk at hvis man
            endrer registreringsnummer i et eksisterende abonnement, så er det
            ikke mulig å endre registreringsnummeret enda en gang, før det har
            gått nye 30 dager.
          </p>
          <div
            className={Styles.infobox}
            onClick={() => (isOpen === 3 ? setIsOpen(0) : setIsOpen(3))}
          >
            <p className={Styles.header}>
              Kan jeg opprette abonnement med Vipps på en firmabil?
            </p>
            <div
              className={isOpen === 3 ? Styles.iconCollapse : Styles.iconExpand}
            >
              <ExpandArrow />
            </div>
          </div>
          <p className={isOpen === 3 ? Styles.open : Styles.closed}>
            Nei dessverre, abonnement er kun mulig for private, og det er ikke
            tillatt å benytte månedsabonnement med Vipps for firmabiler, taxi,
            kjøreskolebiler, og andre kommersielle tjenestebiler. Ta gjerne
            kontakt med oss på{" "}
            <a className="link" href="mailto:kundeservice@divein.no">
              kundeservice@divein.no
            </a>{" "}
            for å få et godt tilbud på bedriftsavtale.
          </p>
          <div
            className={Styles.infobox}
            onClick={() => (isOpen === 4 ? setIsOpen(0) : setIsOpen(4))}
          >
            <p className={Styles.header}>
              Kan jeg registrere personlige bilskilt i abonnementet?
            </p>
            <div
              className={isOpen === 4 ? Styles.iconCollapse : Styles.iconExpand}
            >
              <ExpandArrow />
            </div>
          </div>
          <p className={isOpen === 4 ? Styles.open : Styles.closed}>
            Ja, det er ikke noe problem.
          </p>
          <div
            className={Styles.infobox}
            onClick={() => (isOpen === 5 ? setIsOpen(0) : setIsOpen(5))}
          >
            <p className={Styles.header}>
              Hvordan betaler jeg ved månedlig fornyelse av abonnementet?
            </p>
            <div
              className={isOpen === 5 ? Styles.iconCollapse : Styles.iconExpand}
            >
              <ExpandArrow />
            </div>
          </div>
          <p className={isOpen === 5 ? Styles.open : Styles.closed}>
            Vipps trekker automatisk beløpet en gang pr. måned, fra det
            kredittkortet du har registrert i Vipps appen på din mobil.
          </p>
          <div
            className={Styles.infobox}
            onClick={() => (isOpen === 6 ? setIsOpen(0) : setIsOpen(6))}
          >
            <p className={Styles.header}>Hvor lang oppsigelsestid har jeg?</p>
            <div
              className={isOpen === 6 ? Styles.iconCollapse : Styles.iconExpand}
            >
              <ExpandArrow />
            </div>
          </div>
          <p className={isOpen === 6 ? Styles.open : Styles.closed}>
            Du må si opp innen 5 dager før neste trekk blir utført. Oversikt
            over når ditt neste trekk er, ser du på «Min side» i din profil. Her
            kan du også avslutte et abonnement.
          </p>
          <div
            className={Styles.infobox}
            onClick={() => (isOpen === 7 ? setIsOpen(0) : setIsOpen(7))}
          >
            <p className={Styles.header}>
              Kan jeg oppgradere eller nedgradere mitt eksisterende abonnement
              til et annet vaskeprogram?
            </p>
            <div
              className={isOpen === 7 ? Styles.iconCollapse : Styles.iconExpand}
            >
              <ExpandArrow />
            </div>
          </div>
          <p className={isOpen === 7 ? Styles.open : Styles.closed}>
            Ja, du kan når som helst endre dette på «Min side». Ved oppgradering
            betaler du for mellomlegget delt på antall gjenstående dager i
            inneværende månedsperiode, og abonnementet oppgraderes umiddelbart.
            <br />
            Ved nedgradering, vil endringen tre i kraft ved første
            månedsfornyelse.
          </p>
          <div
            className={Styles.infobox}
            onClick={() => (isOpen === 8 ? setIsOpen(0) : setIsOpen(8))}
          >
            <p className={Styles.header}>
              Kan jeg velge et annet vaskeprogram enn det jeg har abonnement
              for?
            </p>
            <div
              className={isOpen === 8 ? Styles.iconCollapse : Styles.iconExpand}
            >
              <ExpandArrow />
            </div>
          </div>
          <p className={isOpen === 8 ? Styles.open : Styles.closed}>
            Ja, dette valget får du når du kommer til skjermen ved innkjøringen
            til vaskegata. Her kan du velge den vasken du ønsker, velger du en
            dyrere bilvask enn den du abonnerer på, så må du betale et
            mellomlegg som trekkes automatisk fra din Vipps.
          </p>
          <div
            className={Styles.infobox}
            onClick={() => (isOpen === 9 ? setIsOpen(0) : setIsOpen(9))}
          >
            <p className={Styles.header}>
              Kan jeg ha abonnement på flere biler?
            </p>
            <div
              className={isOpen === 9 ? Styles.iconCollapse : Styles.iconExpand}
            >
              <ExpandArrow />
            </div>
          </div>
          <p className={isOpen === 9 ? Styles.open : Styles.closed}>
            Ja, du kan enkelt bestille abonnement til så mange biler du ønsker.
            Vi presiserer at abonnementet følger bilen, så hvis du ønsker
            månedsabonnement på to biler, så betaler du prisen for to
            abonnementer.
          </p>
        </div>
      </div>
    </>
  );
};

export default InfoSubscription;
