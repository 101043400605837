import React from "react";
import DiveInLogoSmall from "../components/DiveInLogo/DiveInLogoSmall";
import Header from "../components/Header/Header";

export const Stovsugerhall = () => {
  return (
    <>
      <Header text="Støvsugerhall" backLink="/selvvask" />
      <div className="container">
        <p className="big-text medium med-spacing dark">
          Kjøp tilgang til støvsugerhall
        </p>
        <div className="divein-logo-small">
          <DiveInLogoSmall />
        </div>
      </div>
    </>
  );
};
