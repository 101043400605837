import React, { useState } from "react";
import { getToken, requestConfig } from "../../../utils/helper";
import CloseIconCircle from "../../../utils/Icons/CloseIconCircle";
import { Subscription } from "../../../stores/subscriptionStore";
import { UpdateLicenseURL } from "../../../utils/urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {
  goHome: () => void;
  program: Subscription;
}

const ChangeRegnum = (props: Props) => {
  const [reg, setReg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const changeRegnum = () => {
    if (!reg || reg.length > 8 || reg.length < 2) {
      return;
    }
    if (loading) return;
    setLoading(true);
    setReg("");
    getToken().then((token) => {
      const config = requestConfig(
        "POST",
        JSON.stringify({
          subscriptionId: props.program.id,
          licenseplate: reg,
        }),
        token
      );

      fetch(UpdateLicenseURL, config)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            navigate("/profil");
          } else if (res.status === 403) {
            toast.error("Kan ikke oppdatere registreringsnummer");
            setLoading(false);
          } else {
            toast.error("Noe gikk galt");
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    });
  };
  return (
    <>
      <div
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          marginLeft: "8px",
          marginRight: "8px",
          marginBottom: "40px",
          color: "black",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "0 20px 0 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ marginTop: "50px", marginLeft: "10px" }}>
            Ved endring av registreringsnummer vil det ikke være mulig å endre
            registreringsnummer på nytt igjen før det er gått 30 dager.
          </p>
          <span
            style={{ position: "absolute", marginTop: "1rem", right: "1.5rem" }}
            onClick={props.goHome}
          >
            <CloseIconCircle />
          </span>
          <input
            type="text"
            placeholder="XX12345"
            value={reg}
            onChange={(e) => setReg(e.target.value)}
          />
          <div
            style={{
              height: "3.4rem",
              margin: "8px 0 8px 0",
              paddingLeft: "1rem",
              backgroundColor: "#00bbb4",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              marginBottom: "2rem",
            }}
            onClick={() => changeRegnum()}
          >
            <p>Endre registreringsnummer</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeRegnum;
