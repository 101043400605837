import React, { useEffect, useState } from "react";
import CreateSubscriptionProgram from "../../../components/SubscriptionProgram/SubscriptionProgram";
import { scrollToDoc } from "../../../utils/helper";
import CloseIconCircle from "../../../utils/Icons/CloseIconCircle";
import { useProgramStore } from "../../../stores/programStore";
import { Subscription } from "../../../stores/subscriptionStore";
import { divein, UpgradeSubscriptionRequest } from "../../../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
interface Props {
  goHome: () => void;
  program: Subscription;
}
const ChangeSubscription = (props: Props) => {
  const programStore = useProgramStore();
  const has = props.program.programId;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const observer = new MutationObserver((_, me) => {
    const doc = document.getElementById(has.toString());
    if (doc) {
      scrollToDoc(doc);
      me.disconnect();
      return;
    }
  });
  const InitiateUpgrade = async (templateID: string) => {
    if (loading) return;
    console.log(props.program.id);
    if (templateID === props.program?.programId) return;
    const bod: UpgradeSubscriptionRequest = {
      subscriptionId: props.program?.id,
      templateId: templateID,
    };
    try {
      setLoading(true);
      const { status, responseData } = await divein.upgradeSubscription(bod);
      if (status === 200 && responseData.url !== "") {
        window.location.href = responseData.url;
      } else if (status === 200 && responseData.url === "") {
        navigate("/profil");
      } else {
        if (responseData.error.includes("due date too soon")) {
          toast.error("Kan ikke endre abonnement, prøv igjen etter fornyelse");
        } else {
          toast.error("Noe gikk galt");
        }
      }
      setLoading(false);
      return;
    } catch (err) {
      setLoading(false);
      toast.error(err);
      console.error(err);
    }
  };

  useEffect(() => {
    observer.observe(document, {
      childList: true,
      subtree: true,
    });
  }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          marginLeft: "8px",
          marginRight: "8px",
          marginBottom: "40px",
          color: "black",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "0 20px 0 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ marginTop: "30px", marginLeft: "10px" }}>
            Endring av vaskeprogram <br />
            gjelder fra neste periode <br />{" "}
            {props.program.nextDue.toDate().toLocaleDateString()}.
          </p>
          <span
            style={{ position: "absolute", marginTop: "1rem", right: "1.5rem" }}
            onClick={props.goHome}
          >
            <CloseIconCircle />
          </span>

          {programStore.programData.get()?.map((program, index) => {
            return (
              <div
                key={index}
                id={program.id.toString()}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <CreateSubscriptionProgram
                  current={program}
                  hasProgram={props.program}
                  changeFunc={InitiateUpgrade}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChangeSubscription;
