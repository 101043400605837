import React from 'react'

function ProfileIcon({white}:{white?:boolean}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={33.001}
      height={33}
      viewBox="0 0 33.001 33"
    >
      <defs>
        <style>{`.prefix__profile-icon{fill:${white ? "#fff" : "#00bbb4"}}`}</style>
      </defs>
      <g transform="translate(-504.862 -358.534)">
        <path
          className="prefix__profile-icon"
          d="M521.363 358.534a16.5 16.5 0 1016.5 16.5 16.5 16.5 0 00-16.5-16.5zm0 1.586a14.893 14.893 0 0112.87 22.423 16.807 16.807 0 00-25.74 0 14.894 14.894 0 0112.87-22.425z"
        />
        <circle
          className="prefix__profile-icon"
          cx={6.23}
          cy={6.23}
          r={6.23}
          transform="rotate(-13.283 1820.036 -2024.607)"
        />
      </g>
    </svg>
  )
}

export default ProfileIcon
