import React, { useEffect, useState } from "react";

import DiveInLogoWhite from "../components/DiveInLogo/DiveInLogoBigWhite";
import VippsLoginButton from "../utils/VippsLoginButton/VippsLoginButton";

import NavItem from "../components/NavItem/NavItem";
import { getTokens } from "../stores/tokenStore";

import {useState as useSpecialState} from "@hookstate/core"

import { useNavigate } from "react-router-dom";
import { LoginUrl } from "../utils/urls";
import { auth } from "../utils/Firebase";
import { Token, TokenType } from "../types";
import ProfileIcon from "../utils/Icons/ProfileIcon"; 
import { Subscription, SubscriptionStatus, useSubscriptionStore } from "../stores/subscriptionStore";
import UserStore from "../stores/userStore";

const MainPage = () => {
  const [hasUser, setHasUser] = useState(false);
  const [vgPrivateModalShowing, setVgPrivateModalShowing] = useState(false);
  const [vSelvvaskShowing, setvSelvvaskShowing] = useState(false);
  const [vMattevaskerShowing, setvMattevaskerShowing] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>()

  const userStore = useSpecialState(UserStore)

  const subStore = useSubscriptionStore()
  const navigate = useNavigate();

  const [token, setToken] = useState<Token>();

  useEffect(() => {
    if (auth.currentUser) {
      setHasUser(true);
    }
  }, [auth.currentUser]);
  useEffect(() => {
    setTimeout(() => {
      // const blowdryToks = getTokens(TokenType.blowdry);
      const washToks = getTokens(TokenType.washgate);
      const freeToks = getTokens(TokenType.freeWash)
      if (freeToks.length > 0) {
        setToken(freeToks[0])
      } else if (washToks.length > 0) {
        setToken(washToks[0]);
      }
    const subs = subStore.subscriptions.get()
    setSubscriptions(subs)
    console.log(subs)
    }, 200);
  }, [setToken]);
  return (
    <>
      <div className="container main"
      >
        {userStore.user?.get() && (
        <div
          className="text-white flex items-center gap-1 ml-auto px-3 pt-3"
          onClick={() => navigate("/profil")}
        >{userStore.user?.firstName?.get()?.split(" ")[0]}
          <ProfileIcon white />
        </div>
        )}
        <div className="nav-box">
          {token && (
            <div
              style={{
                minHeight: 50,
                background: "black",
                color: "#fff",
                padding: "5px 20px",
                marginBottom: 20,
              }}
              onClick={() => {
                navigate(`/qrkode/${token.id}`);
              }}
            >
              <h3>Du har en aktiv vaskekode - Trykk her for visning</h3>
            </div>
          )}
          {subscriptions && subscriptions.map(sub => {
            return (
            <div
              key={sub.id}
              style={{
                minHeight: 50,
                background: "black",
                color: "#fff",
                padding: "5px 20px",
                marginBottom: 20,
              }}
              onClick={() => {
                navigate(`/profil/abonnement/${sub.id}`);
              }}
            >
              <h3>{sub.productName} abonnement - {sub.licenseplate} - {sub.status === SubscriptionStatus.Active ? <span className="text-primary-400">aktivt</span>:<span className="text-red-400">feilet</span>}</h3>
            </div>
            )
          })}
          <NavItem
            text={"VASKEGATE"}
            url={"/vaskegate"}
            isShowing={vgPrivateModalShowing}
            setShowing={setVgPrivateModalShowing}
            id={"FDEJks-74jE"}
            title={"Vaskeabonnement"}
          />
          <NavItem
            text={"SELVVASK"}
            url={"/selvvask"}
            isShowing={vSelvvaskShowing}
            setShowing={setvSelvvaskShowing}
            id={"G-JyC5adA78"}
            title={"Slik bruker du vår selvvask"}
          />
          <NavItem
            text={"MATTEVASK"}
            url={"/mattevask"}
            isShowing={vMattevaskerShowing}
            setShowing={setvMattevaskerShowing}
            id={""}
            title={""}
          />
        </div>
        {!hasUser && (
          <VippsLoginButton
            onClick={() => ((window.location.href = LoginUrl), true)}
            classes="w-4/6 mt-4"
          />
        )}
        <div className="divein-logo">
          <DiveInLogoWhite />
        </div>
      </div>
    </>
  );
};

export default MainPage;
