import React from 'react'

function ExpandArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 306 306"
    >
      <path d="M270.3 58.65L153 175.95 35.7 58.65 0 94.35l153 153 153-153z" />
    </svg>
  )
}

export default ExpandArrow
