import Styles from "./SubscriptionProgram.module.css";
import React, { useEffect, useState } from "react";
import { ProgramType } from "../ProgramComponent/ProgramComponent";
import { Subscription } from "../../stores/subscriptionStore";
import { calcPayNow } from "../../utils/helper";

interface Props {
  current: ProgramType;
  hasProgram: Subscription;
  changeFunc: (templateID: string) => Promise<void>;
}

const ProgramComponent = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [payNow, setPayNow] = useState(0);
  const difference =
    props.current.subscriptionPrice - props.hasProgram.periodAmount;

  const lastTime = props.hasProgram.lastDue.toDate().getTime();
  const nextTime = props.hasProgram.nextDue.toDate().getTime();

  useEffect(() => {
    if (props.current) {
      setIsLoading(false);
    }
    setPayNow(calcPayNow(difference, lastTime, nextTime));
  }, []);

  //   return <> </>
  return (
    <>
      {isLoading ? (
        <p>...</p>
      ) : (
        <div
          className={
            props.current.name.toLowerCase() === "ultimate"
              ? Styles.programContainer
              : [Styles.programContainer, Styles.blandBorder].join(" ")
          }
        >
          <div
            className={
              props.current.name.toLowerCase() === "ultimate"
                ? Styles.programHalfcircleTop
                : [Styles.programHalfcircleTop, Styles.blandHalfcircleTop].join(
                    " "
                  )
            }
            id={props.current.id.toString()}
          >
            <p>{props.current.id}</p>
          </div>
          <p className={Styles.programHeader}>
            {props.current.name.toUpperCase()}
          </p>
          <div className={Styles.programInfoText}>
            {props.current.excludesText.map((txt, i) => {
              return (
                <p key={i} className={Styles.notIncluded}>
                  {txt}
                </p>
              );
            })}
            {props.current.includesText.map((txt, i) => {
              return <p key={i}>{txt}</p>;
            })}
          </div>

          <div
            className={
              props.current.name === "Børsteløs"
                ? Styles.greyBanner
                : Styles.programBanner
            }
            onClick={() => props.changeFunc(props.current.id)}
          >
            <div className={Styles.programBannerLeft}>
              <div className={Styles.programButton}>
                {difference > 0 ? (
                  <p>OPPGRADER</p>
                ) : difference < 0 ? (
                  <p>NEDGRADER</p>
                ) : props.current.name === props.hasProgram.productName ? (
                  <p>DITT PROGRAM</p>
                ) : (
                  <p>ENDRE</p>
                )}
              </div>
            </div>
            <div className={Styles.programBannerRight}>
              <div className={Styles.programBannerRightCircle}>
                <p>{props.current.subscriptionPrice},-</p>
                <p>pr. mnd</p>
              </div>
            </div>
          </div>
          <div className={Styles.programBottom}>
            <p>Betal nå: {payNow > 0 ? payNow : 0},-</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ProgramComponent;
