import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import DiveInLogoSmall from "../../../components/DiveInLogo/DiveInLogoSmall";
import ChangeRegnum from "./ChangeRegnum";
import ChangeSubscription from "./ChangeSubscription";
import CancelSubscription from "./CancelSubscription";

import { useState as useSpecialState } from "@hookstate/core";
import SubscriptionStore, {
  Subscription,
} from "../../../stores/subscriptionStore";
import { useNavigate, useParams } from "react-router-dom";
import { ProgramIdToName } from "../../../utils/constants";
import { subscriptionIsDone } from "../../../utils/helper";

const SubscriptionPage = () => {
  const subscriptionStore = useSpecialState(SubscriptionStore);
  const [program, setProgram] = useState<Subscription>();
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [currentPage, setCurrentPage] = useState("home");

  useEffect(() => {
    window.scrollTo(0, 0);
    const prog = subscriptionStore.subscriptions
      .get()
      .filter((sub) => sub.id === id)[0];
    if (prog) {
      setProgram(prog);
      console.log(prog);
    } else {
      navigate("/profil");
    }
  }, []);

  useEffect(() => {
    console.log(program);
  }, [program]);
  return (
    <>
      <Header text="Abonnement" backLink="/profil" />
      <div
        style={{
          width: "100vw",
          minHeight: "90%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            marginTop: "48px",
            marginLeft: "40px",
            fontSize: "1.5rem",
            letterSpacing: "0.58px",
          }}
        >
          {program?.productName} - {program?.licenseplate}
        </p>
        {currentPage === "home" && (
          <>
            <div
              style={{
                padding: "0 20px 0 20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ margin: "7px 0 7px 0", letterSpacing: "0.38px" }}>
                {program && subscriptionIsDone(program) && (
                  <p className="text-lg text-red-500">
                    Abonnementet er avsluttet
                  </p>
                )}
                <p>Månedsbeløp:</p>
                <p>{program?.periodAmount},-</p>
                <p>Opprettet:</p>
                <p>{program?.created.toDate().toLocaleString()}</p>
                <p>Neste trekk:</p>
                <p>
                  {program?.nextDue.toDate().toLocaleDateString()}
                  {program?.cancelled && (
                    <span className="text-red-600"> (kansellert)</span>
                  )}
                </p>
                {program?.toBeDowngraded && (
                  <p>
                    Ditt abonnement vil bil nedgradert til{" "}
                    {ProgramIdToName.get(program?.downgradeTo)} ved neste trekk
                  </p>
                )}
              </div>
              {!program?.cancelled && (
                <>
                  <div
                    style={{
                      height: "3.4rem",
                      margin: "8px 0 8px 0",
                      paddingLeft: "1rem",
                      backgroundColor: "#00bbb4",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => setCurrentPage("abonnement")}
                  >
                    <p>Endre vaskeprogram</p>
                  </div>
                  <div
                    style={{
                      height: "3.4rem",
                      margin: "8px 0 8px 0",
                      paddingLeft: "1rem",
                      backgroundColor: "#00bbb4",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => setCurrentPage("regnummer")}
                  >
                    <p>Endre registreringsnummer</p>
                  </div>
                  <div
                    style={{
                      height: "3.4rem",
                      margin: "8px 0 8px 0",
                      paddingLeft: "1rem",
                      backgroundColor: "#00bbb4",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => setCurrentPage("avslutt")}
                  >
                    <p>Avslutt abonnement</p>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {currentPage === "avslutt" && (
          <CancelSubscription
            goHome={() => setCurrentPage("home")}
            program={program}
          />
        )}
        {currentPage === "regnummer" && (
          <ChangeRegnum
            goHome={() => setCurrentPage("home")}
            program={program}
          />
        )}
        {currentPage === "abonnement" && (
          <ChangeSubscription
            goHome={() => setCurrentPage("home")}
            program={program}
          />
        )}
        <span style={{ margin: "auto 0 48px 0", alignSelf: "center" }}>
          <DiveInLogoSmall />
        </span>
      </div>
    </>
  );
};

export default SubscriptionPage;
