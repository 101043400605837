import React from "react";
import { LoginUrl } from "../utils/urls";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

export const LoginToast = ({ text }: { text?: string }) => {
  const location = useLocation();
  return (
    <div>
      {text ? text : "Du må være innlogget for å utføre denne handlingen."}
      <div
        className="bg-primary-400 px-3 py-2 my-2 text-white w-fit"
        onClick={() => {
          Cookies.set("divein/redirect", location.pathname);
          window.location.href = LoginUrl;
        }}
      >
        Logg inn nå
      </div>
    </div>
  );
};
