import React from "react";
import { Link } from "react-router-dom";
import ProfileIcon from "../../utils/Icons/ProfileIcon";
import BackButton from "../BackButton";
import { useLocation } from "react-router";
import Styles from "./Header.module.css";
import { useState as useSpecialState } from "@hookstate/core";
import UserStore from "../../stores/userStore";

const Header = (props: { text?: string, backLink: string }) => {
  const location = useLocation();
    const customerStore = useSpecialState(UserStore)
  return (
    <div className={Styles.header}>
      {location.pathname !== "/" && (
        <span className={Styles.backbutton}>
          <BackButton to={props.backLink} goBack={props.backLink === "back" ? true : false}/>
        </span>
      )}
      {props && (
        <p className={[Styles.headerText, "primary"].join(" ")}>{props.text}</p>
      )}
      <Link
        to="/profil"
        className={Styles.profileLink}
        onClick={() => {
          if (location.pathname !== "/vaskegate") {
            localStorage.setItem("redirectMeTo", location.pathname);
          }
        }}
      >
        <p className={[Styles.login, "primary"].join(" ")}>
          {customerStore.user?.get() 
            ? customerStore.user?.firstName.get()?.split(" ")[0]
            : "logg inn"}
        </p>
        <span className={Styles.profileIcon}>
          <ProfileIcon white />
        </span>
      </Link>
    </div>
  );
};

export default Header;
