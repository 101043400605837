import React from "react";
import { Routes, Route } from "react-router-dom";

import NotFound from "./pages/NotFound";
import MainPage from "./pages/MainPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import Vaskegate from "./pages/Vaskegate";
import Abonnement from "./pages/Abonnement";
import Singelvask from "./pages/Singelvask";
import Mattevask from "./pages/Mattevask";
import Torkeprogram from "./pages/Torkeprogram";
import Firmakunde from "./pages/Firmakunde/Firmakunde";
import SubscriptionPage from "./pages/ProfilePage/Subscription/SubscriptionPage";
import InfoAsfalt from "./pages/Info/InfoAsfalt";
import InfoTakboks from "./pages/Info/InfoTakboks";
import InfoSubscription from "./pages/Info/InfoSubscription";
import Selvvask from "./pages/Selvvask";
import Valuecard from "./pages/Valuecard";
import QRCodeViewer from "./pages/QRCodeViewer";
import Auth from "./pages/Auth";
import VippsLanding from "./pages/vipps/VippsLanding";

import AuthRequired from "./utils/AuthRequired";
import { auth, db, getLocation } from "./utils/Firebase";

import { useState as useSpecialState } from "@hookstate/core";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuthState } from "react-firebase-hooks/auth";
import {
  useCustomerStore,
  useSubscriptionListener,
  useTokenListener,
} from "./hooks";

import { useEffect, useState } from "react";
import ProgramStore, { BlowdryOption } from "./stores/programStore";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { useLocationStore } from "./stores/locationStore";
import { SubscriptionLanding } from "./pages/vipps/SubscriptionLanding";
import { ProgramType } from "./components/ProgramComponent/ProgramComponent";
import { Stovsugerhall } from "./pages/Stovsugerhall";
import { InfoSubscriptionTerms } from "./pages/Info/InfoSubscriptionTerms";

import LogRocket from "logrocket";
import { isLocalhost } from "./utils/helper";
import { PaymentsHistory } from "./pages/ProfilePage/Payments/PaymentsHistory";

if (!isLocalhost) {
  LogRocket.init("6rgojl/webapp-prod");
}

function App() {
  const [user, loading, error] = useAuthState(auth);
  const [programLoading, setProgramLoading] = useState(true);

  const programStore = useSpecialState(ProgramStore);

  const getProgramData = async () => {
    const collectionRef = collection(
      db,
      "productSpecs",
      "washgate",
      "products"
    );
    const q = query(collectionRef, orderBy("id", "asc"));

    const tempArr: ProgramType[] = [];
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
      if (doc.data()["id"] === "options") {
        programStore.programOptions.set(doc.data()["options"]);
      } else if (doc.data()["id"] === "blowdry") {
        programStore.programBlowdry.set(doc.data() as BlowdryOption);
      } else {
        tempArr.push(doc.data() as ProgramType);
      }
    });
    programStore.programData.set(tempArr);
    setProgramLoading(false);
  };

  useCustomerStore();
  useTokenListener(user);
  useSubscriptionListener(user);

  const locationStore = useLocationStore();

  useEffect(() => {
    if (!programStore.programData.get()) {
      getProgramData();
    } else {
      setProgramLoading(false);
    }
    const init = async () => {
      try {
        const site = await getLocation("1");
        locationStore.set(site);
      } catch (err) {
        console.error(err);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production")
      console.log = function () {
        return;
      };
  }, []);

  if (error) {
    console.log(JSON.stringify(error));
  }
  if (loading || programLoading) {
    console.log("initializing..");
    return null;
  } else {
    const isAuth = user ? true : false;
    return (
      <>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/auth" element={<Auth />} />

          <Route path="/firmakunde" element={<Firmakunde />} />
          <Route path="/info/asfaltfjerner" element={<InfoAsfalt />} />
          <Route path="/info/takboks" element={<InfoTakboks />} />
          <Route path="/info/abonnement" element={<InfoSubscription />} />
          <Route
            path="/info/abonnement/vilkaar"
            element={<InfoSubscriptionTerms />}
          />

          <Route
            path="/profil/abonnement/:id"
            element={
              <AuthRequired authed={isAuth}>
                <SubscriptionPage />
              </AuthRequired>
            }
          />

          <Route path="/vipps" element={<VippsLanding />} />
          <Route path="/vipps/abonnement" element={<SubscriptionLanding />} />

          <Route path="/profil" element={<ProfilePage />} />
          <Route
            path="/profil/betalinger"
            element={
              <AuthRequired authed={isAuth}>
                <PaymentsHistory uid={user?.uid} />
              </AuthRequired>
            }
          />
          <Route path="/vaskegate" element={<Vaskegate />} />
          <Route
            path="/vaskegate/singelvask/:program"
            element={<Singelvask />}
          />

          <Route
            path="/vaskegate/abonnement/:program"
            element={<Abonnement />}
          />
          <Route path="/selvvask" element={<Selvvask />} />
          <Route path="/mattevask" element={<Mattevask />} />
          <Route path="/torkeprogram" element={<Torkeprogram />} />
          <Route path="/verdikort" element={<Valuecard />} />
          <Route path="/qrkode/:id" element={<QRCodeViewer user={user} />} />
          <Route path="/stovsugerhall" element={<Stovsugerhall />} />
          <Route path="/" element={<MainPage />} />
        </Routes>
        <ToastContainer position="top-center" autoClose={3000} />
      </>
    );
  }
}

export default App;
