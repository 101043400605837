import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import InfoIconPrimary from "../utils/Icons/InfoIconPrimary";
import DiveInLogoSmall from "../components/DiveInLogo/DiveInLogoSmall";
import Button from "../components/Button";
import VippsButton from "../assets/pay_with_vipps.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getAndSetCorpToken } from "../stores/tokenStore";
import { CorporateClientData, Token } from "../types";
import { divein, BuyBlowdryRequest, InitResponse } from "../utils/api";
import { toast } from "react-toastify";
import { BlowdryOption, getBlowdryProgram } from "../stores/programStore";
import { isLoggedIn } from "../utils/Firebase";
import { LoginToast } from "../components/LoginToast";
import { useState as useSpecialState } from "@hookstate/core";
import Img from "../assets/Støvsugerhall.jpeg";
import UserStore from "../stores/userStore";
import VippsLoginButton from "../utils/VippsLoginButton/VippsLoginButton";
import Cookies from "js-cookie";
import { LoginUrl } from "../utils/urls";

const Torkeprogram = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState(false);
  const [bdp, setBdp] = useState<BlowdryOption>();

  const location = useLocation();
  const userStore = useSpecialState(UserStore);

  const [corpToken, setCorpToken] = useState<Token[]>();
  const [, setCorpData] = useState<CorporateClientData[]>();

  const initiate = async (withCorp: boolean, tokId?: string) => {
    if (loading) return;
    if (!isLoggedIn()) {
      toast.error(<LoginToast />, { autoClose: false, icon: false });
      return;
    }
    setLoading(true);
    const bod: BuyBlowdryRequest = {
      locationId: "1",
      productId: bdp?.productId,
      chosenRoofbox: checked,
    };
    if (withCorp) bod.corpTokenId = tokId;

    const { status, responseData } = await divein.buyBlowdry(bod);
    if (status > 299) {
      toast.error("Noe gikk galt");
      setLoading(false);
    } else {
      window.location.href = (responseData as InitResponse).url;
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setBdp(getBlowdryProgram());
    const timeoutId = setTimeout(() => {
      getAndSetCorpToken(setCorpToken, setCorpData);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, []);
  useEffect(() => {
    console.log(bdp);
  }, [bdp]);

  useEffect(() => {
    console.log(corpToken);
  }, [corpToken]);

  return (
    <>
      <Header text="Tørkeprogram" backLink="back" />
      <div className="container">
        <p className="big-text medium med-spacing dark">Kjøp tørkeprogram</p>
        <div style={{ width: "80%", position: "relative" }}>
          <img src={Img} alt="" style={{ width: "100%" }} />
        </div>
        <div className="small-spacing text-box">
          <p>
            Bilen kjøres gjennom vaskegate på vanlig måte, men det er kun
            tørkeprogrammet som aktiveres.
          </p>
          <p>
            Etter endt vaskeprogram, får du tilgang til innendørs støvsugerhall.
          </p>
          <p>Støvsugerene er som alltid helt gratis å benytte!</p>
          <p>
            Ved kjøp av dette vil du få en QR-kode som du scanner foran porten
            til vaskegata
          </p>
        </div>
        {userStore.user.get() ? (
          <>
            <div className="outer-checkbox">
              <div
                className="iconBox"
                onClick={() => navigate("/info/takboks")}
              >
                <InfoIconPrimary height={25} />
              </div>
              <label className="checkboxContainer">
                <input
                  type="checkbox"
                  id={"checkbox-takboks"}
                  name={"takboks"}
                  value={"takboks"}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className="svgLabel" htmlFor={"checkbox-takboks"}>
                  <span></span>
                </label>
                <label className="labelText">
                  <label htmlFor={"checkbox-takboks"} className="inputLabel">
                    Takboks
                  </label>
                </label>
              </label>
            </div>

            <div className="price-text grid column-two big">
              <p className="one medium">Totalt</p>
              <p className="two primary extrabold">{bdp?.price},-</p>
            </div>
            <div className="vipps" onClick={() => initiate(false)}>
              <img src={VippsButton} alt="Betal med Vipps" />
            </div>
          </>
        ) : (
          <VippsLoginButton
            onClick={() => {
              const inFiveMinutes = new Date(
                new Date().getTime() + 2 * 60 * 1000
              );
              Cookies.set("divein/redirect", location.pathname, {
                domain: "divein.no",
                expires: inFiveMinutes,
              });
              window.location.href = LoginUrl;
            }}
            classes="w-4/6 mt-6 mb-4"
          />
        )}
        {corpToken && (
          <>
            {corpToken.map((t) => (
              <Button
                key={t.id}
                onClick={() => initiate(true, t.id)}
                text={`Belast ${
                  (t?.data as unknown as CorporateClientData).companyName
                }`}
              />
            ))}
          </>
        )}
        <div className="divein-logo-small">
          <DiveInLogoSmall />
        </div>
      </div>
    </>
  );
};

export default Torkeprogram;
