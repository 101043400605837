import React, { useState, useEffect, Fragment } from "react";
import { useState as useSpecialState } from "@hookstate/core";
import Styles from "./ProfilePage.module.css";
import DiveInLogo from "../../components/DiveInLogo/DiveInLogoBig";
import DiveInLogoSmall from "../../components/DiveInLogo/DiveInLogoSmall";
import Header from "../../components/Header/Header";
import VippsLoginButton from "../../utils/VippsLoginButton/VippsLoginButton";
import { useNavigate } from "react-router-dom";
import { auth } from "../../utils/Firebase";
import SubscriptionStore from "../../stores/subscriptionStore";
import { LoginUrl } from "../../utils/urls";
import { divein } from "../../utils/api";
import { toast } from "react-toastify";
import {
  getTokens,
  getTotalValuecardValue,
  removeToken,
} from "../../stores/tokenStore";
import {
  CouponTokenData,
  Customer,
  TokenType,
  // ValuecardData,
} from "../../types";
import UserStore from "../../stores/userStore";
import { CouponCategories } from "../../utils/constants";
import ForwardArrowDark from "../../assets/forward-arrow-dark.svg";
import EditIcon from "../../assets/edit-icon.svg";
import XIcon from "../../assets/x-icon.svg";
import isEmail from "validator/lib/isEmail";
import ForwardArrowWhite from "../../utils/Icons/ForwardArrowWhite";
import { subscriptionIsDone } from "../../utils/helper";

const ProfilePage = () => {
  const navigate = useNavigate();
  const user = auth.currentUser;
  const userStore = useSpecialState(UserStore);
  const subscriptionStore = useSpecialState(SubscriptionStore);
  const [discountCode, setDiscountCode] = useState<string>("");
  const [couponTokenId, setCouponTokenId] = useState<string>();
  const [couponTokData, setCouponTokData] = useState<CouponTokenData[]>();
  const [loading, setLoading] = useState(false);

  const [remainingSelfservice, setRemainingSelfservice] = useState(0);

  const [editing, setEditing] = useState(false);
  const [email, setEmail] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);

  const [decideRoofboxOpen, setDecideRoofboxOpen] = useState(false);

  const resetCouponStates = () => {
    setCouponTokData([]);
    if (couponTokenId) removeToken(couponTokenId);
  };

  const login = (): void => {
    window.location.href = LoginUrl;
  };
  const logOut = (): void => {
    auth.signOut();
    userStore.user.set({} as Customer);
    window.location.href = "/profil";
  };

  const saveEmail = async () => {
    if (emailLoading) return;
    if (!isEmail(email)) {
      toast.error("Ugyldig e-postadresse");
      return;
    }
    try {
      setEmailLoading(true);
      const { status } = await divein.saveNewEmail(email);
      if (status !== 200) {
        toast.error("Noe gikk galt");
        setEmailLoading(false);
        return;
      }
      setEmailLoading(false);
      setEditing(false);
    } catch (err) {
      setEmailLoading(false);
      console.error(err);
      toast.error((err as Error).message);
    }
  };

  const applyCoupon = async () => {
    if (loading) return;
    if (discountCode.length < 2) return;
    try {
      setLoading(true);
      const { status } = await divein.applyCouponCode(
        discountCode.toUpperCase()
      );
      setLoading(false);
      setDiscountCode("");
      switch (status) {
        case 404:
          toast.error("Ikke funnet", { position: "bottom-center" });
          break;
        case 409:
          toast.error("Brukt", { position: "bottom-center" });
          break;
        case 500:
          toast.error("Noe gikk galt", { position: "bottom-center" });
          break;
        default:
          resetCouponStates();
          toast.success("Oh, happy days! Rabatt lagt til", {
            position: "bottom-center",
          });
          checkCoupons()
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const generateFreewash = async (withRoofbox: boolean) => {
    const { status, responseData } = await divein.generateFreewashWithStamps(
      withRoofbox
    );
    if (status === 403) {
      toast.error("Ikke nok vaskestempel til gratis vask");
    } else if (status === 201) {
      navigate(`/qrkode/${responseData.tokenId}`);
    } else {
      toast.error("Noe gikk galt");
    }
  };

  useEffect(() => {
    if (editing) {
      document.getElementById("emailInput").focus();
    }
  }, [editing]);

  const checkCoupons = () => {
      const coupToks = getTokens(TokenType.coupon);
      const newArr = [];
      coupToks.forEach((tok) =>
        newArr.push(tok.data as unknown as CouponTokenData)
      );
      if (coupToks.length > 0) {
        setCouponTokenId(coupToks[0].id);
        setCouponTokData(newArr);
        console.log(coupToks);
      }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      const remainingValue = getTotalValuecardValue();
      setRemainingSelfservice(remainingValue);

      checkCoupons()
    }, 100);
    window.scrollTo(0, 0);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Header text="Profil" backLink="/" />
      <div className={Styles.container}>
        {userStore.user.get() ? (
          <>
            <p className={Styles.header}>Din informasjon</p>
            <div className={Styles.userinfo}>
              <p>Navn:</p>
              <p>
                {userStore.user?.firstName?.get()}{" "}
                {userStore.user?.surname?.get()}
              </p>
              <p>Mobil:</p>
              <p>{user?.phoneNumber}</p>
              <p>E-post:</p>
              {editing ? (
                <>
                  <div className="w-full flex items-center">
                    <input
                      type="text"
                      name="email"
                      id="emailInput"
                      placeholder={userStore.user?.email.get()}
                      style={{
                        margin: 0,
                        padding: "0px 0px",
                        fontSize: "1rem",
                        height: "2rem",
                        textAlign: "center",
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <img
                      src={XIcon}
                      alt="Avbryt redigering"
                      className="-mr-5 h-5"
                      onClick={() => setEditing(false)}
                    />
                  </div>
                  <button
                    className="text-white bg-primary-400 border-0 py-2 col-start-2 mt-2"
                    onClick={() => saveEmail()}
                  >
                    Lagre
                  </button>
                </>
              ) : (
                <p>
                  {userStore.user?.email?.get()}
                  <img
                    src={EditIcon}
                    alt="Rediger e-post"
                    className="w-5 ml-2 -mr-6"
                    onClick={() => {
                      setEditing(true);
                      setEmail(userStore.user?.email.get());
                    }}
                  />
                </p>
              )}
            </div>

            {/* Payment history button */}
            <div
              className={Styles.button}
              onClick={() => navigate("/profil/betalinger")}
            >
              <p>Siste kjøp</p>
              <span className=" mr-5 mt-1">
                <ForwardArrowWhite />
              </span>
            </div>

            {/* Subscriptions */}
            <div className={Styles.subscription}>
              <p className={Styles.header}>Abonnement</p>
              {subscriptionStore.subscriptions?.get()?.map((sub, idx) => {
                return (
                  <Fragment key={idx}>
                    <div
                      className={Styles.button}
                      onClick={() => navigate(`/profil/abonnement/${sub.id}`)}
                    >
                      <p>
                        {sub.productName}{" "}
                        {subscriptionIsDone(sub) ? (
                          <span className="text-sm text-red-600">
                            (avsluttet)
                          </span>
                        ) : (
                          sub.cancelled && (
                            <span className="text-sm text-red-600">
                              (kansellert)
                            </span>
                          )
                        )}
                      </p>
                      <p>{sub.licenseplate}</p>
                    </div>
                  </Fragment>
                );
              })}
              <div
                className={Styles.button}
                onClick={() => navigate("/vaskegate")}
              >
                <p>Nytt abonnement</p>
                <span className=" mr-5 mt-1">
                  <ForwardArrowWhite />
                </span>
              </div>
            </div>

            {/* Discount code input  */}
            <div className={Styles.discount}>
              <p className={Styles.header}>Rabattkode</p>
              <div className="flex items-center">
                <input
                  type="text"
                  className="w-2/3"
                  style={{ margin: 0, textTransform: "uppercase" }}
                  placeholder="Rabattkode"
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") applyCoupon();
                  }}
                />
                <div
                  className="w-1/3 bg-primary-400 h-full flex justify-center items-center text-xl text-white bold"
                  style={{ height: "3.43rem" }}
                  onClick={() => applyCoupon()}
                >
                  OK
                </div>
              </div>
            </div>

            {/* Active discount code information */}
            <div className={Styles.washcard}>
              {couponTokData &&
                couponTokData.map((token, idx) => {
                  return (
                    <>
                      <p key={idx}>{token?.description}</p>
                      {token.appliesToCategory === "7" ? (
                        <p>Første {token.intervalCount > 1 ? `${token.intervalCount} måneder` : "måned"} til kroner {token.price},-</p>
                      ) : token.isFixedPrice ? (
                        <p>Til kroner {token?.price},-</p>
                      ) : (
                        <p>Rabatt {(token?.discount * 100).toFixed(2)}%</p>
                      )}
                      <p></p>
                      <p>
                        {token?.appliesToCategory
                          ? `${CouponCategories[token.appliesToCategory]}`
                          : ""}
                      </p>
                    </>
                  );
                })}
            </div>

            {/* Wash stamp information */}
            <p className={Styles.header}>
              Antall vaskestempel: {userStore.user?.washStamps?.get()}
            </p>
            {userStore.user?.washStamps?.get() >= 5 && (
              <>
                {!decideRoofboxOpen ? (
                  <div
                    className={
                      " bg-accent px-4 mb-10 flex items-center justify-between"
                    }
                    onClick={() => setDecideRoofboxOpen(true)}
                  >
                    <p className="font-bold dark">Utløs gratis ultimate</p>
                    <span className="forward-arrow">
                      <img src={ForwardArrowDark} alt="" />
                    </span>
                  </div>
                ) : (
                  <>
                    <div
                      className="bg-accent px-4 mb-10 flex items-center justify-between"
                      onClick={() => generateFreewash(false)}
                    >
                      <p className="font-bold dark">Uten takboks</p>
                      <span className="forward-arrow">
                        <img src={ForwardArrowDark} alt="" />
                      </span>
                    </div>
                    <div
                      className="bg-accent px-4 mb-10 flex items-center justify-between"
                      onClick={() => generateFreewash(true)}
                    >
                      <p className="font-bold dark">Med takboks</p>
                      <span className="forward-arrow">
                        <img src={ForwardArrowDark} alt="" />
                      </span>
                    </div>
                  </>
                )}
              </>
            )}

            {remainingSelfservice && (
              <p className={Styles.header + " mt-0"}>
                Saldo selvvask: {remainingSelfservice}
              </p>
            )}
            <div className={Styles.button} onClick={logOut}>
              <p>Logg ut</p>
            </div>
            <span className={Styles.diveinlogo}>
              <DiveInLogoSmall />
            </span>
          </>
        ) : (
          <>
            <span className={Styles.diveinlogo}>
              <DiveInLogo />
            </span>
            <h1 className="center-text">Heisann!</h1>
            <p className="center-text">Du er visst ikke logget inn.</p>
            <VippsLoginButton onClick={() => login()} />
          </>
        )}
      </div>
    </>
  );
};

export default ProfilePage;
