import { createState, useState, none } from '@hookstate/core'
import React from 'react'
import { Token, TokenType, CorporateClientData, ValuecardData } from '../types'


export const TokenStore = createState<Array<Token>>([])

export const useTokenStore = () => {
  return useState(TokenStore)
}


export const getCoupons = () => {
  const toks = TokenStore.get()
  return toks.filter(token => token.kind === TokenType.coupon)
}

export const getTokens = (type: TokenType) => {
  const toks = TokenStore.get()
  console.log(toks)
  const ts = toks.filter(token => { return token.kind === type && token.expiresAt.toDate() > new Date() })
  return ts
}

export const removeToken = (tokId: string) => {
  const indexOfToken = TokenStore.get().findIndex(t => t.id === tokId);
  if (indexOfToken != -1) {
    TokenStore[indexOfToken].set(none)
  }
}

export const getTokenById = (id: string) => {
  return TokenStore.get().find(token => token.id === id)
}

export const getAndSetCorpToken = (setter: (value: React.SetStateAction<Token[]>) => void, dataSetter: (value: React.SetStateAction<CorporateClientData[]>) => void) => {
  const cp = getTokens(TokenType.corporateClient);
  if (cp) {
    setter(cp);
    const data: CorporateClientData[] = []
    cp.forEach(c => data.push(c.data as unknown as CorporateClientData))
    dataSetter(data)
  }
};

export const getTotalValuecardValue = () => {
  const vcs = getTokens(TokenType.valuecard)
  let remaining = 0
  for (const t of vcs) {
    remaining += (t.data as unknown as ValuecardData).remainingValue
  }
  return remaining
}