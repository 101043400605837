import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect } from 'react'
import { db } from '../utils/Firebase'
import { useState as useSpecialState } from '@hookstate/core'
import { TokenStore } from '../stores/tokenStore'
import { User } from 'firebase/auth'
import { Token, TokenStatus } from '../types'
import { b64Decode } from '../utils/helper'

const useTokenListener = (user: User) => {
  const tokenStore = useSpecialState(TokenStore)

  // const valuecardStore = useSpecialState(ValuecardStore)

  useEffect(() => {
    let unsubscribe
    if (user?.uid) {
      const q = query(collection(db, 'customers', user.uid, 'tokens'), where("status", "==", TokenStatus.active), where("expiresAt", ">=", new Date()))
      unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data() as Token
          const decodedData = JSON.parse(b64Decode(data.data))
          data.data = decodedData
          const indexOfToken = tokenStore.get().findIndex(tok => tok.id === data.id)
          if (indexOfToken === -1) {
            tokenStore.merge([data])
          } else {
            tokenStore[indexOfToken].set(data)
          }
        })
      })
    } else {
      unsubscribe = () => null
    }

    return () => unsubscribe()
  }, [user])
}

// eslint-disable-next-line react/display-name        
export default useTokenListener
