import React, { useEffect } from "react";
import { auth } from "../utils/Firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState as useSpecialState } from "@hookstate/core";
import UserStore from "../stores/userStore";

import {
  setPersistence,
  browserLocalPersistence,
  signInWithCustomToken,
} from "firebase/auth";
import Cookies from "js-cookie";

const Auth = () => {
  const navigate = useNavigate();
  const userStore = useSpecialState(UserStore);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  // const token =
  //   'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay15ODl5ckBkaXZlaW4tYWQ3MmQuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImV4cCI6MTY0MTkwMjYwNiwiaWF0IjoxNjQxODk5MDA2LCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay15ODl5ckBkaXZlaW4tYWQ3MmQuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJ1aWQiOiIxZjgzN2QwOC02M2Q1LTQxNzktYTVkOS05MjdiOTQ5ZDRkOTQiLCJjbGFpbXMiOnsiZW1haWwiOiJ0b3Jiam9ybi5mam9ydG9mdEBnbWFpbC5jb20iLCJuYW1lIjoiS2xoa2dhIE9rd3d3aCIsInBob25lIjoiKzQ3NDgwNjA0NDMiLCJ1aWQiOiIxZjgzN2QwOC02M2Q1LTQxNzktYTVkOS05MjdiOTQ5ZDRkOTQifX0.UBDpG_iXgGQITbceNqD21MgjFPY2gXm8k5_jAUj-HNtKJJpGoBk4mP6937aD36EtNTbkYbW1Q24zKkBIEGiF2q4Yrh7eBNtQMTbIaES8p4WFf5AALl2U6qcLpr6V31kkV_Z4_bglZJ9GKeV7zPlE0_HjLBD_bu2D8M_suUHmZ9c_nEcNdl4d-4zyaaZzjnaT2zqdXvvXos53hzTLnFuNgVtVlT0bCbCnhGYmbU29Wr-VZX_fItBgi6ousEtySkEQ_xqhngGvJhrd7TKNPRIJLVUylkIl1RDD0eb9CdOjt2UHZmHWfa4L7Ix_S3FJF1p1hXER-buU6fqmQk35n0n4ng'

  useEffect(() => {
    if (auth.currentUser) {
      console.log("already authed, -> ", auth.currentUser.uid);
      navigate("/");
    } else {
      if (!token) {
        console.log("token missing... abort");
      } else {
        setPersistence(auth, browserLocalPersistence).then(() => {
          signInWithCustomToken(auth, token)
            .then((userToken) => {
              userStore.userCred.set(userToken);
              const redirectTo = Cookies.get("divein/redirect");
              if (redirectTo) {
                navigate(redirectTo, { replace: true });
                Cookies.remove("divein/redirect");
              } else {
                navigate("/", { replace: true });
              }
            })
            .catch((err) => {
              console.log(err);
              navigate("/");
            });
        });
      }
    }
  }, []);

  return <></>;
};

export default Auth;
