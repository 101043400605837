import React from "react";
Header;
import Header from "../../components/Header/Header";

export const InfoSubscriptionTerms = () => {
  return (
    <>
      <Header text="Avtalevilkår" backLink="back" />
      <div className="container">
        <h3 style={{ textAlign: "center", letterSpacing: "0.8" }}>
          Avtalevilkår for bruk av Dive In Carwash
        </h3>
        <div className="inner px-4">
          <h4 style={{ letterSpacing: "0.8" }}>Innledning</h4>
          <p>
            Dive In Carwash tilbyr bilvask, selvvask og bilpleie gjennom
            stiftelse av abonnement, kjøp av enkeltprogram for vask eller
            individuelle kjøp («Tjenestene»).
            <br /> Informasjon om selgers produkter finnes på{" "}
            <a href="https://www.divein.no" className="text-primary-700">
              www.divein.no
            </a>
            .
          </p>
          <p>
            Dette kjøpet er regulert av de nedenstående standard
            salgsbetingelser for forbrukerkjøp av varer over internett.
            Forbrukerkjøp over internett reguleres hovedsakelig av avtaleloven,
            forbrukerkjøpsloven, markedsføringsloven, angrerettloven og
            e-handelsloven, og disse lovene gir forbrukeren ufravikelige
            rettigheter. Lovene er tilgjengelig på <a href="https://www.lovdata.no" className="text-primary-400">www.lovdata.no</a>. Vilkårene i
            denne avtalen skal ikke forstås som noen begrensning i de
            lovbestemte rettighetene, men oppstiller i all hovedsak partenes
            rettigheter og plikter for handelen.
          </p>
          <h4 className="ml-6">1.&nbsp;&nbsp; Avtalen</h4>
          <p>
            Avtalen består av disse salgsbetingelsene, opplysninger gitt i
            bestillingsløsningen og eventuelt særskilt avtalte vilkår. Ved
            eventuell motstrid mellom opplysningene, går det som særskilt er
            avtalt mellom partene foran, så lenge det ikke strider mot
            ufravikelig lovgivning.
          </p>
          <p>
            Ved å bruke tjenestene godtar du disse avtalevilkårene og samtidig
            akseptere å overholde dem. Avtalevilkårene gjelder så lenge du har
            et kundeforhold til oss. Dersom du på noen tidspunkt ikke er enig i
            eller i stand til å overholde avtalevilkårene, har du ingen rett til
            å bruke tjenestene fra oss.
          </p>
          <p>
            Avtalen vil i tillegg bli utfylt av relevante lovbestemmelser som
            regulerer kjøp av varer mellom næringsdrivende og forbrukere.
          </p>
          <h4 className="ml-6">2.&nbsp;&nbsp; Partene</h4>
          <p>
            Tjenestene er levert av Dive In AS, Ivar Lykkes vei 7, 7075 Tiller,
            kundeservice@divein.no, tlf. 92303030, org.nr. 920 512 852, og
            betegnes i det følgende som selger/selgeren eller vi/oss.
          </p>
          <h4 className="ml-6">3.&nbsp;&nbsp; Pris</h4>
          <p>
            Den oppgitte prisen for tjenester er den totale prisen kjøper skal
            betale. Denne prisen inkluderer alle avgifter og tilleggskostnader.
            Ytterligere kostnader som selger før kjøpet ikke har informert om,
            skal kjøper ikke bære.
          </p>
          <h4 className="ml-6">4.&nbsp;&nbsp; Avtaleinngåelse</h4>
          <p>
            Avtalen er bindende for begge parter når kjøperen har sendt sin
            bestilling til selgeren.
          </p>
          <p>
            Avtalen er likevel ikke bindende hvis det har forekommet skrive-
            eller tastefeil i tilbudet fra selgeren i bestillingsløsningen i
            nettbutikken eller i kjøperens bestilling, og den annen part innså
            eller burde ha innsett at det forelå en slik feil.
          </p>
          <h4 className="ml-6">
            5.&nbsp;&nbsp; Tilgang til og bruk av tjenestene
          </h4>
          <p>
            For å bruke tjenesten månedsabonnement må du bli kunde ved å
            opprette en kundeprofil, samt godta disse avtalevilkår.
            Kundeprofilen opprettes ved bestilling av tjenesten på selgers
            hjemmesider. Du kan avslutte medlemskapet ditt gjennom nevnte
            hjemmeside.
          </p>
          <p>
            Når du har opprettet en kundeprofil kan du få tilgang til tjenestene
            ved å tegne et løpende abonnement og betale månedlig på forhånd til
            du sier opp abonnementet.
          </p>
          <p>
            Ved bestilling av abonnementstjenesten aksepterer du følgende
            betingelser:
          </p>
          <ol>
            <li>
              Opplysninger du har oppgitt i registreringsprosessen er korrekt,
              og du må varsle om opplysningene endres på noen tidspunkt.
            </li>
            <li>
              Tjenesten er kun gyldig i Norge og for registrerte biler.
              Abonnementet gjelder kun for den registrerte bilen. Abonnement for
              ubegrenset vask kan ikke benyttes av taxier eller andre
              kommersielle kjøretøy. Egne avtaler tilbys firmakunder. Les mer om
              firmaavtaler{" "}
              <a href="#" className="text-primary-400">
                her
              </a>
              .
            </li>
            <li>
              Du kan fritt endre registreringsnummer som ligger inne på
              abonnementet uten ekstra kostnad. Dette gjelder imidlertid en gang
              hver 30. dag. Dersom du har endret registreringsnummer en gang, så
              må du vente til en ny 30. dagers periode har gått, for å gjøre en
              ny endring. Endring gjøres i din profil på{" "}
              <a href="https://www.divein.no" className="text-primary-400">
                www.divein.no
              </a>
              .
            </li>
            <li>
              Du må ha et gyldig betalingskort i Vipps app. Vipps brukervilkår
              gjelder
            </li>
            <li>
              Vi registrerer bilens skiltnummer gjennom gjenkjenningssystem av
              bilskilt.
              <br />
              Du godtar herved at bilens sjåfør kan opptre på vegne av eier av
              abonnementet, også med hensyn til oppgradering av vask og
              tilhørende tjenester.
            </li>
            <li>
              Ved bruk av tjenestene må du være oppmerksom på riktig bruk av
              vaskeanlegget. Les mer om hvordan selgers tjenester anvendes i § 7
              og § 8.
            </li>
            <li>
              Driftsstopp og service gjøres i all hovedsak i perioder på dagen
              hvor vaskefrekvensen er lavest. En kan likevel forvente at slike
              stopp vil kunne inntreffe. Kjøper har ikke krav på refusjon ved
              korte driftsstans eller mangler med tjenesten.
            </li>
            <li>Vaskeanlegget og området er videoovervåket.</li>
            <li>
              Du kan ikke bruke eller la andre bruke tjenestene til selger for
              ulovlige aktivitet eller upassende formål.
            </li>
            <li>
              All bilvask avgir fotavtrykk på miljø gjennom bruk av kjemikalier, vann og strøm. Vasker man mer enn 6 ganger i løpet av en 30. dagers periode vil vasken kun inneholde forvask, høytrykksspyling, børstevask (ikke børster ved børsteløst abonnement) og enkel tørk.
            </li>
            <li>
              Oppsigelse av abonnement må gjøres senest 5. kalenderdager før ny
              avtaleperiode. Oppsigelse gjøres via{" "}
              <a href="https://www.divein.no" className="text-primary-400">
                www.divein.no
              </a>
              .
            </li>
          </ol>
          <p>
            Øvrige tjenester krever ikke at du oppretter en kundeprofil, og kan
            betales i kortterminal ved selgers lokaler, eller ved bestilling
            gjennom selgers nettsider eller på mobil. Ovennevnte betingelser
            gjelder så langt de passer for øvrige tjenester.
          </p>
          <h4 className="ml-6">
            6.&nbsp;&nbsp; Korrekt bruk av selgers tjenester
          </h4>
          <p>Korrekt bruk av automatisk vaskehall:</p>
          <ol>
            <li>
              Kontroller at bilen er i overensstemmelse med maksimal høyde og
              bredde. De konkrete målene for bruk av vaskegate finner du på
              selgers hjemmeside under «Vaskegate».
            </li>
            <li>
              Dersom du er i tvil om bilen kan vaskes med ekstrautstyr, ta
              kontakt med betjeningen når du ankommer for veiledning.
            </li>
            <li>
              Ved bruk av anlegget er det viktig at du følger anvisninger fra
              Dive In Carwash, fra vår hjemmeside{" "}
              <a href="https://www.divein.no" className="text-primary-400">
                www.divein.no
              </a>
              , eller andre kommunikasjonsmidler i forbindelse med anlegget.
            </li>
            <p> Korrekt bruk av øvrige tjenester: </p>

            <li>
              Det er kun tillatt å bruke egen felgrens og flyverustfjerner i
              selvvasken. Andre kjemikalier er ikke tillatt da det forstyrrer
              vannrensesystemet.
            </li>
            <li>Følg vaskeinstrukser nøye.</li>
            <li>
              Bruk høytrykkslange med stor forsiktighet, ettersom vannstrålene
              kan forårsake skade hvis de brukes for nært. Vi anbefaler avstand
              på minst 15 cm. Ikke rett stråle mot andre mennesker eller dyr.
            </li>
            <li>
              Det er ikke tillatt å bruke Dive In Carwash sine
              lokaler/uteområder til andre formål, f.eks. verkstedarbeid og
              polering/detailing.
            </li>
            <li>
              Mislighold kan føre til avkortning av tjeneste eller utestengelse.
            </li>
          </ol>
          <h4 className="ml-6">7.&nbsp;&nbsp; Kjøpers forpliktersel</h4>
          <p>
            Kjøper forplikter seg til å følge anvisninger på selgers hjemmeside;{" "}
            <a href="https://www.divein.no" className="text-primary-400">
              www.divein.no
            </a>
            , samt informasjon og brukerveiledninger ved selgers lokaler.
          </p>
          <h4 className="ml-6">8.&nbsp;&nbsp; Betaling</h4>
          <p>
            For enkelte av selgers tjenester kan kunden velge mellom
            forhåndsbetaling via Vipps, kort i betalingsterminal, eller
            bestilling gjennom selgers nettsider.
          </p>
          <p>
            Ved valg av Vipps som betalingsmetode vil kunden bli belastet
            umiddelbart.
          </p>
          <p>
            For abonnement på ubegrenset vask, er det kun mulig å betale med
            Vipps.
          </p>
          <p>
            Dersom kjøperen bruker kredittkort eller debetkort ved betaling, kan
            selgeren reservere kjøpesummen på kortet ved bestilling. Kortet blir
            belastet samme dag som tjenesten bestilles.
          </p>
          <p>
            Dersom du ønsker å oppgradere abonnementet, kan dette gjøres gjennom
            din profil på{" "}
            <a href="https://www.divein.no" className="text-primary-400">
              www.divein.no
            </a>
            . Du betaler bare for differansen for gjenværende dager i det
            eksisterende abonnementet frem til ny periode starter.
          </p>
          <h4 className="ml-6">9.&nbsp;&nbsp; Levering</h4>
          <p>
            Levering av tjenesten regnes som påbegynt den dagen tjenesten blir
            levert til kunde.
          </p>
          <p>
            Ved kjøp av abonnement begynner abonnementet å løpe fra og med
            første vask, og alltid senest 14 dager etter avtaleinngåelsen dersom
            du ikke har utført vask før dette tidspunkt.
          </p>
          <h4 className="ml-6">10.&nbsp;&nbsp; Angrerett</h4>
          <p>
            Med mindre avtalen er unntatt fra angrerett, kan kjøperen angre
            kjøpet av varen i henhold til angrerettloven.
          </p>
          <p>Angrefristen begynner å løpe fra dagen tjenesten er bestilt.</p>
          <p>
            Kjøperen må gi selger melding om bruk av angreretten innen 14 dager
            fra fristen begynner å løpe. I fristen inkluderes alle
            kalenderdager. Dersom fristen ender på en lørdag, helligdag eller
            høytidsdag forlenges fristen til nærmeste virkedag.
          </p>
          <p>
            Angrefristen anses overholdt dersom melding er sendt før utløpet av
            fristen. Kjøper har bevisbyrden for at angreretten er blitt gjort
            gjeldende, og meldingen bør derfor skje skriftlig (angrerettskjema,
            e-post eller brev).
          </p>
          <p>
            Hvis abonnementet er delvis utnyttet, reduseres refusjonen
            tilsvarende pris for hver enkelt vask som er gjennomført oppad
            begrenset til abonnementets beløp.
          </p>
          <p>
            Angreretten kan benyttes ved å avslutte abonnementet innen 14 dager
            etter avtaleinngåelsen via{" "}
            <a href="https://www.divein.no" className="text-primary-400">
              www.divein.no
            </a>
            . Underrett oss skriftlig via epost til{" "}
            <a
              href="mailto:kundeservice@divein.no"
              className="text-primary-400"
            >
              kundeservice@divein.no
            </a>
            . Utøves denne retten vil selger snarlig etter å ha mottatt og
            akseptert din henvendelse, kansellere reservasjonen på
            betalingsmidlet eller refundere beløpet.
          </p>
          <h4 className="ml-6">
            11.&nbsp;&nbsp; Forsinkelse og manglende levering – Kjøpers
            rettigheter og frist for å melde krav
          </h4>
          <p>
            Dersom selgeren ikke leverer tjenesten eller leverer den for sent i
            henhold til avtalen mellom partene, og dette ikke skyldes kjøperen
            eller forhold på kjøperens side, kan kjøperen i henhold til reglene
            i forbrukerkjøpslovens kapittel 5 etter omstendighetene kreve
            oppfyllelse, heve avtalen og/eller kreve erstatning fra selgeren.
          </p>
          <p>
            Ved krav om misligholdsbeføyelser bør meldingen av bevishensyn være
            skriftlig (for eksempel e-post).
          </p>
          <p>
            Oppfyllelse
            <br />
            Kjøper kan fastholde kjøpet og kreve oppfyllelse fra selger. Kjøper
            kan imidlertid ikke kreve oppfyllelse dersom det foreligger en
            hindring som selgeren ikke kan overvinne, eller dersom oppfyllelse
            vil medføre en så stor ulempe eller kostnad for selger at det står i
            vesentlig misforhold til kjøperens interesse i at selgeren
            oppfyller. Skulle vanskene falle bort innen rimelig tid, kan kjøper
            likevel kreve oppfyllelse.
          </p>
          <p>
            Kjøperen taper sin rett til å kreve oppfyllelse om han eller hun
            venter urimelig lenge med å fremme kravet.
          </p>
          <p>
            Heving
            <br />
            Dersom selgeren ikke leverer tjenesten på leveringstidspunktet, skal
            kjøperen oppfordre selger til å levere innen en rimelig
            tilleggsfrist for oppfyllelse. Dersom selger ikke leverer tjenesten
            innen tilleggsfristen, kan kjøperen heve kjøpet.
          </p>
          <p>
            Kjøper kan imidlertid heve kjøpet umiddelbart hvis selger nekter å
            levere tjenesten.
          </p>
          <p>
            Erstatning
            <br />
            Kjøperen kan kreve erstatning for lidt tap som følge av
            forsinkelsen. Dette gjelder imidlertid ikke dersom selgeren godtgjør
            at forsinkelsen skyldes hindring utenfor selgers kontroll som ikke
            med rimelighet kunne blitt tatt i betraktning på avtaletiden,
            unngått, eller overvunnet følgene av.
          </p>

          <h4 className="ml-6">
            12.&nbsp;&nbsp; Mangel ved tjenesten – kjøpers rettigheter og
            reklamasjonsfrist
          </h4>
          <p>
            Hvis det foreligger en mangel ved tjenesten må kjøper innen rimelig
            tid etter at den ble oppdaget eller burde ha blitt oppdaget, gi
            selger melding om at hun eller han vil påberope seg mangelen. Kjøper
            har alltid reklamert tidsnok dersom det skjer innen 2 mnd. fra
            mangelen ble oppdaget eller burde blitt oppdaget. Reklamasjon kan
            senest skje to år etter utført tjeneste.
          </p>
          <p>
            Dersom tjenesten har en mangel og dette ikke skyldes kjøper eller
            forhold på kjøpers side, kan kjøper i henhold til reglene i
            forbrukerkjøpsloven kap. 6, etter omstendighetene velge mellom
            retting og omlevering, kreve prisavslag, kreve avtalen hevet
            og/eller kreve erstatning fra selger.
          </p>
          <p>Reklamasjon til selger bør skje skriftlig.</p>
          <p>
            Retting eller omlevering
            <br />
            Kjøperen kan velge mellom å kreve mangelen rettet eller levering av
            tilsvarende tjeneste. Selger kan likevel motsette seg kjøperens krav
            dersom gjennomføringen av kravet er umulig eller volder selgeren
            urimelige kostnader. Retting eller omlevering skal foretas innen
            rimelig tid. Selger har i utgangspunktet ikke rett til å foreta mer
            enn to avhjelpsforsøk for samme mangel.
          </p>
          <p>
            Prisavslag
            <br />
            Kjøper kan kreve et passende prisavslag dersom varen ikke blir
            rettet eller omlevert. Dette innebærer at forholdet mellom nedsatt
            og avtalt pris svarer til forholdet mellom tingens verdi i
            mangelfull og kontraktsmessig stand. Dersom særlige grunner taler
            for det, kan prisavslaget i stedet settes lik mangelens betydning
            for kjøperen.
          </p>
          <p>
            Heving
            <br />
            Dersom varen ikke er rettet eller omlevert, kan kjøperen også heve
            kjøpet når mangelen ikke er uvesentlig.
          </p>
          <h4 className="ml-6">
            13.&nbsp;&nbsp; Selger sine rettigheter ved kjøpers mislighold
          </h4>
          <p>
            Dersom kjøperen ikke betaler eller oppfyller de øvrige pliktene
            etter avtalen eller loven, og dette ikke skyldes selgeren eller
            forhold på selgerens side, kan selgeren i henhold til reglene i
            forbrukerkjøpsloven kapittel 9 etter omstendighetene kreve avtalen
            hevet samt kreve erstatning fra kjøperen. Selgeren vil også etter
            omstendighetene kunne kreve renter ved forsinket betaling og evt.
            inkassogebyr.
          </p>
          <p>
            Misbruk av tjenesten vil kunne medføre at medlemskapet blir sagt opp
            uten forvarsel. Med misbruk siktes det til manipulering av bilskilt
            eller andre metoder som omgår prinsippet om et abonnement pr. bil.
          </p>
          <p>
            Heving
            <br />
            Selger kan heve avtalen dersom det foreligger vesentlig
            betalingsmislighold eller annet vesentlig mislighold fra kjøperens
            side. Selger kan likevel ikke heve dersom hele kjøpesummen er
            betalt. Fastsetter selger en rimelig tilleggsfrist for oppfyllelse
            og kjøperen ikke betaler innen denne fristen, kan selger heve
            kjøpet.
          </p>
          <p>
            Renter ved forsinket betaling/inkassogebyr
            <br />
            Dersom kjøperen ikke betaler kjøpesummen i henhold til avtalen, kan
            selger kreve renter av kjøpesummen etter forsinkelsesrenteloven. Ved
            manglende betaling kan kravet, etter forutgående varsel, bli sendt
            til Kjøper kan da bli holdt ansvarlig for gebyr etter inkassoloven.
          </p>
          <h4 className="ml-6">
            14.&nbsp;&nbsp; Endringer av vilkår, priser, avtalevilkår m.m.
          </h4>
          <p>
            Alle priser for selgers produkter er tilgjengelig på vår hjemmeside.
            Det tas forbehold om at prisene kan endre seg over tid, noe du som
            kunde vil bli informert om i overensstemmelse med disse vilkårene.
          </p>
          <p>
            Vi gir deg 30 dagers varsel via e-post/sms hvis det skjer endringer
            i prisene. Vesentlig endringer vedrørende abonnement på ubegrenset
            bilvask varsles individuelt via e-post. Vi forbeholder oss retten
            til å endre prisene på kortere varsel dersom selgers kostnader
            forbundet med abonnementet øker på grunn av endringer i skatter,
            avgifter, valutakurssvingninger, endringer i priser til tredjepart,
            beslutninger fra myndighetene eller lovendringer eller andre
            lovreguleringer, og prisøkningen til abonnementet tilsvarer denne
            økningen. Dersom du ikke godtar prisøkningen, har du rett til å si
            opp abonnementet med virkning fra den dagen når endringen trer i
            kraft.
          </p>
          <p>
            Vi forbeholder oss også retten til å endre innholdet i pakkene du
            abonnerer på. Dersom endringen medfører vesentlig ulempe for deg som
            kunde, vil vi varsle deg pr. e-post. Endringen vil tre i kraft på
            den datoen som angitt i varslet, men ikke tidligere enn 30
            kalenderdager etter varslet er sendt eller gjort kjent.
          </p>
          <p>
            Vi forbeholder oss retten til å gjøre endringer i og eller tillegge
            avtalevilkår til denne avtalen. Endringer eller tillegg vil
            annonseres på vår hjemmeside. Du oppfordres til å besøke vår
            hjemmeside regelmessig. Du vil bli særskilt varslet om vesentlige
            endringer eller tillegg via e-post minst 30 dager før endringen
            eller tillegget trer i kraft. Du kan ved vesentlig endring si opp
            avtalen med virkning fra den dag endringen trer i kraft. Dette
            forutsetter at endringer ikke åpenbart er til din fordel, og
            forutsetter at vi får forhåndsvarsel om oppsigelsen. Hvis du ikke
            sier opp avtalen i henhold til ovennevnte, betyr det at du godtar
            endringen.
          </p>
          <p>
            Vi forbeholder oss retten til å stanse salg av tjenester og
            abonnementsløsning. Kunder som benytter slike tjenester vil bli
            særskilt varslet via epost minst 30 dager før stansing av tjenesten.
          </p>
          <h4 className="ml-6">15.&nbsp;&nbsp; Personopplysninger</h4>
          <p>
            Behandlingsansvarlig for innsamlede personopplysninger er selger.
            Med mindre kjøperen samtykker til noe annet, kan selgeren, i tråd
            med personopplysningsloven, kun innhente og lagre de
            personopplysninger som er nødvendig for at selgeren skal kunne
            gjennomføre forpliktelsene etter avtalen. Kjøperens
            personopplysninger vil kun bli utlevert til andre hvis det er
            nødvendig for at selger skal få gjennomført avtalen med kjøperen,
            eller i lovbestemte tilfelle.
          </p>
          <h4 className="ml-6">16.&nbsp;&nbsp; Konfliktløsning</h4>
          <p>
            Klager rettes til selger innen rimelig tid, jf. §§ 11 og 12. Partene
            skal forsøke å løse eventuelle tvister i minnelighet.
          </p>
        </div>
      </div>
    </>
  );
};
