import React, { KeyboardEvent, useState } from "react";
import DiveInLogoSmall from "../../components/DiveInLogo/DiveInLogoSmall";
import { ValidateMe } from "../../utils/ValidateMe";
import TrashIcon from "../../utils/Icons/Trash";
import Styles from "./Firmakunde.module.css";
import { Inputs } from "./Firmakunde";

interface Props {
  inputs: Inputs;
  selectedOption: string;
  selectedCars: string[];
  selectedNumbers: string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  checkNext: (e: KeyboardEvent<HTMLInputElement>) => void;
  handleAdd: (val: string, numOrCar: "num" | "car") => void;
  handleOption: (str: string) => void;
  handleRemoveCar: (index: number) => void;
  handleRemoveNumber: (index: number) => void;
  onSubmit: () => string | void;
  error?: string
  loading:boolean
}

const FirmakundeBedriftFaktura = (props: Props) => {
  const [currentCar, setCurrentCar] = useState<string>();
  const [currentNumber, setCurrentNumber] = useState<string>();
  return (
    <>
      <p className="big-text big-spacing dark">
        Vi ønsker å registrere kjøp
        <br /> av bilvask ved hjelp av:
      </p>
      <form className="radio-valuecard">
        <label className="block" htmlFor="skiltgjenkjenning">
          <input
            type="radio"
            onChange={(event) => props.handleOption(event.target.value)}
            value="skiltgjenkjenning"
            id="skiltgjenkjenning"
            checked={props.selectedOption === "skiltgjenkjenning"}
            name="leasingCompanies"
            autoFocus={true}
          />

          <label className="radio-valuecard-icon" htmlFor="skiltgjenkjenning">
            <span className="radio-valuecard-icon-inner"></span>
          </label>
          <label
            htmlFor="skiltgjenkjenning"
            className="big-text primary medium"
          >
            Skiltgjenkjenning
          </label>
        </label>
        <label className="block" htmlFor="mobilnummer">
          <input
            type="radio"
            onChange={(event) => props.handleOption(event.target.value)}
            value="mobilnummer"
            id="mobilnummer"
            checked={props.selectedOption === "mobilnummer"}
            name="leasingCompanies"
          />

          <label className="radio-valuecard-icon" htmlFor="mobilnummer">
            <span className="radio-valuecard-icon-inner"></span>
          </label>
          <label htmlFor="mobilnummer" className="big-text primary medium">
            Ansattes mobil
          </label>
        </label>
      </form>
      <div className={Styles.inputBox}>
        <input
          id={"1"}
          type="text"
          key="CoName"
          name="CoName"
          onChange={props.onChange}
          value={props.inputs.CoName}
          required={true}
          onBlur={(e) => ValidateMe(e.target)}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Bedriftens navn</span>
      </div>

      <div className={Styles.inputBox}>
        <input
          id={"2"}
          type="text"
          key="CoOrgNum"
          name="CoOrgNum"
          onChange={props.onChange}
          value={props.inputs.CoOrgNum}
          required={true}
          onBlur={(e) => ValidateMe(e.target, "CoOrgNum")}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Organisasjonsnummer</span>
      </div>
      <div className={Styles.inputBox}>
        <input
          id={"3"}
          type="text"
          key="Address"
          name="Address"
          onChange={props.onChange}
          value={props.inputs.Address}
          required={true}
          onBlur={(e) => ValidateMe(e.target)}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Adresse</span>
      </div>
      <div className={Styles.inputBox}>
        <input
          id={"4"}
          type="text"
          key="ZipCode"
          name="ZipCode"
          onChange={props.onChange}
          value={props.inputs.ZipCode}
          required={true}
          onBlur={(e) => ValidateMe(e.target, "ZipCode")}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Postnummer</span>
      </div>
      <div className={Styles.inputBox}>
        <input
          id={"5"}
          type="text"
          key="City"
          name="City"
          onChange={props.onChange}
          value={props.inputs.City}
          required={true}
          onBlur={(e) => ValidateMe(e.target)}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Poststed</span>
      </div>
      <div className={Styles.inputBox}>
        <input
          id={"6"}
          type="text"
          key="BillingReference"
          name="BillingReference"
          onChange={props.onChange}
          value={props.inputs.BillingReference}
          required={true}
          onBlur={(e) => ValidateMe(e.target)}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Fakturareferanse</span>
      </div>
      <div className={Styles.inputBox}>
        <input
          id={"7"}
          type="text"
          key="email"
          name="BillingEmail"
          onChange={props.onChange}
          value={props.inputs.BillingEmail}
          required={true}
          onBlur={(e) => ValidateMe(e.target, "email")}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>E-post adresse for faktura</span>
      </div>
      <br />
      <div className={Styles.inputBox}>
        <input
          id={"8"}
          type="text"
          key="CoContactPerson"
          name="CoContactPerson"
          onChange={props.onChange}
          value={props.inputs.CoContactPerson}
          required={true}
          onBlur={(e) => ValidateMe(e.target)}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Bedriftens kontaktperson</span>
      </div>
      <div className={Styles.inputBox}>
        <input
          id={"9"}
          type="text"
          key="email"
          name="CoEmail"
          onChange={props.onChange}
          placeholder=" "
          value={props.inputs.CoEmail}
          required={true}
          onBlur={(e) => ValidateMe(e.target, "email")}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>E-post kontaktperson</span>
      </div>
      <div className={Styles.inputBox}>
        <input
          id={"10"}
          type="text"
          key="CoCellphone"
          name="CoCellphone"
          onChange={props.onChange}
          value={props.inputs.CoCellphone}
          required={true}
          onBlur={(e) => ValidateMe(e.target, "CoCellphone")}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Mobilnummer kontaktperson</span>
      </div>

      {props.selectedOption === "skiltgjenkjenning" && (
        <>
          <div className={Styles.inputBox}>
            <input
              id={"11"}
              type="text"
              key="CoName"
              name="CoName"
              maxLength={7}
              onChange={(e) => setCurrentCar(e.target.value)}
              value={currentCar}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  props.handleAdd(currentCar, "car");
                  setCurrentCar("");
                }
              }}
            />
            <div
              className={Styles.smallButton}
              onClick={(e) => {
                props.handleAdd(currentCar, "car");
                setCurrentCar("");
                (
                  (e.target as HTMLInputElement)
                    .previousElementSibling as HTMLElement
                ).focus();
              }}
            >
              Legg til
            </div>
            <span className={Styles.floatingLabel}>
              Bilens registreringsnummer
            </span>
          </div>
          <div style={{ width: "80%" }}>
            {props.selectedCars.map((item, idx) => {
              const index = idx;
              return (
                <li className={Styles.item} key={idx}>
                  {item}
                  <span onClick={() => props.handleRemoveCar(index)}>
                    <TrashIcon />
                  </span>
                </li>
              );
            })}
          </div>
        </>
      )}
      {props.selectedOption === "mobilnummer" && (
        <>
          <div className={Styles.inputBox}>
            <input
              id={"11"}
              type="text"
              key="carLicense"
              name="carLicense"
              onChange={(e) => setCurrentNumber(e.target.value)}
              value={currentNumber}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  props.handleAdd(currentNumber, "num");
                  setCurrentNumber("");
                }
              }}
            />
            <span className={Styles.floatingLabel}>
              Mobilnummer til ansatte
            </span>
            <div
              className={Styles.smallButton}
              onClick={(e) => {
                props.handleAdd(currentNumber, "num");
                setCurrentNumber("");
                (
                  (e.target as HTMLInputElement)
                    .previousElementSibling as HTMLElement
                ).focus();
              }}
            >
              Legg til
            </div>
          </div>
          <div className={Styles.listBox}>
            {props.selectedNumbers.map((item, idx) => {
              const index = idx;
              return (
                <li className={Styles.item} key={idx}>
                  {item}
                  <span onClick={() => props.handleRemoveNumber(index)}>
                    <TrashIcon />
                  </span>
                </li>
              );
            })}
          </div>
        </>
      )}
      <div className={Styles.inputBox}>
        <textarea
          typeof="text"
          key="Comments"
          name="Comments"
          onChange={props.onChange}
          value={props.inputs.Comments}
        />
        <span className={Styles.floatingLabel}>Kommentarer(valgfritt)</span>
      </div>
      <p style={{ maxWidth: "80%", marginTop: 0 }}>
        Fakturaer sendes på EHF, alternativt til registrert e-post adresse
      </p>
      {props.error && (
        <p>{props.error}</p>
      )}
      <div className={Styles.button} onClick={() => props.onSubmit()}>
        <p>{props.loading ? "Sender..." : "Send inn"}</p>
      </div>
      <div className={Styles.infoBox}>
        <p>
          Har du spørsmål? <br /> Ta gjerne kontakt med oss på <br />
          telefon{" "}
          <a className="link" href="tel:91116685">
            91116685{" "}
          </a>
          eller e-post{" "}
          <a className="link" href="mailto:kundeservice@divein.no">
            kundeservice@divein.no
          </a>
        </p>
      </div>
      <span className="divein-logo-small">
        <DiveInLogoSmall />
      </span>
    </>
  );
};
export default FirmakundeBedriftFaktura;
