import { createState } from '@hookstate/core'
import { UserCredential } from 'firebase/auth'
import { Customer } from '../types'
import { getCustomer } from '../utils/Firebase'

interface UserState {
  userCred?: UserCredential
  user: Customer
}

const UserStore = createState<UserState>({
  userCred: null,
  user: null,
})

export const fetchCustomer = async (uid: string) => {
  if (UserStore.user.get()) return UserStore.user.get();
  const customer = await getCustomer(uid)
  return customer
}

export default UserStore
