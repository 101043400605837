import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  limit,
  orderBy,
  // connectFirestoreEmulator,
} from "firebase/firestore";
import { Payment, PaymentStatus } from "../stores/subscriptionStore";
import { Customer, Product, Site, Token } from "../types";

// DiveIn firebaseConfig
//
const firebaseConfig = {
  apiKey: "AIzaSyC89UFGoc0psspy2t3aF3W09wufP0H-k6k",
  authDomain: "divein-ad72d.firebaseapp.com",
  projectId: "divein-ad72d",
  storageBucket: "divein-ad72d.appspot.com",
  messagingSenderId: "128202310528",
  appId: "1:128202310528:web:f259e151e85a3d04283765",
  measurementId: "G-CR8MR04NXN",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const signOut = () => {
  auth.signOut();
};

// const hostname = window.location.hostname;
// if (hostname === "localhost" || hostname === "127.0.0.1") {
//   connectFirestoreEmulator(db, "localhost", 8086);
//   console.log("emulator");
// }

export const isLoggedIn = () => {
  if (auth.currentUser) {
    return true;
  }
  return false;
};

export const getTokenByIdFromDB = async (id: string) => {
  const uid = auth.currentUser?.uid;
  if (uid) {
    const ref = collection(db, "customers", uid, "tokens");
    const q = query(ref, where("id", "==", id));
    const snap = await getDocs(q);
    return snap.docs[0].data() as Token;
  }
};

export const getValuecardProducts = async () => {
  const ref = collection(db, "productSpecs", "valuecard", "products");
  const snap = await getDocs(ref);
  const ret: Product[] = [];
  snap.forEach((product) => ret.push(product.data() as Product));
  const sorted = ret.sort((a, b) => (a.value > b.value ? -1 : 1));
  return sorted;
};

export const getCustomer = async (uid: string) => {
  const ref = doc(db, "customers", uid);
  const snap = await getDoc(ref);
  if (snap.data()) {
    return snap.data() as Customer;
  }
};

export const getLocation = async (siteID: string) => {
  const ref = collection(db, "sites");
  const snap = await getDocs(query(ref, where("id", "==", siteID)));
  const res = snap.docs[0].data() as Site;
  return res;
};

export const getCustomerPayments = async (uid: string) => {
  const q = query(
    collection(db, "customers", uid, "payments"),
    where("status", "==", PaymentStatus.Paid),
    limit(5),
    orderBy("created", "desc")
  );
  const snap = await getDocs(q);
  const pays: Payment[] = [];
  snap.forEach((doc) => pays.push(doc.data() as Payment));
  const last = snap.docs[snap.docs.length - 1];
  return { pays, last };
};

export const getAllCustomerPayments = async (uid: string) => {
  const q = query(collection(db, "customers", uid, "payments"));
  const snap = await getDocs(q);
  const pays: Payment[] = [];
  snap.forEach((doc) => pays.push(doc.data() as Payment));
  console.log(pays);
  return { pays };
};

export { auth, db, signOut };
