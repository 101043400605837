import React from 'react'

function VippsLogo() {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 64 16"
      width={64}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M64 3.838C63.28 1.091 61.531 0 59.145 0c-1.934 0-4.36 1.09-4.36 3.717 0 1.697 1.172 3.03 3.084 3.374l1.81.323c1.235.222 1.585.687 1.585 1.313 0 .707-.761 1.111-1.893 1.111-1.48 0-2.406-.525-2.55-2l-2.613.404c.411 2.849 2.962 4.02 5.266 4.02 2.18 0 4.505-1.252 4.505-3.777 0-1.717-1.05-2.97-3.003-3.334l-1.996-.363c-1.11-.202-1.48-.748-1.48-1.273 0-.667.72-1.09 1.707-1.09 1.255 0 2.14.423 2.18 1.817zM5.925 8.161L8.64.303h3.188L7.097 11.959H4.73L0 .303h3.188zm16.683-4.525c0 .93-.74 1.576-1.604 1.576-.864 0-1.605-.646-1.605-1.576 0-.929.74-1.575 1.605-1.575.864 0 1.605.646 1.605 1.575zm.494 4.122c-1.07 1.373-2.201 2.323-4.197 2.323-2.036 0-3.62-1.212-4.854-2.99-.494-.727-1.255-.889-1.81-.505-.515.364-.638 1.131-.165 1.798 1.707 2.566 4.073 4.06 6.83 4.06 2.53 0 4.505-1.212 6.047-3.232.576-.747.555-1.515 0-1.94-.514-.404-1.275-.262-1.851.486zM30.2 6.1c0 2.384 1.399 3.637 2.962 3.637 1.481 0 3.004-1.172 3.004-3.637 0-2.425-1.523-3.596-2.983-3.596-1.584 0-2.983 1.111-2.983 3.596zm0-4.182V.323h-2.9V16h2.9v-5.576c.967 1.293 2.222 1.839 3.64 1.839 2.655 0 5.247-2.06 5.247-6.303 0-4.061-2.695-5.96-5-5.96-1.83 0-3.085.828-3.887 1.92zm13.927 4.182c0 2.384 1.4 3.637 2.963 3.637 1.48 0 3.003-1.172 3.003-3.637 0-2.425-1.522-3.596-2.983-3.596-1.584 0-2.983 1.111-2.983 3.596zm0-4.182V.323h-2.9V16h2.9v-5.576c.967 1.293 2.222 1.839 3.641 1.839 2.654 0 5.246-2.06 5.246-6.303 0-4.061-2.695-5.96-4.999-5.96-1.83 0-3.085.828-3.888 1.92z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default VippsLogo
