import React from 'react'

function DiveInLogoSmall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={106.75}
      height={35.813}
      viewBox="0 0 106.75 35.813"
    >
      <defs>
        <clipPath id="prefix__a-sm">
          <path
            transform="translate(34.25 185.3)"
            fill="none"
            d="M0 0h106.75v35.813H0z"
          />
        </clipPath>
        <style>
          {'.prefix__c-sm{fill:#00bbb4}.prefix__d-sm{fill:#4e4d4e}'}
        </style>
      </defs>
      <g transform="translate(-34.25 -185.3)" clipPath="url(#prefix__a-sm)">
        <path
          className="prefix__c-sm"
          d="M50.12 185.3a.214.214 0 01.209.214v16.717c0 1.29 0 2.6.024 3.888a.219.219 0 01-.209.224h-4.121a.219.219 0 01-.209-.2c0-.257-.043-.566-.057-.876a.209.209 0 00-.371-.128c-.742.9-1.818 1.594-4.307 1.594-3.9 0-6.829-3.007-6.829-7.614a7.508 7.508 0 017.252-7.837 5.722 5.722 0 013.888 1.1.209.209 0 00.338-.171v-5.61a1.28 1.28 0 011.256-1.3zm-11.168 13.743c0 2.284 1.261 3.988 3.488 3.988s3.4-1.532 3.4-4-.952-4.035-3.388-4.035c-2.379 0-3.5 1.937-3.5 4.05M55.083 185.3h4.161a.214.214 0 01.209.214v3.293a.214.214 0 01-.209.214h-4.159a.214.214 0 01-.209-.214v-3.293a.214.214 0 01.209-.214m0 6.405h4.159a.214.214 0 01.209.219v14.2a.214.214 0 01-.209.219h-4.159a.214.214 0 01-.209-.219v-14.2a.214.214 0 01.209-.219M66.328 191.705a.219.219 0 01.2.148c1.937 5.71 2.993 8.566 3.483 10.379h.052c.519-1.851 1.48-4.673 3.45-10.379a.214.214 0 01.2-.147h4.192a.214.214 0 01.2.3l-5.587 14.2a.209.209 0 01-.19.138H67.47a.2.2 0 01-.19-.138l-5.587-14.2a.219.219 0 01.2-.3zM83.109 200.185a.219.219 0 00-.209.247 3.131 3.131 0 003.331 2.779 2.979 2.979 0 002.855-1.266.209.209 0 01.176-.1h4.159a.214.214 0 01.2.3c-.914 2.494-3.046 4.6-7.414 4.6-5.81 0-7.871-3.983-7.871-7.695 0-4.1 2.651-7.733 7.842-7.733 5.482 0 7.714 3.912 7.714 7.742v.918a.214.214 0 01-.209.214zm5.905-2.9a.214.214 0 00.2-.247 2.707 2.707 0 00-3.055-2.413c-2.156 0-3 1.133-3.2 2.4a.219.219 0 00.209.257z"
        />
        <path
          className="prefix__d-sm"
          d="M101.065 185.3h3.112a1.275 1.275 0 011.251 1.3v2.2a.209.209 0 01-.209.214h-4.154a.214.214 0 01-.209-.214v-3.293a.214.214 0 01.209-.214m0 6.405h4.154a.214.214 0 01.209.219v14.2a.214.214 0 01-.209.219h-4.154a.214.214 0 01-.209-.219v-14.2a.214.214 0 01.209-.219M109.512 195.398c0-1.152 0-2.451-.024-3.469a.209.209 0 01.209-.224h4.073a.209.209 0 01.2.2c.029.286.057.676.081 1.052a.209.209 0 00.366.133 5.2 5.2 0 014.283-1.775c3.479 0 5.525 2.241 5.525 6v8.818a.214.214 0 01-.209.219h-4.207a.214.214 0 01-.209-.219v-8.073c0-1.627-.609-3.069-2.565-3.069s-2.95 1.428-2.95 4.026v7.1a.214.214 0 01-.209.219h-4.178a.214.214 0 01-.209-.219z"
        />
        <path
          className="prefix__c-sm"
          d="M130.407 197.287c-.533-3.388 1.551-6.253 5.3-6.186 3.079.048 4.878 2.66 5.087 6.1s-2.617 6.524-5.829 7.709a19.538 19.538 0 01-7.171 1.342s3.783-1.542 2.617-8.951"
        />
        <path
          className="prefix__d-sm"
          d="M58.076 221.117a3.288 3.288 0 01-2.379-.885c-.885-.833-.895-1.775-.895-3.374s0-2.536.895-3.374a3.283 3.283 0 012.379-.88 3.074 3.074 0 013.245 2.646h-1.765a1.428 1.428 0 00-1.48-1.19 1.466 1.466 0 00-1.109.438c-.324.352-.433.761-.433 2.379s.109 2.013.433 2.379a1.461 1.461 0 001.109.433 1.428 1.428 0 001.489-1.185h1.757a3.079 3.079 0 01-3.245 2.646M70.001 221.027l-.523-1.48h-3.15l-.533 1.48h-1.8l3.217-8.37h1.356l3.231 8.37zm-2.065-5.9l-1.133 3.046h2.2zM79.871 221.032l-1.727-3.331h-1.242v3.331h-1.728v-8.36h3.469a2.6 2.6 0 012.855 2.56 2.265 2.265 0 01-1.613 2.2l1.975 3.612zm-1.342-6.914h-1.627v2.213h1.627a1.114 1.114 0 100-2.213M93.129 221.042h-1.442l-1.627-5.082-1.632 5.082h-1.443l-2.36-8.375h1.8l1.366 5.282 1.618-5.282h1.29l1.618 5.282 1.38-5.282h1.8zM103.673 221.027l-.523-1.48h-3.142l-.533 1.48h-1.8l3.222-8.37h1.351l3.236 8.37zm-2.065-5.9l-1.118 3.046h2.2zM111.282 221.113a4.277 4.277 0 01-3.231-1.1l1.118-1.061a3 3 0 002.127.7c1.033 0 1.58-.376 1.58-1.047a.909.909 0 00-.276-.69 1.313 1.313 0 00-.795-.286l-1.056-.152a3.031 3.031 0 01-1.718-.69 2.132 2.132 0 01-.633-1.647c0-1.494 1.171-2.541 3.084-2.541a3.921 3.921 0 012.855.952l-1.094 1.023a2.551 2.551 0 00-1.818-.576c-.918 0-1.351.476-1.351 1.056a.757.757 0 00.247.6 1.485 1.485 0 00.809.319l1.056.152a2.879 2.879 0 011.689.657 2.227 2.227 0 01.685 1.765c0 1.623-1.428 2.541-3.283 2.541M122.717 221.042v-3.507h-2.993v3.507h-1.732v-8.375h1.732v3.421h2.993v-3.421h1.727v8.375z"
        />
      </g>
    </svg>
  )
}

export default DiveInLogoSmall
