import React from "react";
import { Payment } from "../../stores/subscriptionStore";
import Logo from "../../assets/receipt-logo.png";

export const htmlFromPayment = (p: Payment, userName: string) => {
  const html = (
    <div
      id="receipt"
      style={{
        margin: "0 auto",
        maxWidth: "500px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px 0",
          minHeight: "85px",
          backgroundColor: "#F2F4F6",
        }}
      >
        <a href="https://divein.no" style={{ width: "auto" }}>
          <img
            src={Logo}
            alt=""
            style={{
              width: "100%",
              maxWidth: "215px",
              minWidth: "125px",
              marginLeft: "auto",
            }}
          />
        </a>
      </div>
      <div
        style={{
          margin: "0 auto",
          maxWidth: "500px",
          padding: "20px 10px",
        }}
      >
        <p
          style={{
            color: "#747E78",
            fontSize: "18px",
            fontFamily: "Arial",
          }}
        >
          <strong>Elektronisk Salgskvittering</strong>
        </p>
        <p
          style={{
            color: "#747E78",
            fontSize: "18px",
            fontFamily: "Arial",
          }}
        >
          {p.paid.toDate().toLocaleString()}
        </p>
        <p
          style={{
            color: "#747E78",
            fontSize: "18px",
            fontFamily: "Arial",
          }}
        >
          Dive In AS (920 512 852MVA)
        </p>
        <p
          style={{
            color: "#747E78",
            fontSize: "18px",
            fontFamily: "Arial",
          }}
        >
          Ordre: {p.id}
        </p>
        <p
          style={{
            color: "#747E78",
            fontSize: "18px",
            fontFamily: "Arial",
          }}
        >
          Kjøper: {userName}
        </p>
        <p
          style={{
            color: "#747E78",
            fontSize: "18px",
            fontFamily: "Arial",
          }}
        >
          Lokasjon: {"Tiller"}{" "}
        </p>
        <br />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            borderBottom: "1px solid #c1c1",
          }}
        >
          <p style={{ gridColumn: 1, color: "#747E78" }}>
            <span
              style={{ fontSize: "14px", fontWeight: 600, color: "#9BA2AA" }}
            >
              Artikkel
            </span>
          </p>
          <p style={{ gridColumn: 3, color: "#747E78" }}>
            <span
              style={{ fontSize: "14px", fontWeight: 600, color: "#9BA2AA" }}
            >
              Pris
            </span>
          </p>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          <p
            style={{
              gridColumn: 1,
              fontSize: "14px !important",
              margin: "8px 0",
              color: "#585e5a",
            }}
          >
            {p.forProductName} {p.hasOptions && "/m tillegg"}
          </p>
          <p
            style={{
              gridColumn: 3,
              fontSize: "14px !important",
              margin: "8px 0",
              color: "#585e5a",
            }}
          >
            kr {p.amount.toFixed(2)}
          </p>
          <p></p>
          {p.discount && (
            <>
              <p
                style={{
                  fontSize: "14px !important",
                  margin: "8px 0",
                  color: "#585e5a",
                }}
              >
                Rabatt: {p.discount.toFixed(2)}
              </p>
              <p
                style={{
                  gridColumn: 3,
                  fontSize: "14px !important",
                  margin: "8px 0",
                  color: "#585e5a",
                }}
              >
                -{p.discount.toFixed(2)},-
              </p>
            </>
          )}
        </div>
        <div style={{ borderBottom: "1px solid #c1c1" }}></div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          <p
            style={{ fontSize: "16px", color: "#747E78", fontFamily: "Arial" }}
          >
            Totalt:
          </p>
          <p
            style={{
              gridColumn: 3,
              fontSize: "16px",
              color: "#747E78",
              fontFamily: "Arial",
            }}
          >
            {" "}
            {p.amount.toFixed(2)},-
          </p>
        </div>

        <div
          style={{
            marginTop: "15px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
          }}
        >
          <p style={{ fontWeight: 600, color: "#747E78", fontSize: "14px" }}>
            MVA-grunnlag
          </p>
          <p style={{ fontWeight: 600, color: "#747E78", fontSize: "14px" }}>
            MVA-%
          </p>
          <p style={{ fontWeight: 600, color: "#747E78", fontSize: "14px" }}>
            MVA
          </p>
          <p style={{ fontWeight: 600, color: "#747E78", fontSize: "14px" }}>
            Sum
          </p>

          <p style={{ fontWeight: 600, color: "#747E78", fontSize: "14px" }}>
            {(p.amount / 1.25).toFixed(2)}
          </p>
          <p style={{ fontWeight: 600, color: "#747E78", fontSize: "14px" }}>
            25%
          </p>
          <p style={{ fontWeight: 600, color: "#747E78", fontSize: "14px" }}>
            {(p.amount - p.amount / 1.25).toFixed(2)}
          </p>
          <p style={{ fontWeight: 600, color: "#747E78", fontSize: "14px" }}>
            {p.amount.toFixed(2)}
          </p>
        </div>
        <br />
        <p style={{ fontWeight: 600, color: "#747E78" }}>
          Betalingsmåte: Vipps (transaksjons-ID: {p.externalRef})
        </p>
        <p style={{ fontWeight: 600, color: "#747E78" }}>På gjensyn!</p>
        <br />
        <p style={{ fontWeight: 600, color: "#747E78", fontSize: "18px" }}>
          Med vennlig hilsen, <br /> Dive In Carwash
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 0",
          minHeight: "55px",
          backgroundColor: "#F2F4F6",
        }}
      >
        <p style={{ fontSize: "12px" }}>© 2022 Dive In Carwash</p>
      </div>
    </div>
  );
  return html;
};
