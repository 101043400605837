
import { createState, useState } from '@hookstate/core'
import { Timestamp } from 'firebase/firestore'

export enum SubscriptionStatus {
    Pending,
    Active,
    Inactive
}

export type Subscription = {
    id: string
    cancelled: boolean
    cancelledAt: Timestamp
    paused: boolean
    created: Timestamp
    updated: Timestamp
    status: SubscriptionStatus
    boughtAt: string
    washesThisPeriod: number
    licenseplate: string
    licenseplateUpdatedAt: Timestamp
    lastDue: Timestamp
    nextDue: Timestamp
    nextDueId: string
    productId: string
    productName: string
    programId: string
    periodAmount: number
    period: number
    toBeDowngraded: boolean
    downgradeTo: string
    payments: Payment[] | null
    pendingCharges: PendingCharge[] | null
}

type PendingCharge = {
    chargeId: string
    due: Timestamp
    typeOf: ChargeKind
}

enum ChargeKind {
    PeriodCharge = 1,
    UpgradeCharge
}



export enum PaymentKind {
    Washgate = 1,
    SelfService,
    Matwash,
    Blowdry,
    Valuecard,
    SubPeriod,
    SubUpgrade,
    SubUpgradeOnce,
    SubUpgradeOptions
}

export enum PaymentStatus {
    Pending,
    Paid,
    Failed,
    Refunded,
    Cancelled,
    Rejected
}

export type Payment = {
    id: string
    amount: number
    boughtAt: string
    discount: number
    discountCode: string
    discountRef: string
    externalRef: string
    status: PaymentStatus
    paid: Timestamp
    typeOf: PaymentKind
    due: Timestamp
    paidWithValuecard: boolean
    forCategoryId: string
    forProductName: string
    forProductId: string
    forStationId: string
    hasOptions: boolean
    options: unknown[]
    quantity: number
    orderRef: string
    isForToken: boolean
    tokenRef: string
    created: Timestamp
    updated: Timestamp
}
interface SubscriptionState {
    subscriptions: Subscription[] | null
}

const SubscriptionStore = createState<SubscriptionState>({
    subscriptions: []
})

export const useSubscriptionStore = () => {
    return useState(SubscriptionStore)
}

export default SubscriptionStore 
