import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import VippsButton from "../assets/pay_with_vipps.png";
import DiveInLogoSmall from "../components/DiveInLogo/DiveInLogoSmall";
import Button from "../components/Button";
import { getCoupons, getTokens, removeToken } from "../stores/tokenStore";
import { BuyMatwashRequest, ChargeOption, divein } from "../utils/api";
import {
  CorporateClientData,
  CouponTokenData,
  Token,
  TokenType,
} from "../types";
import { getMatwashers } from "../stores/locationStore";
import { toast } from "react-toastify";
import { isLoggedIn } from "../utils/Firebase";
import { LoginToast } from "../components/LoginToast";
import UserStore from "../stores/userStore";
import { useState as useSpecialState } from "@hookstate/core";
import { useLocation } from "react-router-dom";
import VippsLoginButton from "../utils/VippsLoginButton/VippsLoginButton";
import Cookies from "js-cookie";
import { LoginUrl } from "../utils/urls";
import BubblesBG from "../assets/webp/Blue-Water-Drops.webp";

const Mattevask = () => {
  const [selected, setSelected] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [matwashers, setMatwashers] = useState<string[]>();

  const userStore = useSpecialState(UserStore);
  const location = useLocation();

  const productID = "10";

  const [corpToken, setCorpToken] = useState<Token[]>();
  const [couponTok, setCouponTok] = useState<Token>();
  const [couponTokData, setCouponTokData] = useState<CouponTokenData>();

  const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setSelected(e.currentTarget.value);
  };

  const initiatePurchase = async (charge: ChargeOption, tokId?: string) => {
    if (loading) return;
    if (!isLoggedIn()) {
      toast.error(<LoginToast />, { autoClose: false, icon: false });
      return;
    }
    if (!selected) {
      toast.error("Velg mattevasker");
      return;
    }
    setLoading(true);
    const buyRequest: BuyMatwashRequest = {
      siteId: "1",
      stationId: selected,
      productId: productID,
    };
    try {
      switch (charge) {
        case ChargeOption.vipps: {
          const { status, responseData } = await divein.buyMatwash(buyRequest);
          if (status < 300) {
            window.location.href = responseData.url;
          }
          toast.info(responseData);
          setLoading(false);
          break;
        }
        case ChargeOption.corp: {
          buyRequest.corpTokenId = tokId;
          const { status, responseData } = await divein.buyMatwash(buyRequest);
          if (status < 300) {
            toast.success("Mattevasker startet");
          }
          toast.info(responseData);
          setLoading(false);
          break;
        }
        case ChargeOption.coupon: {
          buyRequest.couponTokenId = couponTok.id;
          const { status, responseData } = await divein.buyMatwash(buyRequest);
          if (status < 300) {
            toast.success("Mattevasker startet");
            if (couponTok.maxUses >= couponTok.uses + 1) {
              removeToken(couponTok.id);
              setCouponTok(undefined);
              setCouponTokData(undefined);
            }
          }
          toast.info(responseData);
          setLoading(false);
          break;
        }
      }
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  useEffect(() => {
    const corpToken = getTokens(TokenType.corporateClient);
    setCorpToken(corpToken);
    const coupons = getCoupons();
    for (const coupon of coupons) {
      console.log(coupon);
      const data = coupon.data as unknown as CouponTokenData;
      if (data.appliesToProduct === productID || data.freeMatwash) {
        setCouponTok(coupon);
        setCouponTokData(data);
        console.log(data);
      }
    }
    setMatwashers(getMatwashers());
  }, []);

  return (
    <>
      <Header text="Mattevask" backLink="/" />
      <div className="w-screen flex flex-col items-center relative overflow-hidden">
        <div className="inner-container">
          <p className="text-xl medium px-6">
            Mattevaskeren finner du i støvsugerhallen. Her kan man spyle rent
            gummimatter med skum og høyttrykk.{" "}
          </p>
          <p className="big-text med-spacing medium dark">Velg mattevasker</p>
          <form className="radio-mattevask">
            {matwashers?.map((val, i) => (
              <React.Fragment key={i}>
                <input
                  type="radio"
                  onChange={handleChange}
                  value={val}
                  id={val}
                  checked={selected === val}
                  name="selvvask"
                />
                <label htmlFor={val}>{val}</label>
              </React.Fragment>
            ))}
          </form>
          <div className="big bg-primary-400 flex justify-around mb-8 py-6 mt-2 items-center">
            <p className="text-accent text-5xl bold my-1">25,-</p>
            <p className="text-white text-2xl semibold">2 minutter</p>
          </div>
          <p style={{ margin: 0 }}>
            <strong>Slik gjør du:</strong>
          </p>
          <ol>
            <li>Heng opp mattene</li>
            <li>
              Betal - <em>Mattevaskeren starter umiddelbart ved betaling</em>
            </li>
            <li>Start med skumpistol til venstre i 30 sekunder.</li>
            <li>Spyl av med høyttrykspistol til høyre</li>
          </ol>
          {couponTokData &&
            (couponTokData.discount === 1 || couponTokData.freeMatwash) && (
              <Button
                text="Benytt gratis mattevask"
                onClick={() => {
                  initiatePurchase(ChargeOption.coupon);
                }}
              />
            )}
          {userStore.user.get() ? (
            <div
              className="vipps"
              onClick={() => initiatePurchase(ChargeOption.vipps)}
            >
              <img src={VippsButton} alt="Betal med Vipps" />
            </div>
          ) : (
            <VippsLoginButton
              onClick={() => {
                const inFiveMinutes = new Date(
                  new Date().getTime() + 2 * 60 * 1000
                );
                Cookies.set("divein/redirect", location.pathname, {
                  domain: "divein.no",
                  expires: inFiveMinutes,
                });
                window.location.href = LoginUrl;
              }}
              classes="w-5/6 my-5"
            />
          )}

          {corpToken && (
            <>
              {corpToken.map((t) => {
                return (
                  <Button
                    key={t.id}
                    text={`Belast ${
                      (t.data as unknown as CorporateClientData).companyName
                    }`}
                    onClick={() => {
                      initiatePurchase(ChargeOption.corp, t.id);
                    }}
                  />
                );
              })}
            </>
          )}
          <span className="column-end divein-logo-small">
            <DiveInLogoSmall />
          </span>
        </div>
        <img
          src={BubblesBG}
          alt="bobler bakgrunn"
          className="absolute -top-24 w-11/12 rotate90 opacity-70 right-10 -z-10"
        />
      </div>
    </>
  );
};

export default Mattevask;
