import React from "react";
import InfoIconPrimary from "../utils/Icons/InfoIconPrimary";

interface Props {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  height?: number;
}

const InfoIcon = ({ onClick, height = 33 }: Props) => {
  return (
    <div className="iconBox" onClick={onClick}>
      <InfoIconPrimary height={height} />
    </div>
  );
};

export default InfoIcon;
