import React from 'react'

function CheckMark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13.099}
      height={12.324}
      viewBox="0 0 13.099 12.324"
    >
      <path
        d="M1.391 6.839l3.094 4.485 7.218-9.927"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  )
}

export default CheckMark
