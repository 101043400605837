import isNumeric from 'validator/lib/isNumeric'
import isPostalCode from 'validator/lib/isPostalCode'
import isEmail from 'validator/lib/isEmail'

export const ValidateMe = (target: HTMLInputElement, type?: string) => {
  const list = target.classList
  switch (type) {
    case 'CoOrgNum':
      isNumeric(target.value) && target.value.length === 9
        ? list.remove('invalid')
        : list.add('invalid')
      break
    case 'ZipCode':
      !isPostalCode(target.value, 'NO')
        ? list.add('invalid')
        : list.remove('invalid')
      break
    case 'email':
      !isEmail(target.value) ? list.add('invalid') : list.remove('invalid')
      break
    case 'CoCellphone':
      !isNumeric(target.value) || target.value.length !== 8
        ? list.add('invalid')
        : list.remove('invalid')
      break
    default:
      target.value.length === 0 ? list.add('invalid') : list.remove('invalid')
  }
}
