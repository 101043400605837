import React from "react";
import Styles from "./ProgramComponent.module.css";

import CheckMark from "../../utils/Icons/CheckMark";
import CheckMarkBlack from "../../utils/Icons/CheckMarkBlack";
import CrossIcon from "../../utils/Icons/CrossIcon";
import FastForwardIcon from "../../utils/Icons/FastFordwardIcon";

import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProgramStore from "../../stores/programStore";
import { useState as useSpecialState } from "@hookstate/core";

export interface ProgramType {
  name: string;
  id: string;
  productId: string;
  includesText: Array<string>;
  excludesText: Array<string>;
  perkTextIncludes: Array<string>;
  perkTextExcludes: Array<string>;
  singlePrice: number;
  subscriptionPrice: number;
}

interface ProgramProps {
  current: ProgramType;
  key?: number;
}

const ProgramComponent = ({ current }: ProgramProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const programStore = useSpecialState(ProgramStore);
  const navigate = useNavigate();

  const goToSingle = () => {
    programStore.selectedProgram.set(current.name);
    navigate(`/vaskegate/singelvask/${current.name}`);
  };

  const goToSubscription = () => {
    programStore.selectedSubscription.set(current.name);
    navigate(`/vaskegate/abonnement/${current.name}`);
  };

  useEffect(() => {
    if (current) {
      setIsLoading(false);
    }
  }, [current]);

  return (
    <Fragment key={current.id}>
      {isLoading ? (
        <p>...</p>
      ) : (
        <div
          className={
            current.name.toLowerCase() === "ultimate"
              ? Styles.programContainer
              : [Styles.programContainer, Styles.blandBorder].join(" ")
          }
        >
          <div
            className={
              current.name.toLowerCase() === "ultimate"
                ? Styles.programHalfcircleTop
                : [Styles.programHalfcircleTop, Styles.blandHalfcircleTop].join(
                    " "
                  )
            }
          >
            <p>{current.id}</p>
          </div>
          <p className={Styles.programHeader}>{current.name.toUpperCase()}</p>
          <div className={Styles.programInfoText}>
            {current.excludesText.map((text) => {
              return (
                <p key={text} className={Styles.notIncluded}>
                  {text}
                </p>
              );
            })}
            {current.includesText.map((text) => {
              return <p key={text}>{text}</p>;
            })}
          </div>

          <div
            className={
              current.name === "Børsteløs"
                ? Styles.greyBanner
                : Styles.programBanner
            }
            onClick={goToSubscription}
          >
            <div className={Styles.programBannerLeft}>
              <p className={Styles.programBannerHeader}>Abonnement</p>
              {current.perkTextIncludes.map((text) => {
                return (
                  <p key={text}>
                    <span>
                      <CheckMark /> {text}
                    </span>
                  </p>
                );
              })}
              {current.perkTextExcludes?.length > 0 &&
                current.perkTextExcludes.map((text) => {
                  return (
                    <p key={text}>
                      <span className="not-included">
                        <CrossIcon /> {text}
                      </span>
                    </p>
                  );
                })}

              <div className={Styles.programButton}>
                <p>
                  KJØP HER <FastForwardIcon />
                </p>
              </div>
            </div>
            <div className={Styles.programBannerRight}>
              <div className={Styles.programBannerRightCircle}>
                <>
                  <p>
                    {current.subscriptionPrice}
                    ,-
                  </p>

                  <p>pr. mnd</p>
                </>
              </div>
            </div>
          </div>
          <div className={Styles.programBottom} onClick={() => goToSingle()}>
            <>
              <div className={Styles.programBottomLeft}>
                <p className={Styles.programBottomLeftHeader}>Enkeltvask</p>

                <p style={{ margin: 0 }} className="small-text">
                  <span>
                    <CheckMarkBlack /> Innendørs støvsuger
                  </span>
                </p>
                <div className={Styles.programButton}>
                  <p>
                    KJØP HER <FastForwardIcon />
                  </p>
                </div>
              </div>
              <div className={Styles.programBottomRight}>
                <div className={Styles.programBottomRightCircle}>
                  <p>{current.singlePrice},-</p>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ProgramComponent;
