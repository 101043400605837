import { createState, useState } from "@hookstate/core";
import {Site} from "../types"

const LocationStore = createState<Site>({} as Site)

export const useLocationStore = () => {
  return useState(LocationStore)
}

export const getMatwashers = () => {
  return LocationStore.get()?.matwasherIds
}
