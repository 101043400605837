import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import Header from "../components/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { Token, TokenStatus, TokenType, WashTokenData } from "../types";
import { b64Decode } from "../utils/helper";
import { User } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../utils/Firebase";
import GreenCheckmark from "../assets/lottie/green_checkmark.json";
import Droplet from "../assets/lottie/droplet-loop.json";

const QRCodeViewer = ({ user }: { user: User | undefined }) => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [tokenState, setTokenState] = useState<Token>();
  const [tokenId, setTokenId] = useState("blablabla");
  const [tokenData, setTokenData] = useState<WashTokenData>();
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !id) {
      navigate("/");
    }
    setLoading(true);

    const q = query(
      collection(db, "customers", user.uid, "tokens"),
      where("id", "==", id)
    );
    let hasToken = false;
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (snapshot.empty) {
        setLoading(false);
        return;
      }
      const t = snapshot.docs[0];
      const tok = t.data() as Token;
      console.log(
        `has token: ${tokenState ? "true" : "false"}, incoming status: ${
          tok.status
        }`
      );
      if (!hasToken && tok.status === TokenStatus.active) {
        setTokenState(tok);
        hasToken = true;
        const decodedTokenData = JSON.parse(b64Decode(tok.data));
        setTokenData(decodedTokenData);
        setTokenId(tok.id);
      }
      if (hasToken && tok.status === TokenStatus.consumed) {
        console.log("true and consumed");
        setSuccess(true);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [id]);

  useEffect(() => {
    let timeoutId;
    if (success) {
      timeoutId = setTimeout(() => {
        navigate("/");
      }, 5000);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [success]);

  return (
    <>
      <Header text="QR-kode" backLink="/" />
      {loading ? (
        <Lottie animationData={Droplet} loop={true} />
      ) : success ? (
        <div className="container">
          <div className="column-center">
            <Lottie
              animationData={GreenCheckmark}
              loop={false}
              style={{ height: "100px", marginTop: "80px" }}
            />
            <p className="text-2xl px-12">
              Kjør forhjulene opp på båndet og sett bilen i fri (N).
            </p>
          </div>
        </div>
      ) : tokenState ? (
        <>
          <div className="container">
            <div className="column-center">
              <p className="text-xl my-3">
                {tokenState.kind === TokenType.washgate
                  ? `${tokenData.productName} bilvask`
                  : tokenState.kind === TokenType.blowdry
                  ? `${tokenData.productName}`
                  : tokenState.kind === TokenType.vacuumHall
                  ? "Tilgang til støvsugerhall"
                  : ""}
              </p>
              <p className="text-lg px-12">
                Kjør bilen foran porten til vaskegata, Scan QR-koden,
                <br /> kjør forhjulene opp på båndet og sett bilen i fri (N).
              </p>
              <div className="">
                <QRCodeCanvas
                  value={tokenId}
                  size={180}
                  level={"L"}
                  includeMargin={true}
                  style={{
                    border: "3px solid black",
                  }}
                />
              </div>
              <p className="mt-2 text-slate-800 text-sm">
                Utløper {tokenState.expiresAt.toDate().toLocaleString()}
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container">
            <div className="flex justify-center">
              <h3>Her finnes ingen aktiv QR-kode</h3>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default QRCodeViewer;
