import React, { useEffect, useState } from "react";
import DiveInLogoSmall from "../../../components/DiveInLogo/DiveInLogoSmall";
import Header from "../../../components/Header/Header";
import { Payment, PaymentStatus } from "../../../stores/subscriptionStore";
import {
  getCustomerPayments,
  getAllCustomerPayments,
} from "../../../utils/Firebase";
import { htmlFromPayment } from "../../../utils/Receipt/receipt";
import html2pdf from "html2pdf.js";
import { PaymentItem } from "./Payment";
import { useState as useSpecialState } from "@hookstate/core";
import UserStore from "../../../stores/userStore";

export const PaymentsHistory = ({ uid }: { uid: string }) => {
  const userStore = useSpecialState(UserStore);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [receiptElement, setReceiptElement] = useState<JSX.Element>();
  const [paymentId, setPaymentId] = useState("");
  const [viewingPayment, setViewingPayment] = useState(false);
  const [fetchedAll, setFetchedAll] = useState(false);

  const getMore = async () => {
    if (loadingMore) return;
    setLoadingMore(true);
    const { pays } = await getAllCustomerPayments(uid);
    if (pays.length === 0) {
      setLoadingMore(false);
      return;
    }
    setPayments(pays);
    setLoadingMore(false);
    setFetchedAll(true);
  };
  const makeReceiptHTML = async (p: Payment) => {
    const html = htmlFromPayment(
      p,
      `${userStore.user?.firstName.get()} ${userStore.user?.surname.get()}`
    );
    setReceiptElement(html);
    setPaymentId(p.id);
  };
  const saveAsPDF = () => {
    if (!receiptElement) return;
    const element = document.getElementById("receipt");
    html2pdf(element, {
      filename: `kvittering-${paymentId}.pdf`,
    });
  };

  useEffect(() => {
    (async () => {
      const { pays } = await getCustomerPayments(uid);
      setPayments(pays);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    console.log(payments);
  }, [payments]);

  return (
    <>
      {viewingPayment && receiptElement ? (
        <>
          <div className="flex justify-center items-center">
            <svg
              className="absolute left-5"
              xmlns="http://www.w3.org/2000/svg"
              width={24.477}
              height={24.477}
              viewBox="0 0 20.477 20.477"
              onClick={() => setViewingPayment(false)}
            >
              <path
                d="M10.239 20.477l1.8-1.8-7.141-7.154h15.579v-2.56H4.902l7.141-7.154-1.8-1.8L0 10.239z"
                fill="#00bbb4"
              />
            </svg>
            <button
              className="py-2 px-4 text-lg my-4 bg-primary-400 text-white self-center border-none shadow-md"
              onClick={() => saveAsPDF()}
            >
              Last ned som PDF
            </button>
          </div>
          {receiptElement}
        </>
      ) : (
        <>
          <Header text="Betalinger" backLink="/profil" />
          <div className="container min-h-full">
            <h2>Siste kjøp</h2>
            {loading && <p>Laster...</p>}
            <div className="w-11/12 grid grid-cols-4">
              <p className="place-self-center semibold">Dato</p>
              <p className="place-self-center semibold">Beløp</p>
              <p className="place-self-center semibold">Produkt</p>
              <p className="place-self-center semibold">Status</p>
            </div>
            {!loading &&
              payments.map((p) => (
                <PaymentItem
                  key={p.id}
                  payment={p}
                  onClick={() => {
                    if (p.status === PaymentStatus.Paid) {
                      makeReceiptHTML(p);
                      setViewingPayment(true);
                    }
                  }}
                />
              ))}
            {!loading && payments.length === 0 && <p>Ingen betalinger</p>}
            {!fetchedAll && (
              <div
                className="bg-primary-400 px-4 mt-4"
                style={{ border: "1px solid rgba(0,0,0,0.1)" }}
                onClick={() => getMore()}
              >
                <p className="text-white text-lg semibold">
                  {loadingMore ? "Laster..." : "Se alle betalinger"}
                </p>
              </div>
            )}
            <div className="divein-logo-small">
              <DiveInLogoSmall />
            </div>
          </div>
        </>
      )}
    </>
  );
};
