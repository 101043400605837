import React from 'react'
import Header from '../../components/Header/Header'

const InfoAsfalt = () => {
  return (
    <>
      <Header text="Informasjon" backLink="back"/>
      <div className="container">
        <div className="inner">
          <h3
            style={{
              textAlign: 'center',
              letterSpacing: 0.8,
            }}
          >
            Asfaltfjerner
          </h3>
          <p>Asfaltfjerner er også kjent som avfetting.</p>
          <p>
            Fjerner asfaltflekker, tjæreflekker, og diesel søl på bilens lakk.
          </p>
          <p>
            Asfaltfjerner kan være nødvendig for å få ren bil spesielt på
            vinteren, når bilen er svært skitten eller det er lenge siden den er
            vasket.
          </p>
          <p>
            Spar penger og miljøet! Unngå å kjøpe dette hvis du vasker jevnlig,
            og bilen ikke har synlige asfaltflekker.
          </p>
          <p>
            Asfaltfjerner har <em>ingen </em>
            vaskeeffekt mot:
          </p>
          <ul>
            <li style={{ display: 'list-item' }}>Insekter</li>
            <li style={{ display: 'list-item' }}>Kvae fra trær</li>
            <li style={{ display: 'list-item' }}>
              Grå trafikkfilm ved kjøp av børsteløs bilvask
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default InfoAsfalt
