import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { useState as useSpecialState } from "@hookstate/core";
import ForwardArrowPrimary from "../utils/Icons/ForwardArrowPrimary";
import VippsButton from "../assets/pay_with_vipps.png";
import Button from "../components/Button";
import { calculateNewTotal } from "../utils/helper";

import {
  getAndSetCorpToken,
  getCoupons,
  getTokens,
  removeToken,
} from "../stores/tokenStore";
import ProgramStore from "../stores/programStore";

import Header from "../components/Header/Header";
import InfoIcon from "../components/InfoIcon";
import {
  BuyCarwashRequest,
  ChargeOption,
  divein,
  InitResponse,
} from "../utils/api";
import { toast } from "react-toastify";
import {
  CorporateClientData,
  CouponTokenData,
  ProductCategory,
  Token,
  TokenType,
} from "../types";
import { isLoggedIn } from "../utils/Firebase";
import { LoginToast } from "../components/LoginToast";
import UserStore from "../stores/userStore";
import VippsLoginButton from "../utils/VippsLoginButton/VippsLoginButton";
import Cookies from "js-cookie";
import { LoginUrl } from "../utils/urls";

const Singelvask = () => {
  const navigate = useNavigate();
  const { program } = useParams<{ program?: string }>();
  const [err, setErr] = useState(false);

  const programStore = useSpecialState(ProgramStore);
  const options = programStore.programOptions.get();

  const location = useLocation();
  const userStore = useSpecialState(UserStore);

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>();
  const [discountedTotal, setDiscountedTotal] = useState<number>();
  const [checkedState, setCheckedState] = useState<Array<boolean>>(
    new Array(options.length).fill(false)
  );

  const [couponTokenId, setCouponTokenId] = useState("");
  const [couponData, setCouponData] = useState<CouponTokenData>();
  const [matwashToken, setMatwashToken] = useState<Token>();
  const [corpToken, setCorpToken] = useState<Token[]>();
  const [, setCorpData] = useState<CorporateClientData[]>();

  const handleChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const correctIndex = programStore.programData
    ?.get()
    .findIndex((p) => p.name.toLowerCase() === program.toLowerCase());

  const currentProgram = programStore.programData.get()[correctIndex];

  const InitiatePurchase = async (charge: ChargeOption, tokId?: string) => {
    if (loading) return;
    if (!isLoggedIn()) {
      toast.error(<LoginToast />, { autoClose: false, icon: false });
      return;
    }
    setLoading(true);
    const opts = [];
    for (let i = 0; i < options.length; i++) {
      opts.push({
        id: i === 0 ? "1" : i === 1 ? "13" : "14",
        selected: checkedState[i],
      });
    }

    const body: BuyCarwashRequest = {
      programId: currentProgram.id,
      options: opts,
      location: "1",
    };

    if (charge === ChargeOption.corp) body.corpTokenId = tokId;
    if (couponData) body.couponId = couponTokenId;
    console.log(body);

    const { status, responseData } = await divein.buyCarwash(body);
    if (status > 299) {
      if (status === 400 && responseData.error === "expired coupon") {
        toast.error("Rabattkoden er utgått");
        setCouponTokenId("");
        setCouponData(undefined);
        removeToken(couponTokenId);
        setLoading(false);
        return;
      }
      setErr(true);
      setLoading(false);
      toast.error(responseData["error"]);
      return;
    }
    console.log("did it!");
    const resp = responseData as InitResponse;
    if (charge === ChargeOption.corp) {
      navigate("/qrkode/" + resp.tokenId);
    } else {
      setLoading(false);
      window.location.href = resp.url;
    }
  };

  useEffect(() => {
    programStore.selectedProgram.set(program);
    setTotal(currentProgram.singlePrice);
    window.scrollTo(0, 0);

    setMatwashToken(getTokens(TokenType.matwash)[0]);
    const cp = getTokens(TokenType.corporateClient);
    if (cp) {
      setCorpToken(cp);
    } else {
      getAndSetCorpToken(setCorpToken, setCorpData);
    }

    return () => programStore.selectedProgram.set(null);
  }, []);

  useEffect(() => {
    setTotal(
      calculateNewTotal(currentProgram.singlePrice, checkedState, options)
    );
    setDiscountedTotal(
      calculateNewTotal(
        currentProgram.singlePrice,
        checkedState,
        options,
        couponData
      )
    );
  }, [checkedState, couponData]);

  useEffect(() => {
    setTimeout(() => {
      const toks = getCoupons();
      for (const tok of toks) {
        const data = tok.data as unknown as CouponTokenData;
        if (
          data.appliesToProduct === currentProgram.productId ||
          data.appliesToCategory === ProductCategory.Washgate
        ) {
          setCouponTokenId(tok.id);
          setCouponData(data);
          break;
        }
      }
    }, 500);
  }, []);
  return (
    <>
      <Header backLink="/vaskegate" />
      <div className="container ">
        <div className="selectionHeader">
          <span className="programHeader">{program.toUpperCase()}</span>
          <span className="primary price">{currentProgram.singlePrice},-</span>
        </div>
        {options.map(({ option, subText, price }, index) => {
          return (
            <React.Fragment key={index}>
              <div className="outer-checkbox">
                {option === "Asfaltfjerner" ? (
                  <InfoIcon onClick={() => navigate("/info/asfaltfjerner")} />
                ) : option === "Takboks" ? (
                  <InfoIcon onClick={() => navigate("/info/takboks")} />
                ) : (
                  <div className="iconBox"> </div>
                )}

                <label className="checkboxContainer">
                  {option === "Takboks" &&
                  program.toUpperCase() === "BØRSTELØS" ? null : (
                    <>
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        name={option}
                        value={option}
                        checked={checkedState[index]}
                        onChange={() => handleChange(index)}
                      />
                      <label className="svgLabel" htmlFor={`checkbox-${index}`}>
                        <span></span>
                      </label>
                    </>
                  )}
                  <label className="labelText">
                    <label htmlFor={`checkbox-${index}`} className="inputLabel">
                      {option}
                      {option === "Takboks" &&
                      program.toUpperCase() === "BØRSTELØS" ? (
                        <span className="notAvailable">
                          For øyeblikket ikke tilgjengelig
                        </span>
                      ) : null}
                    </label>
                    <label htmlFor={`checkbox-${index}`} className="subLabel">
                      {subText}
                    </label>
                  </label>
                  {option === "Takboks" &&
                  program.toUpperCase() === "BØRSTELØS" ? null : (
                    <p className="primary big-text med-spacing priceLabel">
                      {price === 0 ? price + ",-" : "+" + price + ",-"}
                    </p>
                  )}
                </label>
              </div>
            </React.Fragment>
          );
        })}
        <div className="price-text grid column-two big">
          {couponData && couponData.discount > 0 && (
            <>
              <p className="one medium">
                Rabatt {(couponData.discount * 100)?.toFixed(0)}%
              </p>
              <p className="two primary extrabold">
                -{(Number(total.toFixed(2)) * couponData.discount)?.toFixed(2)}
                ,-
              </p>
            </>
          )}

          <p className="one medium">Totalt</p>
          <p className="two primary extrabold">
            {discountedTotal?.toFixed(2)},-
          </p>
        </div>
        {matwashToken && (
          <Button
            text="Benytt gratis mattevask"
            onClick={() => {
              return;
            }}
          />
        )}
        {userStore.user.get() ? (
          <div
            className="vipps"
            onClick={() => InitiatePurchase(ChargeOption.vipps)}
          >
            <img src={VippsButton} alt="Betal med Vipps" />
          </div>
        ) : (
          <VippsLoginButton
            onClick={() => {
              const inFiveMinutes = new Date(
                new Date().getTime() + 2 * 60 * 1000
              );
              Cookies.set("divein/redirect", location.pathname, {
                domain: "divein.no",
                expires: inFiveMinutes,
              });
              window.location.href = LoginUrl;
            }}
            classes="w-4/6 mt-4"
          />
        )}
        {err && <p>Noe gikk galt. Prøv igjen senere</p>}
        {corpToken && (
          <>
            {corpToken.map((t) => {
              return (
                <Button
                  key={t.id}
                  text={`Belast ${
                    (t.data as unknown as CorporateClientData).companyName
                  }`}
                  onClick={() => InitiatePurchase(ChargeOption.corp, t.id)}
                />
              );
            })}
          </>
        )}
        {!corpToken && (
          <Link to="/firmakunde" className="firmakunde primary medium">
            <span>
              <p>Bli firmakunde</p>
            </span>
            <ForwardArrowPrimary />
          </Link>
        )}
      </div>
    </>
  );
};

export default Singelvask;
