import { HTTPMethod } from "workbox-routing/utils/constants";
import { auth } from "./Firebase";
import { ProgramOption } from "../stores/programStore";
import { CouponTokenData } from "../types";
import { Subscription } from "../stores/subscriptionStore";

export const calculateNewTotal = (
  base: number,
  checkedState: boolean[],
  options: ProgramOption[],
  couponData?: CouponTokenData,
) => {
  let optsPrice = 0
  for (let i = 0; i < checkedState.length; i++) {
    if (checkedState[i]) {
      optsPrice += options[i].price
    }
  }

  if (couponData?.isFixedPrice) {
    return couponData.price + optsPrice
  }

  return couponData ? (base + optsPrice) - ((base + optsPrice) * couponData.discount) : base + optsPrice
}

export const scrollToDoc = (el: HTMLElement) => {
  el.scrollIntoView({ block: "start", behavior: "smooth" });
};

export const calcPayNow = (difference: number, lastInTime: number, nextInTime: number): number => {
  const differenceInTime = nextInTime - lastInTime;
  const daysInPeriod = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  const timeNow = new Date().getTime();
  const daysLeftInTime = nextInTime - timeNow;
  const daysLeft = Math.ceil(daysLeftInTime / (1000 * 3600 * 24));

  const payNow = (difference / daysInPeriod) * daysLeft;

  return Number(toFixed(payNow))
}

export const toFixed = (num: number) => {
  const regex = new RegExp('^-?\\d+(?:.\\d{0,' + (2 || -1) + '})?')
  return num.toString().match(regex)[0]
}

export const requestConfig = (method: HTTPMethod, body: BodyInit, token: string, headers?: HeadersInit): RequestInit => {
  const ret: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      ...headers,
    },
    body: body
  }
  return ret
}

export const getToken = async () => {
  return await auth.currentUser.getIdToken()
}

export const PollPayment = async (pid: string): Promise<string> => {
  const token = await getToken()
  const req = requestConfig("GET", null, token)
  let status: string;
  await fetch("/api/payments/status/" + pid, req).then(response => response.json()).then(data => status = data.status).catch(err => console.error(err))
  return status
}


export const decodeB64 = (s: string) => {
  const ds = atob(s)
  const res = JSON.parse(ds)
  return res
}

export const b64Encode = (str) => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
}
export const b64Decode = (str) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

export const isLocalhost = () => {
  return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"
}

const isBeforeNow = (d: Date) => {
  const now = new Date().getTime()
  const then = d.getTime()
  return now > then
}

export const subscriptionIsDone = (sub: Subscription) => {
  return sub.cancelled && sub.nextDueId === "" && isBeforeNow(sub.nextDue.toDate())
}