import React from "react";
import LogInWithVipps from "../../assets/log_in_with_vipps.svg"

const VippsLoginButton = ({ onClick, classes }: { onClick: () => void, classes?:string }) => {
  return (
      <img src={LogInWithVipps} alt="Logg inn med Vipps"  onClick={onClick} className={`${classes}`}/>
  );
};

export default VippsLoginButton;
