import React from 'react'
import Header from '../../components/Header/Header'
import SkiboksLavWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_07_Skiboks_OK_2400x1800_v02.webp'
import SkiboksLavAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_07_Skiboks_OK_2400x1800_v02.avif'
import SkiboksLav from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_07_Skiboks_OK_2400x1800_v02.jpg'
import SkiboksHøyWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_08_Skiboks_ikkeOK_2400x1800_v02.webp'
import SkiboksHøyAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_08_Skiboks_ikkeOK_2400x1800_v02.avif'
import SkiboksHøy from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_08_Skiboks_ikkeOK_2400x1800_v02.jpg'
import LastestativWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_04_Lastestativ_OK_2400x1800_v02.webp'
import LastestativAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_04_Lastestativ_OK_2400x1800_v02.avif'
import Lastestativ from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_04_Lastestativ_OK_2400x1800_v02.jpg'
import SkistativWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_09_Skistativ_OK_2400x1800_v02.webp'
import SkistativAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_09_Skistativ_OK_2400x1800_v02.avif'
import Skistativ from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_09_Skistativ_OK_2400x1800_v02.jpg'
import SykkelstativWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_11_Sykkelstativ_ikkeOK_2400x1800_v02.webp'
import SykkelstativAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_11_Sykkelstativ_ikkeOK_2400x1800_v02.avif'
import Sykkelstativ from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_11_Sykkelstativ_ikkeOK_2400x1800_v02.jpg'
import SykkelstativOKWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_10_Sykkelstativ_OK_2400x1800_v02.webp'
import SykkelstativOKAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_10_Sykkelstativ_OK_2400x1800_v02.avif'
import SykkelstativOK from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_10_Sykkelstativ_OK_2400x1800_v02.jpg'
import LedbarWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_05_Lysboyle_OK_2400x1800_v02.webp'
import LedbarAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_05_Lysboyle_OK_2400x1800_v02.avif'
import Ledbar from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_05_Lysboyle_OK_2400x1800_v02.jpg'
import LysplankeWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_01_Ekstralys_OK_2400x1800_v02.webp'
import LysplankeAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_01_Ekstralys_OK_2400x1800_v02.avif'
import Lysplanke from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_01_Ekstralys_OK_2400x1800_v02.jpg'
import KufangerWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_02_Kufanger_OK_2400x1800_v02.webp'
import KufangerAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_02_Kufanger_OK_2400x1800_v02.avif'
import Kufanger from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_02_Kufanger_OK_2400x1800_v02.jpg'
import PickUpWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_06_Pickup_OK_2400x1800_v03.webp'
import PickUpAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_06_Pickup_OK_2400x1800_v03.avif'
import PickUp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_06_Pickup_OK_2400x1800_v03.jpg'
import TaxiWebp from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_03_Taxiskilt_OK_2400x1800_v02.webp'
import TaxiAvif from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_03_Taxiskilt_OK_2400x1800_v02.avif'
import Taxi from '../../assets/ekstrautstyr/DiveIn_Ekstrautstyr_03_Taxiskilt_OK_2400x1800_v02.jpg'

import InfoImageBox from '../../components/InfoImageBox'

const files = [
  {
    avif: SkiboksLavAvif,
    webp: SkiboksLavWebp,
    jpeg: SkiboksLav,
    header: 'Takboks u/glippe',
    description: [
      'Ingen, eller liten glippe mellom takboks og frontrute. Takbørsten har ingen mulighet til å hekte seg under takboksen.',
      'Er du usikker hva som gjelder din takboks, ta kontakt med betjeningen så hjelper vi deg.',
    ],
    verdict: 'Benytt ordinært vaskeprogram',
    isOK: true,
  },
  {
    avif: SkiboksHøyAvif,
    webp: SkiboksHøyWebp,
    jpeg: SkiboksHøy,
    header: 'Takboks m/glippe',
    description: [
      'For stor glippe mellom takboks og frontrute – Takbørste kan kile seg mellom disse.',
      'Litt dårligere vaskeresultat på frontrute må påregnes, da takbørste ikke vil berøre frontrute.',
    ],
    verdict: 'Kryss av for takboks når du kjøper vaskeprogram',
    isOK: true,
  },
  {
    avif: LastestativAvif,
    webp: LastestativWebp,
    jpeg: Lastestativ,
    header: 'Lasteholdere',
    description: ['Lasteholdere må være forskriftsmessig festet.'],
    verdict: 'Benytt ordinært vaskeprogram.',
    isOK: true,
  },
  {
    avif: SkistativAvif,
    webp: SkistativWebp,
    jpeg: Skistativ,
    header: 'Skistativ',
    description: ['Skistativ må være forskriftsmessig festet.'],
    verdict: 'Benytt ordinært vaskeprogram.',
    isOK: true,
  },
  {
    avif: SykkelstativAvif,
    webp: SykkelstativWebp,
    jpeg: Sykkelstativ,
    header: 'Sykkelstativ',
    description: [
      'Deler av stativet/stang stikker opp. Kan ikke vaskes i vaskegate.',
    ],
    verdict: 'Vaskegate ikke mulig, benytt selvvask.',
    isOK: false,
  },
  {
    avif: SykkelstativOKAvif,
    webp: SykkelstativOKWebp,
    jpeg: SykkelstativOK,
    header: 'Sykkelstativ',
    description: [
      'OK, så lenge langsgående sykkelholder ikke stikker ut over frontrute.',
      'Stikker den ut over frontrute – Benytt takboksprogram.',
    ],
    verdict: 'Benytt ordinært vaskeprogram.',
    isOK: true,
  },
  {
    avif: LedbarAvif,
    webp: LedbarWebp,
    jpeg: Ledbar,
    header: 'LED-bar på tak',
    description: ['LED-bar må være forskiftsmessig festet.'],
    verdict: 'Benytt ordinært vaskeprogram.',
    isOK: true,
  },
  {
    avif: LysplankeAvif,
    webp: LysplankeWebp,
    jpeg: Lysplanke,
    header: 'Ekstralys foran',
    description: ['Ekstralys må være forskiftsmessig festet.'],
    verdict: 'Benytt ordinært vaskeprogram.',
    isOK: true,
  },
  {
    avif: KufangerAvif,
    webp: KufangerWebp,
    jpeg: Kufanger,
    header: 'Frontbøyle',
    description: ['Null problem.'],
    verdict: 'Benytt ordinært vaskeprogram.',
    isOK: true,
  },
  {
    avif: PickUpAvif,
    webp: PickUpWebp,
    jpeg: PickUp,
    header: 'Pickup',
    description: ['Null problem.'],
    verdict: 'Benytt ordinært vaskeprogram.',
    isOK: true,
  },
  {
    avif: TaxiAvif,
    webp: TaxiWebp,
    jpeg: Taxi,
    header: 'Taxi-skilt',
    description: [
      'Vi har egne avtaler og vaskeprogram for Taxi, hvor skiltet kan stå på under vask. Kontakt oss for tilbud.',
    ],
    verdict:
      'Eget taxiprogram må benyttes, hvis skiltet er på taket under vask.',
    isOK: false,
  },
]

const InfoTakboks = () => {
  return (
    <>
      <Header text="Informasjon" backLink="back"/>
      <div className="container">
        <div className="inner" style={{ marginBottom: 25 }}>
          <h3 style={{ textAlign: 'center', letterSpacing: 0.8 }}>Takboks</h3>
          <p>
            Ved aktivering av takboksfunksjonen i et vaskeprogram, vil man
            oppleve at en del av panser + frontvindu ikke alltid vil få et
            tilfredstillende vaskeresultat.
            <br /> Dette skyldes at høyttrykksbom og takbørste blir satt i øvre
            posisjon, slik at disse ikke skal rive av takboks eller annet
            ekstrautstyr som stikker ut foran bilens frontrute.
            <br />
            Når vaskemaskinen har passert bilens frontrute, vil utstyret senkes
            og resten av bilens tak vil bli vasket som i et ordinært
            vaskeprogram.
          </p>
          <p>
            Ofte kan man allikevel vaske med ordinært vaskeprogram hvis man har
            montert takutstyr, se eksempler nedenfor på når man må velge
            takboksfunksjonen eller ikke.
          </p>
          <p>
            Er du i tvil, ta gjerne kontakt med betjeningen så forteller vi deg
            hva du bør velge.
          </p>
          {files.map((obj, idx) => {
            return (
              <InfoImageBox
                key={idx}
                imgs={{ webp: obj.webp, avif: obj.avif, jpg: obj.jpeg }}
                headerText={obj.header}
                infoText={obj.description}
                verdictText={obj.verdict}
                isOK={obj.isOK}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default InfoTakboks
