import React from 'react'
import './DiveInLogoBig.css'

function DiveInLogo() {
  return (
    <svg
      className="diveInLogo"
      xmlns="http://www.w3.org/2000/svg"
      width={206.445}
      height={69.26}
      viewBox="0 0 206.445 69.26"
    >
      <defs>
        <clipPath id="prefix__a">
          <path
            transform="translate(34.25 185.3)"
            fill="none"
            d="M0 0h206.445v69.26H0z"
          />
        </clipPath>
        <style>{'.prefix__c{fill:#00bbb4}.prefix__d{fill:#25221e}'}</style>
      </defs>
      <g transform="translate(-34.25 -185.3)" clipPath="url(#prefix__a)">
        <path
          className="prefix__c"
          d="M64.941 185.3a.414.414 0 01.4.414v32.329c0 2.494 0 5.025.046 7.519a.423.423 0 01-.4.433h-7.97a.423.423 0 01-.4-.377c0-.5-.083-1.1-.11-1.693a.4.4 0 00-.718-.248c-1.436 1.749-3.515 3.083-8.328 3.083-7.537 0-13.206-5.816-13.206-14.724 0-9.562 7.316-15.157 14.025-15.157 4.178 0 6.4 1.141 7.519 2.126a.4.4 0 00.653-.331v-10.85a2.475 2.475 0 012.43-2.522zm-21.6 26.578c0 4.417 2.439 7.712 6.746 7.712s6.573-2.964 6.573-7.74-1.841-7.8-6.552-7.8c-4.6 0-6.764 3.746-6.764 7.832M74.54 185.3h8.043a.414.414 0 01.4.414v6.366a.414.414 0 01-.405.414h-8.043a.414.414 0 01-.4-.414v-6.368a.414.414 0 01.4-.414m0 12.387h8.043a.414.414 0 01.4.423v27.461a.414.414 0 01-.4.423h-8.043a.414.414 0 01-.4-.423V198.11a.414.414 0 01.4-.423M96.286 197.687a.424.424 0 01.387.285c3.745 11.043 5.788 16.565 6.736 20.071h.1c1-3.58 2.862-9.037 6.672-20.071a.414.414 0 01.387-.285h8.108a.414.414 0 01.377.58l-10.8 27.461a.4.4 0 01-.368.267h-9.39a.4.4 0 01-.368-.267l-10.8-27.47a.423.423 0 01.377-.58zM128.735 214.086a.423.423 0 00-.4.479 6.054 6.054 0 006.442 5.374c3.046 0 4.528-.92 5.522-2.448a.405.405 0 01.341-.193h8.035a.414.414 0 01.377.571c-1.767 4.822-5.89 8.89-14.338 8.89-11.237 0-15.221-7.7-15.221-14.881 0-7.933 5.126-14.954 15.166-14.954 10.6 0 14.918 7.565 14.918 14.973v1.776a.414.414 0 01-.4.414zm11.421-5.6a.414.414 0 00.4-.478c-.295-2.043-1.546-4.666-5.908-4.666-4.169 0-5.807 2.19-6.193 4.647a.423.423 0 00.4.5z"
        />
        <path
          className="prefix__d"
          d="M163.466 185.3h6.019a2.466 2.466 0 012.42 2.522v4.258a.4.4 0 01-.4.414h-8.034a.414.414 0 01-.4-.414v-6.368a.414.414 0 01.4-.414m0 12.387h8.03a.414.414 0 01.4.423v27.461a.414.414 0 01-.4.423h-8.034a.414.414 0 01-.4-.423V198.11a.414.414 0 01.4-.423M179.801 204.828c0-2.227 0-4.739-.046-6.709a.4.4 0 01.4-.433h7.878a.4.4 0 01.4.377 61.8 61.8 0 01.156 2.034.4.4 0 00.709.258c1.4-1.693 3.847-3.433 8.282-3.433 6.727 0 10.684 4.334 10.684 11.6v17.051a.414.414 0 01-.4.423h-8.139a.414.414 0 01-.4-.423v-15.591c0-3.147-1.178-5.936-4.96-5.936s-5.706 2.761-5.706 7.785v13.742a.414.414 0 01-.4.423h-8.08a.414.414 0 01-.4-.423z"
        />
        <path
          className="prefix__c"
          d="M220.21 208.491c-1.031-6.552 3-12.092 10.243-11.964 5.954.092 9.433 5.144 9.838 11.8s-5.062 12.617-11.273 14.908a37.782 37.782 0 01-13.869 2.6s7.316-2.982 5.062-17.31"
        />
        <path
          className="prefix__d"
          d="M80.329 254.56a6.359 6.359 0 01-4.6-1.712c-1.712-1.61-1.73-3.433-1.73-6.525s0-4.905 1.73-6.525a6.35 6.35 0 014.6-1.7 5.945 5.945 0 016.276 5.117h-3.414a2.761 2.761 0 00-2.862-2.3 2.834 2.834 0 00-2.144.847c-.626.681-.837 1.472-.837 4.6s.212 3.893.837 4.6a2.825 2.825 0 002.144.837 2.761 2.761 0 002.88-2.291h3.4a5.954 5.954 0 01-6.276 5.117M103.39 254.397l-1.012-2.862h-6.092l-1.031 2.862h-3.479l6.221-16.188h2.623l6.249 16.188zm-3.994-11.411l-2.19 5.89h4.252zM122.477 254.394l-3.341-6.442h-2.4v6.442h-3.342v-16.16h6.71c3.488 0 5.522 2.245 5.522 4.951a4.38 4.38 0 01-3.12 4.252l3.819 6.985zm-2.6-13.372h-3.147v4.279h3.147a2.153 2.153 0 100-4.279M148.115 254.422h-2.788l-3.147-9.829-3.157 9.829h-2.788l-4.565-16.2h3.49l2.641 10.215 3.129-10.215h2.494l3.129 10.215 2.669-10.215h3.488zM168.509 254.397l-1.012-2.862h-6.083l-1.031 2.862h-3.488l6.23-16.188h2.61l6.258 16.188zm-3.994-11.411l-2.163 5.89h4.252zM183.224 254.56c-2.6 0-4.6-.525-6.249-2.135l2.163-2.052a5.8 5.8 0 004.114 1.362c2 0 3.055-.727 3.055-2.025a1.757 1.757 0 00-.534-1.334 2.54 2.54 0 00-1.537-.552l-2.043-.294a5.862 5.862 0 01-3.322-1.334 4.123 4.123 0 01-1.224-3.184c0-2.89 2.264-4.914 5.963-4.914a7.583 7.583 0 015.522 1.841l-2.117 1.979a4.933 4.933 0 00-3.515-1.114c-1.776 0-2.614.92-2.614 2.043a1.463 1.463 0 00.479 1.159 2.872 2.872 0 001.564.617l2.043.294a5.568 5.568 0 013.267 1.27 4.307 4.307 0 011.325 3.414c0 3.138-2.761 4.914-6.35 4.914M205.338 254.422v-6.782h-5.788v6.782h-3.35v-16.2h3.35v6.617h5.789v-6.614h3.341v16.2z"
        />
      </g>
    </svg>
  )
}

export default DiveInLogo
