import React, { useState, useEffect, Fragment } from "react";
import Header from "../components/Header/Header";
import DiveInLogoSmall from "../components/DiveInLogo/DiveInLogoSmall";
import VippsButton from "../assets/pay_with_vipps.png";
import { getValuecards } from "../stores/productStore";
import { CorporateClientData, Product, Token, TokenType } from "../types";
import { divein } from "../utils/api";
import { toast } from "react-toastify";
import { isLoggedIn } from "../utils/Firebase";
import { LoginToast } from "../components/LoginToast";
import { useLocation, useNavigate } from "react-router-dom";
import { useState as useSpecialState } from "@hookstate/core";
import UserStore from "../stores/userStore";
import VippsLoginButton from "../utils/VippsLoginButton/VippsLoginButton";
import Cookies from "js-cookie";
import { LoginUrl } from "../utils/urls";
import BubblesBG from "../assets/webp/Blue-Water-Drops.webp";
import { getAndSetCorpToken, getTokens } from "../stores/tokenStore";
import Button from "../components/Button";

type Valuecard = {
  id: string;
  cost: number;
  value: string;
  discount: number;
};

const Valuecard = () => {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Product>({
    productId: "9",
    eanCode: "",
    productNumber: 0,
    categoryId: "",
    price: 0,
    value: 0,
    description: "",
    name: "",
  });

  const location = useLocation();
  const userStore = useSpecialState(UserStore);

  const [corpToken, setCorpToken] = useState<Token[]>();
  const [, setCorpData] = useState<CorporateClientData[]>();

  const [valuecards, setValuecards] = useState<Product[]>();
  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const vc = valuecards.filter(
      (obj) => obj.productId === event.target.value
    )[0];
    setSelectedOption(vc);
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetch = async () => {
      const valuecards = await getValuecards();
      setValuecards(valuecards);
    };
    fetch();
    const cp = getTokens(TokenType.corporateClient);
    if (cp) {
      setCorpToken(cp);
    } else {
      getAndSetCorpToken(setCorpToken, setCorpData);
    }
  }, []);

  const InitiatePurchase = async (tokId?: string) => {
    if (loading) return;

    if (!isLoggedIn()) {
      toast.error(<LoginToast />, { autoClose: false, icon: false });
      return;
    }
    setLoading(true);
    const { status, responseData } = await divein.buyValuecard({
      productId: selectedOption.productId,
      siteId: "1",
      corpTokenId: tokId ?? "",
    });
    if (status < 300) {
      setLoading(false);
      if (tokId) {
        navigate("/selvvask");
      } else {
        window.location.href = responseData.url;
      }
    } else {
      toast.error(responseData.error);
      setLoading(false);
    }
  };

  return (
    <>
      <Header text="Kjøp verdikort" backLink="/selvvask" />
      <div className="w-screen flex flex-col items-center relative overflow-hidden">
        <p className="big-text med-spacing center-text">
          Saldo blir lagt til din profil, og du trekker beløp fra din saldo hver
          gang du vasker.
        </p>
        <form className="radio-valuecard">
          {valuecards &&
            valuecards.map(({ price, productId, value }, i) => (
              <Fragment key={i}>
                <label className="valuecardContainer" htmlFor={productId}>
                  <input
                    type="radio"
                    onChange={handleChangeOption}
                    value={productId}
                    id={productId}
                    checked={selectedOption?.productId === productId}
                    name="valuecard"
                    key={i}
                  />

                  <label
                    className="radio-valuecard-icon z-20"
                    htmlFor={productId}
                  >
                    <span className="radio-valuecard-icon-inner"></span>
                  </label>
                  <label
                    htmlFor={productId}
                    className="big-text text-white bold z-20"
                  >
                    {price},-
                    <br />
                    <span className="small-text regular text-white">
                      Vask for <span className="bold">{value},-</span>
                    </span>
                  </label>
                  <div className="valuecard-discount-icon z-20">
                    <p>{((value - price) / value) * 100}%</p>
                    <p>rabatt</p>
                  </div>
                </label>
              </Fragment>
            ))}
        </form>
        <p className="small-text small-spacing">
          Ditt digitale verdikort er gyldig i 24 måneder.
        </p>
        {userStore.user.get() ? (
          <>
            <div className="vipps" onClick={() => InitiatePurchase()}>
              <img src={VippsButton} alt="Betal med vipps" />
            </div>
            {corpToken && (
              <>
                {corpToken.map((t) => {
                  return (
                    <Button
                      key={t.id}
                      text={`Belast ${
                        (t.data as unknown as CorporateClientData).companyName
                      }`}
                      onClick={() => InitiatePurchase(t.id)}
                    />
                  );
                })}
              </>
            )}
          </>
        ) : (
          <VippsLoginButton
            onClick={() => {
              const inFiveMinutes = new Date(
                new Date().getTime() + 2 * 60 * 1000
              );
              Cookies.set("divein/redirect", location.pathname, {
                domain: "divein.no",
                expires: inFiveMinutes,
              });
              window.location.href = LoginUrl;
            }}
            classes="w-5/6"
          />
        )}
        <div className="divein-logo-small">
          <DiveInLogoSmall />
        </div>
        <img
          src={BubblesBG}
          alt="bobler bakgrunn"
          className="absolute -top-28 left-40 w-11/12 rotate90 opacity-70"
        />
      </div>
    </>
  );
};

export default Valuecard;
