import React from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import CloseIconCircle from "../../utils/Icons/CloseIconCircle";
import ForwardArrowWhite from "../../utils/Icons/ForwardArrowWhite";
import InfoIcon from "../../utils/Icons/InfoIcon";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

interface Props {
  title: string;
  text: string
  id: string;
  url: string;
  isShowing: boolean;
  setShowing: (input: boolean) => void;
}

const NavItem = (props: Props) => {
  const { url,title, text, id, isShowing, setShowing } = props;
  const navigate = useNavigate();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div className="nav-item">
      <div className="nav-iconbox flex-col" onClick={() => setShowing(true)}>
        <InfoIcon />
        <p className="text-xs text-white mt-1 mb-0">Se video</p>
      </div>
      <ReactModal
        style={customStyles}
        isOpen={isShowing}
        onRequestClose={() => setShowing(false)}
      >
        <div
          onClick={() => setShowing(false)}
          style={{ float: "right", marginBottom: 5 }}
        >
          <CloseIconCircle />
        </div>
        <div className="mt-10">
          <LiteYouTubeEmbed id={id} title={title} />
        </div>
      </ReactModal>
      <div className="button nav-button" onClick={() => navigate(url)}>
        <p>{text}</p>
        <span className="forward-arrow-nav">
          <ForwardArrowWhite />
        </span>
      </div>
    </div>
  );
};

export default NavItem;
