import React from "react";
import ForwardArrowWhite from "../utils/Icons/ForwardArrowWhite";

interface ButtonProps {
  onClick: () => void;
  text: string;
  styles?: string;
  loading?: boolean;
}

export default function Button(props: ButtonProps) {
  return (
    <div className={`button ${props.styles}`} onClick={props.onClick}>
      {props.loading ? <p>Laster...</p> : <p>{props.text}</p>}
      <span className="forward-arrow">
        <ForwardArrowWhite />
      </span>
    </div>
  );
}
