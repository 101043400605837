import React from "react";
import Checkmark from "../utils/Icons/CheckMark";
import CrossIcon from "../utils/Icons/CrossIcon";
interface Props {
  imgs: {
    avif: string;
    webp: string;
    jpg: string;
  };
  headerText: string;
  infoText: string[];
  verdictText: string;
  isOK: boolean;
  key?: number;
}
const padding = {
  padding: "0px 5px",
};
const InfoImageBox = ({
  imgs,
  headerText,
  infoText,
  verdictText,
  isOK,
}: Props) => {
  return (
    <div
      style={{
        marginBottom: 20,
        boxShadow: "0px 5px 2px -2px #ccc,0px -5px 5px -5px #ccc",
      }}
    >
      <picture>
        <source srcSet={imgs.avif} type="image/avif" />
        <source srcSet={imgs.webp} type="image/webp" />
        <img src={imgs.jpg} width={"100%"} alt={""} />
      </picture>
      <h3 style={padding}>{headerText}</h3>
      {infoText.map((txt) => {
        return (
          <p key={txt} style={padding}>
            {txt}
          </p>
        );
      })}
      <div
        style={{
          minHeight: 50,
          backgroundColor: "#00bbb4",
          color: "#fff",
          padding: "15px 25px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {isOK ? <Checkmark /> : <CrossIcon />}
        <p style={{ marginLeft: 10 }}>{verdictText}</p>
      </div>
    </div>
  );
};

export default InfoImageBox;
