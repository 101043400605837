import React from 'react'

function CarFlushingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32.565}
      height={23.924}
      viewBox="0 0 32.565 23.924"
    >
      <defs>
        <style>
          {
            '.prefix__a-f{fill:none;stroke:#00bbb4;stroke-linecap:round}.prefix__b-f{fill:#00bbb4}'
          }
        </style>
      </defs>
      <path
        className="prefix__a-f"
        d="M10.202 21.1l1.449-1.449M13.208 21.1l-1.449-1.449M11.705 19.815v3.6"
      />
      <g>
        <path
          className="prefix__a-f"
          d="M14.874 21.1l1.449-1.449M17.88 21.1l-1.449-1.449M16.377 19.815v3.6"
        />
        <g>
          <path
            className="prefix__a-f"
            d="M19.546 21.1l1.449-1.449M22.552 21.1l-1.449-1.449M21.049 19.815v3.6"
          />
        </g>
      </g>
      <g>
        <path
          className="prefix__b-f"
          d="M.839 18.057a.848.848 0 00.947.731h4.982c.731 0 1.191-.108 1.191-.487v-1.11L.84 16.433zM24.444 18.299c0 .379.46.487 1.191.487h4.981a.848.848 0 00.947-.731v-1.624l-7.119.758zM25.013 11.153s.65-.947 1.137-1.679a2.506 2.506 0 012.328-1.029h3.14a6.241 6.241 0 00-2.192-2.246c.758-.217 1.516-.569 1.516-1.11a1.242 1.242 0 00-1.327-1.38h-3.466c-.325-.515-.677-1.083-1.029-1.651a4.83 4.83 0 00-4.2-2.057H10.85a4.83 4.83 0 00-4.2 2.057c-.352.568-.731 1.137-1.029 1.651H2.156A1.242 1.242 0 00.83 5.089c0 .677 1.137 1.029 2.03 1.218a6.352 6.352 0 00-2 2.138h2.842A2.506 2.506 0 016.03 9.474a184.5 184.5 0 001.137 1.679H-.007l.839 4.737 7.688.947h15.376l.113-.014.005.014 7.688-.947.618-3.49.238-1.247zM6.795 5.089a5.821 5.821 0 00-1.164.108c.406-.65 1.056-1.706 1.706-2.707A4.021 4.021 0 0110.829.812h10.07a4 4 0 013.492 1.678c.6.947 1.218 1.949 1.651 2.6H6.795zm2.515 9.819l.315-.777h.012s.373-.869.738-1.848l.308-.76a1.944 1.944 0 011.769-1.1h7.823a1.935 1.935 0 011.741 1.045l1.284 3.441z"
        />
      </g>
    </svg>
  )
}

export default CarFlushingIcon
