import { Timestamp } from "firebase/firestore";
import { ProgramOption } from "./stores/programStore";

export interface Options {
  ID: string;
  Option: string;
  Price: number;
  Selected: boolean;
}
export interface Product {
  eanCode: string;
  categoryId: string;
  description: string;
  name: string;
  price: number;
  productNumber: number;
  productId: string;
  value: number;
}

export interface Customer {
  created: Timestamp;
  updated: Timestamp;
  consentedMarketing: boolean;
  decidedMarketing: boolean;
  id: string;
  email: string;
  firstName: string;
  phone: string;
  surname: string;
  washStamps: number;
}

export interface Token {
  id: string;
  kind: TokenType;
  status: TokenStatus;
  data: string;
  ref: string;
  uses: number;
  maxUses: number;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  expiresAt: Timestamp;
}

export type WashTokenData = {
  tokenId: string;
  productName: string;
  programId: string;
  options: ProgramOption[];
};

export type CorporateClientData = {
  companyName: string;
  corpRefId: string;
};

export type DigitalValuecardData = {
  currentValue: number;
  totalFilled: number;
  updated: Timestamp;
};

export type ValuecardOrder = {
  date: Timestamp;
  amount: number;
  site: string;
};

export type ValuecardData = {
  remainingValue: number;
  initialValue: number;
  history: ValuecardOrder[];
};

export enum ProductCategory {
  Wares = "1",
  Washgate = "2",
  Selfservice = "3",
  Matwash = "4",
  Vacuum = "5",
  Valuecard = "6",
  Subscription = "7",
}
export interface CouponTokenData {
  appliesToCategory: ProductCategory;
  appliesToProduct: string;
  code: string;
  createdAt: Timestamp;
  couponRefId: string;
  description: string;
  discount: number;
  isFixedPrice: boolean;
  freeMatwash: boolean;
  id: string;
  price: number;
  uses: number;
  kind: TokenType;
  status: TokenStatus;
  limitedToSite: boolean;
  siteId: string;
  isCampaign: boolean;
  intervalCount: number;
  intervalType: IntervalType;
  maxUses: number;
  expiresAt: Timestamp;
}

export enum TokenType {
  unknown,
  washgate,
  blowdry,
  matwash,
  coupon,
  corporateClient,
  vacuumHall,
  valuecard,
  freeWash
}

export enum TokenStatus {
  pending,
  active,
  consumed,
  cancelled,
  rejected,
  paused,
}

export interface CouponToken {
  appliesToCategory: string;
  appliesToProduct: string;
  code: string;
  createdAt: Timestamp;
  couponRefIc: string;
  description: string;
  discount: number;
  isFixedPrice: boolean;
  id: string;
  price: number;
  uses: number;
  kind: TokenType;
  status: TokenStatus;
  limitedToSite: boolean;
  siteId: string;
  isCampaign: boolean;
  intervalCount: number;
  intervalType: IntervalType;
  maxUses: number;
  expiresAt: Timestamp;
}

export enum IntervalType {
  Month = "MONTH",
  Week = "WEEK",
  Day = "DAY",
}

export interface Site {
  id: string;
  name: string;
  address: string;
  matwasherIds: string[];
  selfserviceIds: string[];
}
