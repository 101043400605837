export const IntervalTypes = {
  DAY: "dag",
  DAYS: "dager",
  WEEK: "uke",
  WEEKS: "uker",
  MONTH: "måned",
  MONTHS: "måneder",
};

export const SelfserviceProductID = "12";
export const MatwashProductID = "10";

export const CouponCategories = {
  "1": "Gjelder varer",
  "2": "Gjelder vaskegate",
  "3": "Gjelder selvvask",
  "4": "Gjelder mattevask",
  "5": "Gjelder støvsugerhall",
  "6": "Gjelder verdikort",
  "7": "Gjelder månedsabonnement",
};


export const ProgramIdToName = new Map<string, string>([
  ["1", "Ultimate"],
  ["2", "Premium"],
  ["3", "Basic"],
  ["4", "Børsteløs"],
])

export const PaymentStatuses = new Map<number, string>([
  [0, "Ikke oppdatert"],
  [1, "Betalt"],
  [2, "Feilet"],
  [3, "Refundert"],
  [4, "Kansellert"],
])