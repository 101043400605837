import React, { KeyboardEvent, useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import ForwardArrowWhite from "../../utils/Icons/ForwardArrowWhite";
import FirmakundeBedriftFaktura from "./FirmakundeBedriftFaktura";
import FirmakundeLeasingFaktura from "./FirmakundeLeasingFaktura";
import Styles from "./Firmakunde.module.css";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { useInputs } from "../../hooks/useInputs";
import { CorporateClientRequest, divein } from "../../utils/api";
import { ValidateMe } from "../../utils/ValidateMe";

export interface Inputs {
  CoName: string;
  CoContactPerson: string;
  CoEmail: string;
  BillingEmail: string;
  CoCellphone: string;
  CoOrgNum: string;
  Address: string;
  City: string;
  ZipCode: string;
  BillingReference: string;
  Comments: string;
}

const Firmakunde = () => {
  const initialState = {
    CoName: "",
    CoContactPerson: "",
    CoEmail: "",
    BillingEmail: "",
    CoCellphone: "",
    CoOrgNum: "",
    Address: "",
    City: "",
    ZipCode: "",
    BillingReference: "",
    Comments: "",
  };


  const [loading, setLoading] = useState(false)

  const [selectedBilling, setSelectedBilling] = useState<string>();
  const [sent, setSent] = useState(false);
  const [selectedCars, setSelectedCars] = useState<Array<string>>([]);
  const [selectedNumbers, setSelectedNumbers] = useState<Array<string>>([]);
  const { inputs, onChange } = useInputs(initialState);
  const [error, setError] = useState("");
  const [selectedLeasing, setSelectedLeasing] = useState<string>("");
  const [selectedNumberOrLicense, setSelectedNumberOrLicense] =
    useState<string>("skiltgjenkjenning");
  const checkNext = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      const nextID = document.getElementById(
        `${Number((event.target as Element).id) + 1}`
      );
      window.setTimeout(() => {
        nextID.focus();
      }, 0);
    }
  };

  const resetStates = () => {
    setSent(false);
    setSelectedLeasing("");
    setSelectedNumberOrLicense("skiltgjenkjenning");
    setSelectedNumbers([]);
    setSelectedCars([]);
    setSelectedBilling("");
  };

  useEffect(() => {
    let timeoutId:NodeJS.Timeout;
    if (sent) {
      timeoutId = setTimeout(() => {
        resetStates();
      }, 5000);
    }
    return () => timeoutId ? clearTimeout(timeoutId) : null
    // eslint-disable-next-line
  }, [sent]);

  const handleRemoveCar = (index: number) => {
    const arr = [...selectedCars];
    arr.splice(index, 1);
    setSelectedCars(arr);
    setSent(false);
  };
  const handleRemoveNumber = (index: number) => {
    const arr = [...selectedNumbers];
    arr.splice(index, 1);
    setSelectedNumbers(arr);
  };
  const handleAddNumberOrCar = (val: string, numOrCar: "num" | "car"): void => {
    if (val) {
      if (numOrCar === "num") {
        const arr = selectedNumbers;
        if (arr.indexOf(val) !== -1) {
          return;
        } else {
          arr.push(val);
          setSelectedNumbers(arr);
        }
      } else {
        const arr = selectedCars;
        if (arr.indexOf(val) !== -1) {
          return;
        } else {
          arr.push(val);
          setSelectedCars(arr);
        }
      }
    } else {
      console.error("input empty");
    }
  };

  const send = async () => {
    if(loading) return;
    setLoading(true)
    const body: CorporateClientRequest = {
      companyName: inputs.CoName,
      contactPerson: inputs.CoContactPerson,
      contactEmail: inputs.CoEmail,
      contactPhone: inputs.CoCellphone,
      billingEmail: inputs.BillingEmail,
      address: inputs.Address,
      city: inputs.City,
      zipCode: inputs.ZipCode,
      billingReference: inputs.BillingReference,
      leasingPartner: selectedLeasing,
      organisationNumber: inputs.CoOrgNum,
      comments: inputs.Comments,
      useLicenseplate: selectedNumberOrLicense === "skiltgjenkjenning",
      useUsers: selectedNumberOrLicense !== "skiltgjenkjenning",
      cars: selectedCars,
      users: selectedNumbers,
    };
    console.log("SENDING REQUEST: \n", body);
    const { status } = await divein.sendCorporateClientRequest(body);
    if (status !== 201) {
      setError("Noe gikk galt under sending, prøv igjen senere");
    setLoading(false)
      return;
    }
    setLoading(false)
    setSent(true);
  };

  const onSubmit = () => {
    setError("");
    const numOfIds = selectedBilling === "leasing" ? 5 : 11;
    for (let i = 1; i < numOfIds; i++) {
      const doc = document.getElementById(String(i)) as HTMLInputElement;
      ValidateMe(doc, doc.getAttribute("data-id"))
    }
    const invalids = document.getElementsByClassName("invalid");
    if (invalids.length > 0) {
      (invalids[0] as HTMLElement).focus();
      return;
    }
    if (
      selectedNumberOrLicense === "skiltgjenkjenning" &&
      selectedCars.length < 1
    ) {
      setError("Du må legge til minst ett bilnummer");
    } else if (
      selectedNumberOrLicense === "mobilnummer" &&
      selectedNumbers.length < 1
    ) {
      setError("Du må legge til minst ett telefonnummer");
    } else if (selectedBilling === "leasing" && selectedLeasing === "") {
      setError("Du må velge leasing partner");
    } else {
      send();
    }
  };

  useEffect(() => {
    console.log(inputs.CoName);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header text="Firmakunde" backLink="back" />
      <div className="container">
        {sent && (
          <>
            <p className={Styles.header}>Takk for din henvendelse!</p>
          </>
        )}
        {!selectedBilling && !sent && (
          <>
            <div className="h-2/3 w-5/6 my-10">
              <LiteYouTubeEmbed
                id={"rSOCGGuP4sU"}
                title={"Bedriftsavtale"}
                noCookie={true}
              />
            </div>
            <div
              className="button"
              onClick={() => setSelectedBilling("bedrift")}
            >
              <p>Direkte faktura til bedriften</p>
              <span className="forward-arrow">
                <ForwardArrowWhite />
              </span>
            </div>
            <div
              className="button"
              onClick={() => setSelectedBilling("leasing")}
            >
              <p>Faktura til leasingselskap</p>
              <span className="forward-arrow">
                <ForwardArrowWhite />
              </span>
            </div>
          </>
        )}
        {selectedBilling === "bedrift" && !sent && (
          <FirmakundeBedriftFaktura
            inputs={inputs}
            onChange={onChange}
            selectedCars={selectedCars}
            selectedNumbers={selectedNumbers}
            selectedOption={selectedNumberOrLicense}
            handleOption={setSelectedNumberOrLicense}
            handleAdd={handleAddNumberOrCar}
            checkNext={checkNext}
            handleRemoveNumber={handleRemoveNumber}
            handleRemoveCar={handleRemoveCar}
            onSubmit={onSubmit}
            error={error}
            loading={loading}
          />
        )}
        {selectedBilling === "leasing" && !sent && (
          <FirmakundeLeasingFaktura
            inputs={inputs}
            onChange={onChange}
            selectedLeasing={selectedLeasing}
            handleSelectedLeasing={setSelectedLeasing}
            selectedNumbers={selectedNumbers}
            selectedCars={selectedCars}
            selectedOption={selectedNumberOrLicense}
            handleOption={setSelectedNumberOrLicense}
            onSubmit={onSubmit}
            checkNext={checkNext}
            handleRemoveCar={handleRemoveCar}
            handleRemoveNumber={handleRemoveNumber}
            handleAdd={handleAddNumberOrCar}
            error={error}
            loading={loading}
          />
        )}
      </div>
    </>
  );
};

export default Firmakunde;
