import React, { KeyboardEvent, useState } from "react";
import DiveInLogoSmall from "../../components/DiveInLogo/DiveInLogoSmall";
import TrashIcon from "../../utils/Icons/Trash";
import { ValidateMe } from "../../utils/ValidateMe";
import Styles from "./Firmakunde.module.css";
import { Inputs } from "./Firmakunde";

interface Props {
  inputs: Inputs;
  selectedOption: string;
  selectedCars: string[];
  selectedNumbers: string[];
  selectedLeasing: string;
  onChange: (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  checkNext: (e: KeyboardEvent<HTMLInputElement>) => void;
  handleAdd: (val: string, numOrCar: "num" | "car") => void;
  handleOption: (str: string) => void;
  handleRemoveCar: (index: number) => void;
  handleRemoveNumber: (index: number) => void;
  handleSelectedLeasing: (leasing: string) => void;
  onSubmit: () => string | void;
  error?: string
  loading: boolean
}
const FirmakundeLeasingFaktura = (props: Props) => {
  const [currentCar, setCurrentCar] = useState<string>("");
  const [currentNumber, setCurrentNumber] = useState<string>("");
  return (
    <>
      <p className="big-text big-spacing dark">
        Vi ønsker å registrere kjøp
        <br /> av bilvask ved hjelp av:
      </p>
      <form className="radio-valuecard">
        <label className="block" htmlFor="skiltgjenkjenning">
          <input
            type="radio"
            onChange={(event) => props.handleOption(event.target.value)}
            value="skiltgjenkjenning"
            id="skiltgjenkjenning"
            checked={props.selectedOption === "skiltgjenkjenning"}
            name="leasingCompanies"
            autoFocus={true}
          />

          <label className="radio-valuecard-icon" htmlFor="skiltgjenkjenning">
            <span className="radio-valuecard-icon-inner"></span>
          </label>
          <label
            htmlFor="skiltgjenkjenning"
            className="big-text primary medium"
          >
            Skiltgjenkjenning
          </label>
        </label>
        <label className="block" htmlFor="mobilnummer">
          <input
            type="radio"
            onChange={(event) => props.handleOption(event.target.value)}
            value="mobilnummer"
            id="mobilnummer"
            checked={props.selectedOption === "mobilnummer"}
            name="leasingCompanies"
          />

          <label className="radio-valuecard-icon" htmlFor="mobilnummer">
            <span className="radio-valuecard-icon-inner"></span>
          </label>
          <label htmlFor="mobilnummer" className="big-text primary medium">
            Ansattes mobil
          </label>
        </label>
      </form>
      <div className={Styles.inputBox}>
        <input
          id={"1"}
          data-id={""}
          type="text"
          key="CoName"
          name="CoName"
          onChange={props.onChange}
          value={props.inputs.CoName}
          required={true}
          onBlur={(e) => ValidateMe(e.target)}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Bedriftens navn</span>
      </div>

      <div className={Styles.inputBox}>
        <input
          id={"2"}
          type="text"
          key="CoContactPerson"
          data-id={""}
          name="CoContactPerson"
          onChange={props.onChange}
          value={props.inputs.CoContactPerson}
          required={true}
          onBlur={(e) => ValidateMe(e.target)}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Bedriftens kontaktperson</span>
      </div>
      <div className={Styles.inputBox}>
        <input
          id={"3"}
          type="text"
          key="CoEmail"
          name="CoEmail"
          data-id={"email"}
          onChange={props.onChange}
          value={props.inputs.CoEmail}
          required={true}
          onBlur={(e) => ValidateMe(e.target, "email")}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>E-post kontaktperson</span>
      </div>
      <div className={Styles.inputBox}>
        <input
          id={"4"}
          type="text"
          key="CoCellphone"
          name="CoCellphone"
          data-id={"CoCellphone"}
          onChange={props.onChange}
          value={props.inputs.CoCellphone}
          required={true}
          onBlur={(e) => ValidateMe(e.target, "CoCellphone")}
          onKeyPress={(e) => props.checkNext(e)}
        />
        <span className={Styles.floatingLabel}>Mobilnummer kontaktperson</span>
      </div>
      <div className={Styles.inputBox}>
        <input
          id={"5"}
          type="text"
          key="CarLicense"
          name="CarLicense"
          maxLength={7}
          onChange={(e) => setCurrentCar(e.target.value)}
          value={currentCar}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              props.handleAdd(currentCar, "car");
              setCurrentCar("");
            }
          }}
        />
        <span className={Styles.floatingLabel}>Bilens registreringsnummer</span>
        <div
          className={Styles.smallButton}
          onClick={() => {
            props.handleAdd(currentCar, "car");
            setCurrentCar("");
          }}
        >
          Legg til
        </div>
      </div>
      <div style={{ width: "80%" }}>
        {props.selectedCars.map((item, idx) => {
          const index = idx;
          return (
            <li className={Styles.item} key={idx}>
              {item}
              <span onClick={() => props.handleRemoveCar(index)}>
                <TrashIcon />
              </span>
            </li>
          );
        })}
      </div>
      {props.selectedOption === "mobilnummer" && (
        <>
          <div className={Styles.inputBox}>
            <input
              id={"5"}
              type="text"
              key="Cellphone"
              name="Cellphone"
              onChange={(e) => setCurrentNumber(e.target.value)}
              value={currentNumber}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  props.handleAdd(currentNumber, "num");
                  setCurrentNumber("");
                }
              }}
            />

            <span className={Styles.floatingLabel}>
              Mobilnummer til ansatte
            </span>
            <div
              className={Styles.smallButton}
              onClick={(e) => {
                props.handleAdd(currentNumber, "num");
                setCurrentCar("");
                (
                  (e.target as HTMLInputElement)
                    .previousElementSibling as HTMLElement
                ).focus();
              }}
            >
              Legg til
            </div>
          </div>
          <div style={{ width: "80%" }}>
            {props.selectedNumbers.map((item, idx) => {
              const index = idx;
              return (
                <li className={Styles.item} key={idx}>
                  {item}
                  <span onClick={() => props.handleRemoveNumber(index)}>
                    <TrashIcon />
                  </span>
                </li>
              );
            })}
          </div>
        </>
      )}
      <p className={Styles.header}>
        Hvilket leasingselskap benytter ditt firma?
      </p>
      <form className="radio-valuecard" style={{ marginBottom: 0 }}>
        <label className="block" htmlFor="DNB Autolease">
          <input
            type="radio"
            onChange={(event) =>
              props.handleSelectedLeasing(event.target.value)
            }
            value="DNB Autolease"
            id="DNB Autolease"
            checked={props.selectedLeasing === "DNB Autolease"}
            name="leasingCompanies"
          />

          <label className="radio-valuecard-icon" htmlFor="DNB Autolease">
            <span className="radio-valuecard-icon-inner"></span>
          </label>
          <label htmlFor="DNB Autolease" className="big-text primary medium">
            DNB Autolease
          </label>
        </label>
        <label className="block" htmlFor="Leaseplan Norge">
          <input
            type="radio"
            onChange={(event) =>
              props.handleSelectedLeasing(event.target.value)
            }
            value="Leaseplan Norge"
            id="Leaseplan Norge"
            checked={props.selectedLeasing === "Leaseplan Norge"}
            name="leasingCompanies"
          />

          <label className="radio-valuecard-icon" htmlFor="Leaseplan Norge">
            <span className="radio-valuecard-icon-inner"></span>
          </label>
          <label htmlFor="Leaseplan Norge" className="big-text primary medium">
            LeasePlan Norge
          </label>
        </label>
        <label className="block" htmlFor="ALD Automotive">
          <input
            type="radio"
            onChange={(event) =>
              props.handleSelectedLeasing(event.target.value)
            }
            value="ALD Automotive"
            id="ALD Automotive"
            checked={props.selectedLeasing === "ALD Automotive"}
            name="leasingCompanies"
          />

          <label className="radio-valuecard-icon" htmlFor="ALD Automotive">
            <span className="radio-valuecard-icon-inner"></span>
          </label>
          <label htmlFor="ALD Automotive" className="big-text primary medium">
            ALD Automotive
          </label>
        </label>
        <label className="block">
          <input
            type="radio"
            onChange={(event) =>
              props.handleSelectedLeasing(event.target.value)
            }
            value="Arval"
            id="Arval"
            checked={props.selectedLeasing === "Arval"}
            name="leasingCompanies"
          />

          <label className="radio-valuecard-icon" htmlFor="Arval">
            <span className="radio-valuecard-icon-inner"></span>
          </label>
          <label htmlFor="Arval" className="big-text primary medium">
            Arval
          </label>
        </label>
        <label className="block">
          <input
            type="radio"
            onChange={(event) =>
              props.handleSelectedLeasing(event.target.value)
            }
            value="Auto Plan"
            id="Auto Plan"
            checked={props.selectedLeasing === "Auto Plan"}
            name="leasingCompanies"
          />

          <label className="radio-valuecard-icon" htmlFor="Auto Plan">
            <span className="radio-valuecard-icon-inner"></span>
          </label>
          <label htmlFor="Auto Plan" className="big-text primary medium">
            AUTO PLAN
          </label>
        </label>
      </form>
      <br />
      <div className={Styles.inputBox}>
        <textarea
          typeof="text"
          key="Comments"
          name="Comments"
          onChange={props.onChange}
          value={props.inputs.Comments}
        />
        <span className={Styles.floatingLabel}>Kommentarer(valgfritt)</span>
      </div>
      {props.error && (
        <p>{props.error}</p>
      )}
      <div className={Styles.button} onClick={() => props.onSubmit()}>
        <p>{props.loading ? "Sender...": "Send inn"}</p>
      </div>
      <div className={Styles.infoBox}>
        <p>
          Har du spørsmål? <br /> Ta gjerne kontakt med oss på <br />
          telefon
          <a className="link" href="tel:91116685">
            {" "}
            91116685{" "}
          </a>
          eller e-post
          <a className="link" href="mailto:kundeservice@divein.no">
            {" "}
            kundeservice@divein.no
          </a>
        </p>
      </div>
      <span className="divein-logo-small">
        <DiveInLogoSmall />
      </span>
    </>
  );
};

export default FirmakundeLeasingFaktura;
