import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import ProgramStore from "../stores/programStore";
import { useState as useSpecialState } from "@hookstate/core";
import VippsLogo from "../utils/VippsLogo";
import DiveInLogoSmall from "../components/DiveInLogo/DiveInLogoSmall";
import ForwardArrowWhite from "../utils/Icons/ForwardArrowWhite";
import InfoIconPrimary from "../utils/Icons/InfoIconPrimary";
import Header from "../components/Header/Header";
import { divein } from "../utils/api";
import { toast } from "react-toastify";
import { isLoggedIn } from "../utils/Firebase";
import { Token, TokenType, CouponTokenData } from "../types";
import { getTokens } from "../stores/tokenStore";
import Cookies from "js-cookie";
import Modal from "react-modal";
import VippsLoginButton from "../utils/VippsLoginButton/VippsLoginButton";
import { LoginUrl } from "../utils/urls";
import UserStore from "../stores/userStore";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
  },
};

const Abonnement = () => {
  const [loading, setLoading] = useState(false);
  const [regnr, setRegnr] = useState<string>("");
  const [coupon, setCoupon] = useState<Token>();
  const [couponData, setCouponData] = useState<CouponTokenData>();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const userStore = useSpecialState(UserStore);

  const location = useLocation();

  const navigate = useNavigate();
  const { program } = useParams<{ program?: string }>();
  const programStore = useSpecialState(ProgramStore);

  const correctIndex = programStore.programData
    .get()
    .findIndex((p) => p.name === program);

  const currentProgram = programStore.programData.get()[correctIndex];

  useEffect(() => {
    programStore.selectedProgram.set(program);
    window.scrollTo(0, 0);

    const timeout = setTimeout(() => {
      const toks = getTokens(TokenType.coupon);
      for (const tok of toks) {
        const data = tok.data as unknown as CouponTokenData;
        if (data.isCampaign) {
          setCoupon(tok);
          setCouponData(tok.data as unknown as CouponTokenData);
          console.log("GOT COUPON " + coupon);
        }
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    console.log(couponData);
  }, [couponData]);

  const initiatePurchase = async () => {
    if (loading) return;

    if (!isLoggedIn) {
      toast.error("Du  må være logget inn for å starte abonnement");
    }
    if (regnr.length < 2 || regnr.length > 7) {
      toast.error("Registreringsnummer ugyldig");
      return;
    }
    setLoading(true);
    const { status, responseData } = await divein.buySubscription({
      licensePlate: regnr,
      templateId: currentProgram.id,
      location: "1",
      couponId: coupon ? coupon.id : "",
    });
    if (status === 409) {
      toast.error("Et aktivt abonnement er allerede registrert på denne bilen");
      setLoading(false);
      return;
    }
    if (status > 299) {
      toast.error("Noe gikk galt");
      setLoading(false);
    } else {
      setLoading(false);
      console.log(responseData);
      const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
      Cookies.set("divein/subID", responseData.subId, {
        domain: "divein.no",
        expires: inFiveMinutes,
      });
      window.location.href = responseData.url;
    }
  };
  return (
    <>
      <Header text="Abonnement" backLink={"/vaskegate"} />
      <div className="container">
        <div className="subscription-content">
          <p className="bold">{programStore.selectedProgram.get()}</p>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate("/info/abonnement")}
          >
            <InfoIconPrimary height={30} />
            <p
              style={{
                marginLeft: 5,
                fontSize: 18,
                color: "#00bbb4",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              Spørsmål og svar
            </p>
          </div>
          <p>
            Abonnementet fornyes hver måned, og du kan avslutte når som helst
            innen 5 dager før hver fornyelse
          </p>

          <p>
            Abonnement er ikke tillat å benytte for firmabiler, taxi og andre
            kommersielle tjenestebiler.
            <br />
            Ta kontakt med oss på{" "}
            <a className="link" href="mailto:kundeservice@divein.no">
              kundeservice@divein.no
            </a>{" "}
            for å få tilbud på bedriftavtale.
          </p>
          {userStore.user.get() && (
            <>
              <p style={{ marginBottom: 0 }}>
                Skriv inn registreringsnummeret til bilen abonnementet skal
                gjelde for:
              </p>
              <input
                style={{ marginTop: 15 }}
                type="text"
                id="bilnummer"
                name="bilnummer"
                value={regnr}
                onChange={(e) => setRegnr(e.target.value.toUpperCase())}
                placeholder="Registreringsnummer"
                maxLength={7}
              />
            </>
          )}
          <span className="grid grid-cols-3 items-center subscription-price">
            {couponData ? (
              <>
                <p className="big-text medium col-span-2">
                  Første {couponData.intervalCount} måned
                  {couponData.intervalCount > 1 ? "er" : ""} til:
                </p>
                <span className="extrabold big-text primary">
                  {couponData.price},-
                </span>
                <p className="col-span-2 text-center">
                  Deretter{" "}
                  {
                    programStore.programData.get()[correctIndex]
                      .subscriptionPrice
                  }{" "}
                  per måned.
                </p>
              </>
            ) : (
              <>
                <p className="big-text medium">Per måned:</p>
                <span className="extrabold big-text primary">
                  {
                    programStore.programData.get()[correctIndex]
                      .subscriptionPrice
                  }
                  ,-
                </span>
              </>
            )}
          </span>
        </div>
        <p>
          Ved å fortsette godtar du{" "}
          <Link to="/info/abonnement/vilkaar" className="link">
            avtalevilkårene
          </Link>
        </p>

        {userStore.user.get() ? (
          <div
            className="button"
            onClick={async () => {
              if (confirmationModalOpen) return;
              try {
                // await divein.getVehicleData(regnr);
                setConfirmationModalOpen(true);
              } catch (err) {
                console.error();
                toast.error((err as Error).message);
              }
            }}
          >
            <p>Start abonnement</p>
            <VippsLogo />
            <span className="forward-arrow">
              <ForwardArrowWhite />
            </span>
          </div>
        ) : (
          <VippsLoginButton
            onClick={() => {
              const inFiveMinutes = new Date(
                new Date().getTime() + 2 * 60 * 1000
              );
              Cookies.set("divein/redirect", location.pathname, {
                domain: "divein.no",
                expires: inFiveMinutes,
              });
              window.location.href = LoginUrl;
            }}
            classes="w-4/6 mt-6"
          />
        )}
        <span className="subscription-divein-logo">
          <DiveInLogoSmall />
        </span>
      </div>

      <Modal id="modal" isOpen={confirmationModalOpen} style={customStyles}>
        <div className="">
          <p className="big-text medium">
            Jeg bekrefter at bilen ikke er en firmabil, og/eller at den benyttes
            til kommersielle tjenester som for eksempel kjøreskole og Taxi.
          </p>
          <div className="flex">
            <div
              className="bg-primary-400 py-1 px-5 w-fit"
              onClick={() => initiatePurchase()}
            >
              <p className="big-text medium text-white">Bekreft</p>
            </div>
            <div
              className=" ml-auto py-1 px-5 w-fit"
              onClick={() => setConfirmationModalOpen(false)}
            >
              <p className="big-text medium">avbryt</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Abonnement;
