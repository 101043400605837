import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import DiveInLogoSmall from "../components/DiveInLogo/DiveInLogoSmall";
import Header from "../components/Header/Header";
import CarFlushingIcon from "../utils/Icons/CarFlushingIcon";
import ForwardArrowWhite from "../utils/Icons/ForwardArrowWhite";
import VippsButton from "../assets/pay_with_vipps.png";
import { getAndSetCorpToken, getTokens } from "../stores/tokenStore";
import { BuySelfserviceRequest, ChargeOption, divein } from "../utils/api";
import { CorporateClientData, Token, TokenType, ValuecardData } from "../types";
import { isLoggedIn } from "../utils/Firebase";
import { toast } from "react-toastify";
import { SelfserviceProductID } from "../utils/constants";
import { LoginToast } from "../components/LoginToast";
import { useState as useSpecialState } from "@hookstate/core";
import Modal from "react-modal";
import UserStore from "../stores/userStore";
import VippsLoginButton from "../utils/VippsLoginButton/VippsLoginButton";
import Cookies from "js-cookie";
import { LoginUrl } from "../utils/urls";
import PlusIcon from "../assets/plus-icon.svg";
import MinusIcon from "../assets/minus-icon.svg";
import BubblesBG from "../assets/webp/Blue-Water-Drops.webp";

const payboxStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const Selvvask = () => {
  const [selectedOption, setSelectedOption] = useState<string>();
  const [selectedAmount, setSelectedAmount] = useState(100);
  const [remaining, setRemaining] = useState<number>();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const userStore = useSpecialState(UserStore);

  const [payBoxOpen, setPayBoxOpen] = useState(false);

  const [corpToken, setCorpToken] = useState<Token[]>();
  const [, setCorpData] = useState<CorporateClientData[]>();

  const navigate = useNavigate();
  const arrayOfTen = Array.from(Array(10), (e, i) => i + 1);

  const handleIncrement = () => {
    if (selectedAmount < 200) setSelectedAmount(selectedAmount + 20);
  };

  const handleDecrement = () => {
    if (selectedAmount > 20) setSelectedAmount(selectedAmount - 20);
  };

  const initiateSelfservicePayment = async (
    charge: ChargeOption,
    tokId?: string
  ) => {
    if (loading) return;

    if (!isLoggedIn()) {
      toast.error(
        <LoginToast text="Du må være innlogget for å kjøpe selvvask" />,
        { autoClose: false, icon: false }
      );
      return;
    }
    if (!selectedOption) {
      toast.error("Du må velge vaskeplass for å fortsette", {
        position: "bottom-center",
      });
      return;
    }
    setLoading(true);
    const withValuecard = charge === ChargeOption.valuecard ? true : false;
    const body: BuySelfserviceRequest = {
      siteId: "1",
      stationId: selectedOption,
      amount: selectedAmount,
      withValuecard: withValuecard,
      productId: SelfserviceProductID,
    };
    if (charge === ChargeOption.corp) body.corpTokenId = tokId;

    console.log(body);
    try {
      const { status, responseData } = await divein.buySelfservice(body);
      if (status < 300 && !withValuecard && !body.corpTokenId) {
        window.location.href = responseData.url;
      }
      if (status < 300) {
        setLoading(false);
        toast.success(
          <div className="h-40 flex items-center">
            <p className="text-2xl">Verdi sendt til selvvaskebås</p>
          </div>,
          {
            position: "top-center",
          }
        );
        updateRemaining();
      }
    } catch (err) {
      toast.error("Noe gikk galt", { position: "bottom-center" });
      setLoading(false);
      console.error(err);
    }
  };

  const updateRemaining = () => {
    setTimeout(() => {
      const vcs = getTokens(TokenType.valuecard);
      let remainingVal = 0;
      for (const vc of vcs) {
        remainingVal += (vc.data as unknown as ValuecardData).remainingValue;
      }
      setRemaining(remainingVal);
    }, 100);
  };

  useEffect(() => {
    const cp = getTokens(TokenType.corporateClient);
    if (cp) {
      setCorpToken(cp);
    } else {
      getAndSetCorpToken(setCorpToken, setCorpData);
    }
    updateRemaining();
  }, []);

  return (
    <>
      <Header text="Selvvask" backLink="/" />
      <div className="w-screen flex flex-col items-center relative overflow-hidden">
        <img
          src={BubblesBG}
          alt="bobler bakgrunn"
          className="absolute -top-10 w-11/12 opacity-70 -z-10 rotate30"
        />
        <p className="big-text med-spacing medium dark">Velg vaskeplass</p>

        <form className="radio-selfWash">
          {arrayOfTen.map((val, i) => (
            <React.Fragment key={i}>
              <input
                type="radio"
                onChange={(e) => setSelectedOption(e.target.value)}
                value={val}
                id={val.toString()}
                checked={selectedOption === val.toString()}
                name="selvvask"
              />
              <label htmlFor={val.toString()}>{val}</label>
            </React.Fragment>
          ))}
        </form>
        <div className="icon-text">
          <CarFlushingIcon />
          <p className="small-spacing small-text">Plass 1-4 har underspyling</p>
        </div>
        <div className="big bg-primary-400 grid grid-cols-3 mb-4 py-6 mt-2">
          <div
            className="self-center justify-self-center"
            onClick={handleDecrement}
          >
            <img src={MinusIcon} alt="Dekrement" />
          </div>
          <div>
            <p className="text-center text-white semibold text-xl my-1">
              Velg beløp
            </p>
            <p className="text-center bold text-accent amount-text">
              {selectedAmount},-
            </p>
          </div>
          <div
            className="self-center justify-self-center"
            onClick={handleIncrement}
          >
            <img src={PlusIcon} alt="Inkrement" />
          </div>
        </div>
        <div
          className="bg-primary-400 rounded-full px-12 mt-2 mb-20"
          onClick={() => setPayBoxOpen(true)}
        >
          <p className="text-white bold text-xl my-3">Betal</p>
        </div>
        {/* <Button
          text="Betal"
          onClick={() => {
            setPayBoxOpen(true);
          }}
        /> */}
        {payBoxOpen && (
          <Modal
            isOpen={payBoxOpen}
            onRequestClose={() => setPayBoxOpen(false)}
            style={payboxStyles}
          >
            <div className="flex flex-col items-center pb-4">
              <button
                onClick={() => setPayBoxOpen(false)}
                className="border-0 bg-transparent self-end text-lg"
              >
                X
              </button>
              {remaining > 0 ? (
                <>
                  <Button
                    onClick={() =>
                      initiateSelfservicePayment(ChargeOption.valuecard)
                    }
                    text="Belast verdikort"
                  />
                </>
              ) : (
                <>
                  <p>
                    Husk at du kan spare inntil 20% på selvvask om du benytter
                    våre digitale verdikort!
                  </p>
                  <Button
                    onClick={() => navigate("/verdikort")}
                    text="Kjøp verdikort her"
                  />
                </>
              )}
              {corpToken && (
                <>
                  {corpToken.map((t) => {
                    return (
                      <Button
                        key={t.id}
                        onClick={() =>
                          initiateSelfservicePayment(ChargeOption.corp, t.id)
                        }
                        text={`Belast ${
                          (t.data as unknown as CorporateClientData).companyName
                        }`}
                      />
                    );
                  })}
                </>
              )}
              {userStore.user.get() ? (
                <div
                  className="vipps"
                  onClick={() => initiateSelfservicePayment(ChargeOption.vipps)}
                >
                  <img src={VippsButton} alt="Betal med Vipps" />
                </div>
              ) : (
                <VippsLoginButton
                  onClick={() => {
                    const inFiveMinutes = new Date(
                      new Date().getTime() + 2 * 60 * 1000
                    );
                    Cookies.set("divein/redirect", location.pathname, {
                      domain: "divein.no",
                      expires: inFiveMinutes,
                    });
                    window.location.href = LoginUrl;
                  }}
                  classes="w-5/6"
                />
              )}
            </div>
          </Modal>
        )}

        <div
          className="button big-button"
          onClick={() => navigate("/verdikort")}
        >
          <div className="big-button-content">
            <div className="discount-circle">
              <p className="small-text">Inntil</p>
              <p>
                <span>20%</span>
              </p>
              <p className="small-text">rabatt</p>
            </div>
            <div className="big-button-content-textbox">
              {remaining > 0 ? (
                <>
                  <p
                    className="big-spacing bigger-text"
                    style={{
                      display: "block",
                      padding: 0,
                      marginBottom: 10,
                      gridColumnStart: 2,
                      gridColumnEnd: 4,
                    }}
                  >
                    Verdikort
                  </p>
                  <p>
                    Saldo:{" "}
                    <span className="text-2xl text-accent">
                      kr {remaining},-
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <p
                    className="big-spacing"
                    style={{
                      display: "block",
                      padding: 0,
                      marginBottom: 10,
                      gridColumnStart: 2,
                      gridColumnEnd: 4,
                    }}
                  >
                    Spar penger med digitalt verdikort
                  </p>

                  <p className="small-text">Kjøp her </p>
                </>
              )}
            </div>
            <span className="forward-arrow">
              <ForwardArrowWhite />
            </span>
          </div>
        </div>

        <div
          className="button big-button"
          onClick={() => navigate("/torkeprogram")}
        >
          <div className="big-button-content" style={{ paddingBottom: 30 }}>
            <div className="black-circle">
              <p style={{ padding: 0, marginLeft: 4 }}>69,-</p>
            </div>
            <p
              style={{
                display: "block",
                padding: 0,
                marginBottom: 10,
                gridColumnStart: 2,
                gridColumnEnd: 4,
              }}
              className="bold"
            >
              Tørkeprogram
            </p>
            <p
              className="small-text"
              style={{
                padding: 0,
                gridRow: 2,
                gridColumn: 2,
              }}
            >
              Inkluderer:
            </p>
            <ul style={{ paddingLeft: "1rem", gridRow: 2, gridColumn: 2 }}>
              <li>Skyllevoks i vaskegate</li>
              <li>Blåsetørk i vaskegate</li>
              <li>Fri bruk av støvsugere i støvsugerhallen</li>
            </ul>

            <span
              className="forward-arrow"
              style={{
                gridRow: 2,
                gridColumn: 3,
                height: 32,
              }}
            >
              <ForwardArrowWhite />
            </span>
          </div>
        </div>
        <div className="button big-button">
          <div className="big-button-content" style={{ paddingBottom: 30 }}>
            <div className="small-black-circle">
              <p style={{ padding: 0, marginLeft: 4 }}>Gratis</p>
            </div>
            <p
              style={{
                display: "block",
                padding: 0,
                marginBottom: 10,
                gridColumnStart: 2,
                gridColumnEnd: 4,
              }}
              className="bold"
            ></p>
            <p
              className="text-base"
              style={{
                padding: 0,
                gridRow: 2,
                gridColumn: 2,
              }}
            >
              Det er alltid gratis bruk av støvsugere ute når du benytter vår
              selvvask.
              <br />
              <span className="text-sm">
                Kjør til fremsiden av bygget - Støvsugeren starter automatisk
                når du løfter av slangen.
              </span>
            </p>
          </div>
        </div>

        <span className="divein-logo-small">
          <DiveInLogoSmall />
        </span>
      </div>
    </>
  );
};

export default Selvvask;
