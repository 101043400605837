import UserStore from "../stores/userStore";

import { useState as useSpecialState } from "@hookstate/core";
import React, { useEffect } from "react";

import { auth, db } from "../utils/Firebase";

import { onSnapshot, doc, Unsubscribe } from "firebase/firestore";
import { Customer } from "../types";
import { toast } from "react-toastify";
import { divein } from "../utils/api";

import LogRocket from "logrocket";
import { isLocalhost } from "../utils/helper";

const useCustomerStore = () => {
  const customerStore = useSpecialState(UserStore);
  const decide = async (decision: boolean) => {
    await divein.decideMarketing(decision);
  };

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    const uid = auth.currentUser?.uid;
    if (uid) {
      if (!isLocalhost) {
        LogRocket.identify(uid, {
          phone: auth.currentUser.phoneNumber,
        });
      }
      const q = doc(db, "customers", uid);
      unsubscribe = onSnapshot(q, (snapshot) => {
        console.log("got customer: ", snapshot.data());
        const customerData = snapshot.data() as Customer;
        if (!customerData.decidedMarketing) {
          toast.info(
            <div className="px-5">
              <p>
                Hei! <br /> Ønsker du å motta rabattkoder og tilbud på e-post?
              </p>
              <div className="flex gap-2 justify-between">
                <div
                  className="bg-primary-400 px-1 text-white w-1/3 flex justify-center items-center"
                  onClick={() => decide(true)}
                >
                  <p>Ja</p>
                </div>
                <div
                  className="bg-primary-400 px-2 text-white w-1/3 flex justify-center items-center"
                  onClick={() => decide(false)}
                >
                  <p>Nei</p>
                </div>
              </div>
            </div>,
            { autoClose: false, position: "bottom-center", icon: false }
          );
        }
        customerStore.user.set(customerData);
      });
    } else {
      unsubscribe = () => null;
    }
    return () => unsubscribe();
  }, [auth.currentUser]);
};

// eslint-disable-next-line react/display-name
export default useCustomerStore;
