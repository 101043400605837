import { getToken } from "./helper";

const Methods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export interface BuyCarwashRequest {
  programId: string;
  options: Option[];
  location: string;
  couponId?: string;
  corpTokenId?: string;
}

interface Option {
  id: string;
  selected: boolean;
}

export interface BuyMatwashRequest {
  stationId: string;
  siteId: string;
  productId: string;
  corpTokenId?: string;
  couponTokenId?: string;
}

export interface BuyValuecardRequest {
  productId: string;
  corpTokenId?: string;
  siteId: string;
  couponId?: string;
}

export interface BuySubscriptionRequest {
  licensePlate: string;
  templateId: string;
  location: string;
  couponId?: string;
}

export interface BuyBlowdryRequest {
  productId: string;
  locationId: string;
  chosenRoofbox: boolean;
  couponCode?: string;
  corpTokenId?: string;
}

export interface BuyVacuumAccess {
  siteId: string;
  corpTokenId?: string;
}

export interface BuySelfserviceRequest {
  siteId: string;
  stationId: string;
  amount: number;
  corpTokenId?: string;
  withValuecard: boolean;
  productId: string;
}

export interface CancelSubscriptionRequest {
  subscriptionId: string;
  setInactive: boolean;
  reasons: string[];
}

export interface UpgradeSubscriptionRequest {
  subscriptionId: string;
  templateId: string;
}

interface CarDetails {
  found: boolean;
  brand: string;
  model: string;
  color: string;
}

class DiveinAPI {
  baseurl: string;
  headers: HeadersInit;
  constructor(endpoint: string) {
    this.baseurl = endpoint;
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "",
    };
  }

  decideMarketing = async (consent: boolean) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/customer/consent", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify({ consent: consent }),
    });
    const ok = response.ok;
    const status = response.status;
    return { ok, status };
  };

  buyCarwash = async (data: BuyCarwashRequest) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/washgate", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify(data),
    });
    const ok = response.ok;
    let responseData: BuyCarwashResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };

  buyMatwash = async (data: BuyMatwashRequest) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/matwash", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify(data),
    });
    const ok = response.ok;
    let responseData: InitResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };

  buyValuecard = async (data: BuyValuecardRequest) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/valuecard", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify(data),
    });
    const ok = response.ok;
    let responseData: InitResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };

  buySubscription = async (data: BuySubscriptionRequest) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/subscription", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify(data),
    });
    const ok = response.ok;
    let responseData: InitResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };

  buyBlowdry = async (data: BuyBlowdryRequest) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/washgate/blowdry", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify(data),
    });
    const ok = response.ok;
    let responseData: InitResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };

  buyVacuumAccess = async (data: BuyVacuumAccess) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/vacuumhall", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify(data),
    });
    const ok = response.ok;
    let responseData: InitResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };

  buySelfservice = async (data: BuySelfserviceRequest) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/selfservice", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify(data),
    });
    const ok = response.ok;
    let responseData: InitResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };

  cancelSubscription = async (data: CancelSubscriptionRequest) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/subscription/cancel", {
      method: Methods.PATCH,
      headers: this.headers,
      body: JSON.stringify(data),
    });
    const ok = response.ok;
    let responseData: InitResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };

  upgradeSubscription = async (data: UpgradeSubscriptionRequest) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/subscription/upgrade", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify(data),
    });
    const ok = response.ok;
    let responseData: InitResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };

  applyCouponCode = async (code: string) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/coupon/apply", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify({ code: code, siteId: "1" }),
    });
    const ok = response.ok;
    let responseData: InitResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };

  pollPayment = async (pid: string) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/payments/status/" + pid, {
      method: Methods.GET,
      headers: this.headers,
    });
    const ok = response.ok;
    let responseData: PaymentCheckResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };
  pollSubscription = async (subID: string) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(
      this.baseurl + "/subscription/status/" + subID,
      {
        method: Methods.GET,
        headers: this.headers,
      }
    );
    const ok = response.ok;
    let responseData: SubCheckResponse;
    const status = response.status;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };
  useVacuumHallToken = async (tokenId: string) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/vacuumhall/start", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify({ tokenId: tokenId }),
    });
    const ok = response.ok;
    const status = response.status;
    let responseData: InitResponse;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { ok, status, responseData };
  };
  generateFreewashWithStamps = async (withRoofbox: boolean) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/customer/freewash", {
      method: Methods.POST,
      headers: this.headers,
      body: JSON.stringify({ withRoofbox: withRoofbox }),
    });
    const status = response.status;
    let responseData: FreewashResponse;
    try {
      responseData = await response.json();
    } catch (err) {
      console.error(err);
    }
    return { status, responseData };
  };
  sendCorporateClientRequest = async (data: CorporateClientRequest) => {
    const response = await fetch(
      this.baseurl + "/corporate/webapp_corp_request",
      {
        method: Methods.POST,
        body: JSON.stringify(data),
      }
    );
    const status = response.status;
    return { status };
  };
  saveNewEmail = async (email: string) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/customer/update_email", {
      method: Methods.PATCH,
      headers: this.headers,
      body: JSON.stringify({ email: email }),
    });
    const status = response.status;
    return { status };
  };
  getVehicleData = async (lp: string) => {
    this.headers["Authorization"] = await getToken();
    const response = await fetch(this.baseurl + "/cardetails/" + lp, {
      method: Methods.GET,
      headers: this.headers,
    });
    if (response.status === 204) {
      throw new Error("Ingen bil funnet med det registreringsnummeret");
    }
    if (response.status === 500) {
      throw new Error("Noe gikk galt");
    }
    const data = (await response.json()) as CarDetails;

    return data;
  };
}

export interface CorporateClientRequest {
  companyName: string;
  contactPerson: string;
  contactEmail: string;
  contactPhone: string;
  billingEmail: string;
  address: string;
  city: string;
  zipCode: string;
  billingReference: string;
  leasingPartner: string;
  organisationNumber: string;
  comments: string;
  useLicenseplate: boolean;
  useUsers: boolean;
  cars: string[];
  users: string[];
}

export interface FreewashResponse {
  tokenId: string;
}

export interface BuyCarwashResponse {
  success: boolean;
  tokenId: string;
  error?: string;
}

export interface InitResponse {
  success: boolean;
  error?: string;
  url?: string;
  tokenId?: string;
  subId?: string;
}

interface SubCheckResponse {
  status: number;
}
interface PaymentCheckResponse {
  status: string;
}

let url: string;
if (window.location.hostname == "localhost") {
  url = "http://localhost:8080";
} else {
  url = "/api";
}
export const divein = new DiveinAPI(url);

export enum ChargeOption {
  vipps = 1,
  corp,
  valuecard,
  coupon,
}
