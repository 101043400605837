import React, { useEffect } from "react";
import ProgramComponent from "../components/ProgramComponent/ProgramComponent";
import Header from "../components/Header/Header";
import programStore from "../stores/programStore";
import { useState as useGlobalState } from "@hookstate/core";

const Vaskegate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const programState = useGlobalState(programStore);

  useEffect(() => {
    console.log(programState);
  }, []);
  return (
    <>
      <Header backLink="/"/>
      <div className="container bubbles">
        {programState.programData.get()?.map((program, index) => {
          return <ProgramComponent key={index} current={program} />;
        })}
      </div>
    </>
  );
};

export default Vaskegate;
