import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import DiveInLogo from "../components/DiveInLogo/DiveInLogoBig";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="container justify-center" style={{}}>
      <h3 style={{ margin: 10, textAlign: "center" }}>
        Beklager, det ser ut som at vi har ledet deg til noe som ikke eksister!
      </h3>
      <h3>La oss få deg tilbake.</h3>
      <Button text="Send meg hjem" onClick={() => navigate(-1)} />
      <DiveInLogo />
    </div>
  );
};
export default NotFound;
