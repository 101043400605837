import React from "react";
import { useNavigate } from "react-router";


function BackButton({to, goBack}:{to: string, goBack: boolean}) {
  const navigate = useNavigate();

  return (

    <svg
      className="backButton"
      xmlns="http://www.w3.org/2000/svg"
      width={20.477}
      height={20.477}
      viewBox="0 0 20.477 20.477"
      onClick={() => goBack ? navigate(-1) : navigate(to)}
    >
      <path
        d="M10.239 20.477l1.8-1.8-7.141-7.154h15.579v-2.56H4.902l7.141-7.154-1.8-1.8L0 10.239z"
        fill="#fff"
      />
    </svg>
  );
}

export default BackButton;
