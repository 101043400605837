import React from "react";
import { Navigate } from "react-router-dom";

interface AuthRequiredProps {
  authed: boolean;
  children?: JSX.Element;
}

const AuthRequired = ({ children, authed }: AuthRequiredProps) => {
  return authed ? children : <Navigate to="/" />;
};

export default AuthRequired;
