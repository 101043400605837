import { createState, useState } from "@hookstate/core"
import { Product, ProductCategory } from "../types"
import { getValuecardProducts } from "../utils/Firebase"

interface ProductStoreProps {
    products: Product[]
}


const ProductStore = createState<ProductStoreProps>({ products: [] } as ProductStoreProps)

export const useProductStore = () => {
    return useState(ProductStore)
}

export const fillValuecardProducts = async () => {
    const products = await getValuecardProducts()
    if (products.length > 0) {
        ProductStore.products.set(products)
        return products
    }
}

export const getValuecards = async () => {
    const valuecards = ProductStore.products?.get().filter(product => product.categoryId === ProductCategory.Valuecard)
    if (valuecards.length === 0) {
        const cards = await fillValuecardProducts()
        return cards
    } else {
        return valuecards
    }
}