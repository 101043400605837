import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Cookie from "js-cookie";
import { divein } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Lottie from "lottie-react"
import GreenCheckmarkJSON from "../../assets/lottie/green_checkmark.json"

export const SubscriptionLanding = () => {
  const [status, setStatus] = useState(0);

  const navigate = useNavigate();

  const poll = (id: string) => {
    try {
      const interval = setInterval(async () => {
        const { status, responseData } = await divein.pollSubscription(id);
        if (status === 404) {
          if (responseData.status) {
            setStatus(responseData.status);
            clearInterval(interval);
          }
        } else {
          setStatus(responseData.status);
          clearInterval(interval);
        }
      }, 1000);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const subID = Cookie.get("divein/subID");
    if (!subID) {
      console.error("no cookie found");
    } else {
      console.log(subID);
    }

    poll(subID);
  }, []);

  useEffect(() => {
    if (status === 1 || status === 2) {
      Cookies.remove("divein/subID");
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 3000);
    }
  }, [status]);
  return (
    <>
      <Header backLink="/" />
      <div className="container mt-20">
        {!status && <p className="text-xl">Venter på bekreftelse...</p>}
        {status === 1 && (
          <>
            <Lottie animationData={GreenCheckmarkJSON} loop={false} style={{width:"220px"}} />
            <p className="text-xl text-center">
              Gratulerer! <br/> Du har nå et aktivt vaskeabonnement
            </p>
          </>
        )}
        {status === 2 && <p className="text-xl">Avbrutt av kunde</p>}
      </div>
    </>
  );
};
