import React, { useState } from "react";
import CloseIconCircle from "../../../utils/Icons/CloseIconCircle";
import { Subscription } from "../../../stores/subscriptionStore";
import { CancelSubscriptionRequest, divein } from "../../../utils/api";
import { toast } from "react-toastify";

interface Props {
  goHome: () => void;
  program: Subscription;
}
const CancelSubscription = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [reasons, setReasons] = useState({
    tooExpensive: false,
    poorQuality: false,
    dontWashEnough: false,
    soldCar: false,
    other: false,
    otherText: "",
  });

  const cancelSubscription = async () => {
    if (loading) return;
    setError("");
    const reasoning: string[] = [];
    if (reasons.dontWashEnough) {
      reasoning.push("Vasker ikke ofte nok");
    }
    if (reasons.poorQuality) {
      reasoning.push("For dårlig vask");
    }
    if (reasons.tooExpensive) {
      reasoning.push("For dyrt");
    }
    if (reasons.soldCar) {
      reasoning.push("Har solgt bilen");
    }
    if (reasons.other) {
      if (reasons.otherText === "") {
        setError(
          "Beskriv problemet som gjør at du ikke vil beholde abonnementet i tekstfeltet"
        );
        return;
      }
      reasoning.push(reasons.otherText);
    }

    if (reasoning.length === 0) {
      setError("Vennligst huk av ett eller flere valg");
      return;
    }

    const bod: CancelSubscriptionRequest = {
      subscriptionId: props.program.id,
      setInactive: false,
      reasons: reasoning,
    };
    try {
      setLoading(true);
      const { status, responseData } = await divein.cancelSubscription(bod);
      if (status === 200) {
        setIsOpen(false);
        toast.success("Ditt abonnement er nå avsluttet.");
      } else {
        setLoading(false);
        if (responseData.error.includes("less than 5")) {
          toast.error(
            "Kan ikke kansellere, mindre enn 5 dager til neste periode"
          );
        } else {
          toast.error("Noe gikk galt");
        }
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };
  return (
    <>
      <div
        // box style
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          marginLeft: "8px",
          marginRight: "8px",
          marginBottom: "40px",
          color: "black",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          // inner style
          style={{
            padding: "0 20px 0 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            // margintop style
            style={{ marginTop: "30px", marginLeft: "10px" }}
          >
            Abonnementet er betalt til og med <br />
            {props.program.nextDue.toDate().toLocaleString()}.
          </p>
          <span
            // back style
            style={{ position: "absolute", marginTop: "1rem", right: "1.5rem" }}
            onClick={props.goHome}
          >
            <CloseIconCircle />
          </span>
          <div
            // button style + marginbot style
            style={{
              height: "3.4rem",
              margin: "8px 0 8px 0",
              paddingLeft: "1rem",
              backgroundColor: "#00bbb4",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              marginBottom: "2rem",
            }}
            onClick={() => setIsOpen(true)}
          >
            <p>Avslutt abonnement</p>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-slate-400 bg-opacity-60 flex justify-center items-center">
          <div className="bg-white rounded-lg w-full mx-5 p-5 pb-20 h-fit relative">
            <p className="text-xl">
              Hei, hva er årsaken til at du avslutter abonnementet?
            </p>
            <CheckboxInput
              text="Vasker ikke ofte nok"
              checked={reasons.dontWashEnough}
              onChange={() =>
                setReasons({
                  ...reasons,
                  dontWashEnough: !reasons.dontWashEnough,
                })
              }
            />
            <CheckboxInput
              text="Vasken er for dårlig"
              checked={reasons.poorQuality}
              onChange={() =>
                setReasons({ ...reasons, poorQuality: !reasons.poorQuality })
              }
            />
            <CheckboxInput
              text="Det er for dyrt"
              checked={reasons.tooExpensive}
              onChange={() =>
                setReasons({ ...reasons, tooExpensive: !reasons.tooExpensive })
              }
            />
            <CheckboxInput
              text="Har solgt bilen"
              checked={reasons.soldCar}
              onChange={() =>
                setReasons({ ...reasons, soldCar: !reasons.soldCar })
              }
            />
            <CheckboxInput
              text="Annet"
              checked={reasons.other}
              onChange={() => setReasons({ ...reasons, other: !reasons.other })}
            />
            {reasons.other && (
              <div className="flex justify-center">
                <textarea
                  className="border-1 border-slate-500 w-5/6 text-sm"
                  style={{ fontFamily: "proxima-nova" }}
                  onChange={(e) =>
                    setReasons({ ...reasons, otherText: e.target.value })
                  }
                />
              </div>
            )}
            {error && <p className="text-red-500">{error}</p>}
            <button
              className="absolute bottom-5 left-5 border-0 bg-primary-400 text-white text-lg py-1 px-4"
              onClick={() => cancelSubscription()}
            >
              Avslutt abonnement
            </button>
            <button
              className="absolute bottom-5 right-5 border-0 bg-white text-primary-400 text-lg py-1 px-4"
              onClick={() => setIsOpen(false)}
            >
              Avbryt
            </button>
          </div>
        </div>
      )}
      {/* <Modal isOpen={isOpen} className="">
        <div onClick={() => setIsOpen(false)}>
          <CloseIconCircle />
        </div>
        <p>
          Er du sikker på at du vil avslutte abonnementet? Om du avslutter nå,
          kan du fortsatt bruke abonnementet fram til{" "}
          {props.program.nextDue.toDate().toLocaleDateString()}.
        </p>
        <div
          className="bg-red-500 text-white px-2 py-1"
          onClick={() => cancelSubscription()}
        >
          <p>Avslutt nå</p>
        </div>

        <div className="mt-10 px-2 py-1" onClick={() => setIsOpen(false)}>
          <p>Avbryt</p>
        </div>
      </Modal> */}
    </>
  );
};

interface CheckboxInputProps {
  text: string;
  checked: boolean;
  onChange: () => void;
}
const CheckboxInput = ({ text, checked, onChange }: CheckboxInputProps) => {
  return (
    <div className="flex">
      <input type="checkbox" id={text} checked={checked} onChange={onChange} />
      <label className="svgLabel" htmlFor={text}>
        <span></span>
      </label>
      <label className="labelText">
        <label htmlFor={text} className="inputLabel">
          {text}
        </label>
      </label>
    </div>
  );
};

export default CancelSubscription;
