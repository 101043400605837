import {useState, useCallback} from "react"
    export interface TargetProps {
        name: string
        value: string
    }
    export interface InputProps {
        target: TargetProps
    }

      // eslint-disable-next-line
export const useInputs = (initial: any) => {

    const [inputs, setInputs] = useState(initial)


    const onChange =
        ({ target: { name, value } }: InputProps) => {
      // eslint-disable-next-line
            setInputs((state: any) => ({ ...state, [name]: value }))
        }
      // eslint-disable-next-line
    const change = useCallback((target: string, value: any) => {
      // eslint-disable-next-line
        setInputs((state: any) => ({ ...state, [target]: value }))
    }, [])


    return {inputs, onChange, change}
}
