import React from "react";
import { Payment } from "../../../stores/subscriptionStore";
import { PaymentStatuses } from "../../../utils/constants";

export const PaymentItem = ({
  payment,
  onClick,
}: {
  payment: Payment;
  onClick: () => void;
}) => {
  return (
    <div
      key={payment.id}
      className="w-11/12 grid grid-cols-4 text-center my-2 bg-primary-400 text-white"
      style={{ border: "1px solid #00bbb4" }}
      onClick={onClick}
    >
      <p className="place-self-center font-semibold">
        {payment.created.toDate().toLocaleString()}
      </p>
      <p className="place-self-center font-semibold">{payment.amount.toFixed(2)},-</p>
      <p className="place-self-center font-semibold">
        {payment.forProductName}
      </p>
      <p className="place-self-center font-semibold">
        {PaymentStatuses.get(payment.status)}
      </p>
    </div>
  );
};
